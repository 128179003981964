import React, { useContext, useState, useEffect, useRef } from 'react';
import 'date-fns';
import {
    Box,
    Paper,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { makeStyles } from '@material-ui/core/styles';
import usePermissionsHook from 'Hooks/usePermissionHook';
import DevicesList from './DevicesList';
import CopyComponent from 'components/users/CopyComponent';
import CopyToClipboard from '../CopyToClipboard';
import CustomTooltip from '../CustomTooltip';

const PERMISSION_SLUG = 'Byjus/Reports';
const USER_PERMISSION_SLUG = 'Byjus/Users';
const PER_PAGE = 10;
const useStyles = makeStyles(theme => ({
    flexRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px 0 24px',
        background: '#9baaba',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },
    headerItem: {
        fontWeight: 500,
        alignItems: 'center',
        padding: '18px 0',
        color: '#fff',
    },
    mainData: {
        fontWeight: 500,
        paddingLeft: 18,
    },
    icon: {
        fontSize: 13,
    },
    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    pannedDetails: {
        borderTop: '1px solid #ddd',
    },
    userDetails: {
        width: '100%',
    },
    noReportsFound: {
        marginTop: 20,
        textAlign: 'center',
    },
    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const UsersMultipleDevicesInfo = props => {
    const classes = useStyles();
    const { allowed, allowedUpdate } = usePermissionsHook(PERMISSION_SLUG);
    const userAllowedUpdate = usePermissionsHook(USER_PERMISSION_SLUG).allowedUpdate;
    const { usersMultipleDevicesInfo, fetchUsersMultipleDeviceInfo, page, setPage, totalReports } =
        props;
    const [expanded, setExpanded] = useState(false);

    // // FOR COPY ICON
    // const userIdMultipleDevices = useRef(null);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const getPageCount = () => {
        if (totalReports % PER_PAGE == 0) {
            return Math.trunc(totalReports / PER_PAGE);
        }
        return Math.trunc(totalReports / PER_PAGE) + 1;
    };
    console.log('getPageCount: ', getPageCount());
    console.log('totalReports: ', totalReports, props);
    return (
        <div>
            <MainHeaders />
            {usersMultipleDevicesInfo && usersMultipleDevicesInfo.length > 0 ? (
                <Box>
                    {usersMultipleDevicesInfo.map((usageInfo, index) => (
                        <Accordion
                            key={index}
                            classes={{ root: classes.expansionPannelRoot }}
                            expanded={expanded === usageInfo.user_id}
                            onChange={handleChange(usageInfo.user_id)}>
                            <AccordionSummary
                                expandIcon={<CustomTooltip variable={expanded} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                <Box display="flex" alignItems="center" width="100%">
                                    <Box flex="3">
                                        <Link
                                            underline="always"
                                            target="_blank"
                                            // rel="noopener noreferrer"
                                            href={
                                                process.env.PUBLIC_URL +
                                                `/users?q=${usageInfo.user_id}`
                                            }>
                                            {usageInfo.user_id}
                                        </Link>
                                        {/* <CopyComponent
                                            inputRef={userIdMultipleDevices}
                                            inputValue={usageInfo.user_id}
                                        /> */}
                                        <CopyToClipboard text={usageInfo.user_id} />
                                    </Box>
                                    <Box flex="3" className={classes.mainData}>
                                        {usageInfo.total_devices}
                                    </Box>
                                    <Box flex="3" className={classes.mainData}>
                                        {usageInfo.transfer_end_date}
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className={classes.pannedDetails}>
                                <Box className={classes.userDetails}>
                                    <DeviceDetails
                                        user={usageInfo}
                                        fetchUsersMultipleDeviceInfo={fetchUsersMultipleDeviceInfo}
                                        allowedUpdate={userAllowedUpdate}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Box className={classes.paginationSection}>
                        <Pagination
                            color="primary"
                            count={getPageCount()}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handleChangePage}
                        />
                    </Box>
                </Box>
            ) : (
                <Typography className={classes.noReportsFound}>
                    <strong> No Reports Found!! </strong>
                </Typography>
            )}
        </div>
    );
};

const MainHeaders = props => {
    const classes = useStyles();
    return (
        <Paper className={classes.flexRoot}>
            <Box flex="3" className={classes.headerItem}>
                User ID
            </Box>
            <Box flex="3" className={classes.headerItem}>
                Total Devices
            </Box>
            <Box flex="3" className={classes.headerItem}>
                Subscription Transfer End Date
            </Box>
        </Paper>
    );
};

const DeviceDetails = props => {
    const classes = useStyles();
    const { user, fetchUsersMultipleDeviceInfo, allowedUpdate } = props;
    const headers = [
        'Serial No.',
        'User type',
        'Enrollment Date',
        'Expiry',
        'Impact On Pool',
        'User count',
    ];
    if (allowedUpdate) {
        headers.push('Action');
    }
    return (
        <DevicesList
            fetchUsersMultipleDeviceInfo={fetchUsersMultipleDeviceInfo}
            userId={user.user_id}
            selectedUser={user}
            listHeaders={headers}
            devices={user.device_details}
            allowedUpdate={allowedUpdate}
        />
    );
};
export default UsersMultipleDevicesInfo;
