import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ArrowForward, Autorenew, Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Heading from 'components/header/Heading';
import http from 'core/http';
import BulkActionStatusFilter from './BulkActionStatusFilter';
import BulkActionStatusList from './BulkActionStatusList';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const PER_PAGE = 10;

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    helpText: {
        fontSize: 13,
        marginLeft: 8,
        color: 'gray',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 100,
    },
}));

const BulkActionStatusReport = props => {
    const classes = useStyles();

    const [backgroundJobDetails, setBackgroundJobDetails] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);
    const [jobStatus, setJobStatus] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUserEmail, setSelectedUserEmail] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [{ user }, userDispatch] = React.useContext(UserContext);

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }
    const getFromDate = () => {
        const currentDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(currentDate.getDate() - 6);
        fromDate = new Date(fromDate.toDateString());
        fromDate = new Date(
            fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate(),
        );
        return fromDate;
    };

    const getToDate = () => {
        const currentDate = new Date();
        let toDate = new Date(
            currentDate.getFullYear() +
                '-' +
                (currentDate.getMonth() + 1) +
                '-' +
                currentDate.getDate(),
        );
        return toDate;
    };

    const formatDate = date => {
        return date && date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    };

    const [filters, setFilters] = useState({
        from_date: getFromDate(),
        to_date: getToDate(),
        selectedActivityType: '',
        selectedJobStatus: '',
    });

    const goBack = e => {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    };

    useEffect(() => {
        fetchAccountUsers();
        fetchJobTypes();
        fetchBackgroundJobStatus();
    }, []);

    useEffect(() => {
        fetchBackgroundJobDeatils(false);
    }, [page]);

    // Fetch Account Users
    const fetchAccountUsers = () => {
        async function fetchData() {
            const response = await http.get('/api/v3/account_users.json');
            setUsers(response.data.account_users);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {});
    };

    // Fetch Activity Types
    const fetchJobTypes = () => {
        async function fetchData() {
            const response = await http.get('/api/v3/job_types.json');
            setActivityTypes(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {});
    };

    // Fetch Background Job Status
    const fetchBackgroundJobStatus = () => {
        async function fetchData() {
            const response = await http.get('/api/v3/background_job_status.json');
            setJobStatus(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {});
    };

    // Getting Background Job Status Value
    const getJobStatusValue = () => {
        const comparingArrayWithString = jobStatus.filter(
            status => status.key == filters.selectedJobStatus,
        );
        let value = comparingArrayWithString.map(status => status.value);
        let stringValue = value.toString();
        return stringValue;
    };

    // Getting Job Type Key
    const getJobTypeKey = () => {
        const comparingArrayWithString = activityTypes.filter(
            activity => activity.value == filters.selectedActivityType,
        );
        let activityKey = comparingArrayWithString.map(activity => activity.key);
        let stringKey = activityKey.toString();
        return stringKey;
    };

    // Fetch Background Job Details
    const fetchBackgroundJobDeatils = (refreshList = false) => {
        const updateList = refreshList;
        if (updateList) {
            setPage(1);
        }

        setLoading(true);
        const params = {
            params: {
                from_date: formatDate(filters.from_date),
                to_date: formatDate(filters.to_date),
                job_type: getJobTypeKey(),
                job_status: getJobStatusValue(),
                admin_ids: selectedUserEmail,
                per_page: PER_PAGE,
                page: page,
            },
        };

        http.get('/api/v3/download_centre.json', params)
            .then(response => {
                setBackgroundJobDetails(response.data.background_job_details);
                setTotalRecords(response.data.total_records);
                setTotalPages(Math.ceil(response.data.total_records / 10));
            })
            .catch(error => {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Box marginBottom="15px">
                <Heading
                    title={
                        <>
                            <Box>
                                <Button color="primary" onClick={goBack}>
                                    Reports&nbsp; <ArrowForward fontSize="small" />
                                </Button>
                                <span className={classes.reportName}>Bulk Action Status</span>
                            </Box>
                            <Box className={classes.helpText}>
                                View Status and Download CSV for bulk actions activity from here.
                            </Box>
                        </>
                    }
                />
            </Box>

            <Box>
                <BulkActionStatusFilter
                    activityTypes={activityTypes}
                    jobStatus={jobStatus}
                    users={users}
                    filters={filters}
                    setFilters={setFilters}
                    getFromDate={getFromDate}
                    getToDate={getToDate}
                    setPage={setPage}
                    selectedUserEmail={selectedUserEmail}
                    setSelectedUserEmail={setSelectedUserEmail}
                    fetchBackgroundJobDeatils={fetchBackgroundJobDeatils}
                />

                {loading ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                    <BulkActionStatusList
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        totalRecords={totalRecords}
                        backgroundJobDetails={backgroundJobDetails}
                        setBackgroundJobDetails={setBackgroundJobDetails}
                    />
                )}
            </Box>
        </Container>
    );
};

export default BulkActionStatusReport;
