import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Paper, CircularProgress } from '@material-ui/core/';
import LoadMoreButton from './LoadMoreButton';
import AppDataUsageListHeader from './AppDataUsageListHeader';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '30px',
    },
    noActivityFound: {
        marginTop: '5%',
        textAlign: 'center',
    },

    tableContainer: {
        maxHeight: 500,
    },

    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
        padding: 10,
    },

    pannedDetails: {
        borderTop: '1px solid #ddd',
    },

    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const AppDataUsageList = props => {
    const classes = useStyles();
    const { dataUsageData, page, setPage, isLoading, totalRecords, totalPages, noDataMessage } =
        props;

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <>
            {dataUsageData.length > 0 ? (
                <Box className={classes.paper}>
                    <AppDataUsageListHeader />
                    <Box>
                        {dataUsageData.map((data, index) => (
                            <Paper key={index} classes={{ root: classes.expansionPannelRoot }}>
                                <Box display="flex" alignItems="cneter" width="100%">
                                    <Box flex="3">
                                        <Box>
                                            <b>{data.name}</b>
                                        </Box>
                                        <Box>{data.package_name}</Box>
                                    </Box>
                                    <Box flex="1" textAlign="center">
                                        {data.foreground_duration}
                                    </Box>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                    <Box className={classes.paginationSection}>
                        <Pagination
                            color="primary"
                            count={totalPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handlePageChange}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    {isLoading ? (
                        <Box className={classes.noActivityFound}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Typography className={classes.noActivityFound}>
                            <strong> {noDataMessage} </strong>
                        </Typography>
                    )}
                </>
            )}
        </>
    );
};

export default AppDataUsageList;
