import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
/**
 * Toast which shows toast messages depending on variants (error, success etc)
 */
const position = {
    vertical: 'top',
    horizontal: 'right',
};

const Toast = props => {
    const { message, variant, isOpen, toggleVisibility } = props;

    return (
        <Snackbar
            autoHideDuration={6000}
            anchorOrigin={position}
            open={isOpen}
            onClose={toggleVisibility}>
            <Alert onClose={toggleVisibility} severity={variant} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
};

Toast.defaultProps = {
    variant: 'success',
    message: '',
};

Toast.propTypes = {
    variant: PropTypes.any,
    message: PropTypes.any,
};

export default Toast;
