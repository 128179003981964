import React, { useContext, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createMuiTheme,
    IconButton,
    makeStyles,
    MuiThemeProvider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import CustomTooltip from '../CustomTooltip';
import { Pagination } from '@material-ui/lab';
import { GetApp, Refresh } from '@material-ui/icons';
import http from 'core/http';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/styles';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '12px',
                color: 'black',
                backgroundColor: 'white',
                zIndex: 0,
                position: 'relative',
                padding: 12,
                borderRadius: 6,
                boxShadow:
                    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            },
            arrow: {
                color: 'white',
                width: 15,
                fontSize: 10,
            },
        },
    },
});

const InfoIconTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#ffffff',
        color: 'black',
        minWidth: 315,
        fontSize: theme.typography.pxToRem(13),
        border: '1px solid #dadde9',
        height: 40,
        padding: 10,
    },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '30px',
    },
    flexRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 24px 0 24px',
        background: '#9baaba',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },
    itemOne: {
        textAlign: 'left',
        flex: 2.6,
    },

    itemTwo: {
        textAlign: 'center',
        flex: 2,
    },
    itemThree: {
        textAlign: 'center',
        flex: 1.6,
    },

    itemFive: {
        textAlign: 'center',
        flex: 0.6,
    },

    headerItem: {
        fontWeight: 500,
        padding: '18px 0',
        color: '#fff',
    },
    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },

    pannedDetails: {
        borderTop: '1px solid #ddd',
    },

    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
    noActivityFound: {
        marginTop: '5%',
        textAlign: 'center',
    },
    totalRecords: {
        textAlign: 'center',
        fontSize: 14,
        marginTop: 15,
    },
    showExtraInfoLink: {
        cursor: 'pointer',
        paddingBottom: '1px',
        borderBottom: '1px solid',
        fontWeight: 500,
        display: 'inline-block',
        marginTop: 10,
        color: '#3F51B5',
    },
    iconButton: {
        padding: '0px 8px 0px 8px',
    },
}));

const BulkActionStatusList = props => {
    const classes = useStyles();
    const {
        page,
        setPage,
        totalPages,
        totalRecords,
        backgroundJobDetails,
        setBackgroundJobDetails,
    } = props;

    const [{ user }, userDispatch] = useContext(UserContext);
    const [expanded, setExpanded] = useState(false);
    const [showExtraInfo, setShowExtraInfo] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setShowExtraInfo(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    function showSuccessToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    }

    // Download the download_centre File Details
    const getDownloadCenterFile = backgroundJobDetail => {
        http.get(`/api/v3/download_file/${backgroundJobDetail.download_centre_file_detail_id}.json`)
            .then(response => {
                window.open(response.data.file_path, '_blank');
            })
            .catch(error => {
                showErrorToast(error.response.data.error || 'Something Went Wrong');
            });
    };

    // Get Specific Job Details

    const getSpcificJobDetails = (event, backgroundJobDetail, index) => {
        event.stopPropagation();
        http.get(`/api/v3/download_centre/${backgroundJobDetail.id}.json`)
            .then(response => {
                let refreshArray = backgroundJobDetails;
                refreshArray.splice(index, 1, response.data.background_job_detail);
                setBackgroundJobDetails(refreshArray);
                showSuccessToast('Refresh Successful');
            })
            .catch(error => {
                console.log(error);
            });
    };

    // Get Date From Past Month
    const getDateFromPastMonth = backgroundJobDetail => {
        let currentDate = new Date();
        let finishedDate = backgroundJobDetail.finished_at;
        if (finishedDate) {
            let finished_at = finishedDate.split('-');
            finished_at = [finished_at[1], finished_at[0], finished_at[2]];
            finished_at = finished_at.join('-');
            finished_at = new Date(finished_at);
            let pastMonthDate = currentDate - finished_at <= 30;

            return pastMonthDate;
        } else {
            return null;
        }
    };

    // Get Tooltip Title for Download Centre File
    const getTooltipTitle = backgroundJobDetail => {
        if (getDateFromPastMonth(backgroundJobDetail)) {
            return 'File is Valid Only For 30 Days';
        } else if (
            (backgroundJobDetail.job_type === 'Add Bulk Tags' ||
                backgroundJobDetail.job_type === 'Bulk Update Subscription End Date') &&
            !backgroundJobDetail.download_centre_file_detail_id
        ) {
            return '';
        } else if (!backgroundJobDetail.download_centre_file_detail_id) {
            return 'Download Centre File Not Found';
        } else if (backgroundJobDetail.status === 'Failed') {
            return 'Job is Failed';
        } else if (backgroundJobDetail.status != 'Completed') {
            return 'Job is still in Process';
        } else {
            return 'Download CSV File';
        }
    };

    // Show and Hide Extra Info method
    const handleShowExtraInfo = panel => {
        if (panel == showExtraInfo) {
            setShowExtraInfo(false);
        } else {
            setShowExtraInfo(panel);
        }
    };

    return (
        <>
            {backgroundJobDetails.length > 0 ? (
                <>
                    <Box className={classes.totalRecords}>
                        <Typography>
                            <strong> Total Records: {totalRecords} </strong>
                        </Typography>
                    </Box>
                    <Box className={classes.paper}>
                        <BackgroundTaskListHeader />
                        <Box>
                            {backgroundJobDetails.map((backgroundJobDetail, index) => (
                                <Accordion
                                    key={index}
                                    classes={{ root: classes.expansionPannelRoot }}
                                    expanded={expanded === index}
                                    onChange={handleChange(index)}>
                                    <AccordionSummary
                                        expandIcon={<CustomTooltip variable={expanded} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Box display="flex" alignItems="cneter" width="100%">
                                            <Box flex="2.6">{backgroundJobDetail.email}</Box>
                                            <Box flex="2">{backgroundJobDetail.job_type}</Box>
                                            <Box flex="1.6">{backgroundJobDetail.queued_at}</Box>
                                            <Box flex="0.9">
                                                {backgroundJobDetail.status
                                                    ? backgroundJobDetail.status
                                                    : 'NA'}
                                            </Box>
                                            <Box flex="1" textAlign="center">
                                                {backgroundJobDetail.total_count
                                                    ? backgroundJobDetail.progress +
                                                      ' / ' +
                                                      backgroundJobDetail.total_count
                                                    : backgroundJobDetail.progress + ' %'}

                                                {backgroundJobDetail.status != 'Completed' &&
                                                    backgroundJobDetail.status != 'Failed' && (
                                                        <Tooltip arrow title="Refresh">
                                                            <IconButton
                                                                className={classes.iconButton}
                                                                onClick={event =>
                                                                    getSpcificJobDetails(
                                                                        event,
                                                                        backgroundJobDetail,
                                                                        index,
                                                                    )
                                                                }>
                                                                <Refresh />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.pannedDetails}>
                                        <Box width="100%">
                                            <TableContainer>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>Started At</strong>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <strong>Finished At</strong>
                                                            </TableCell>
                                                            {backgroundJobDetail.job_with_file && (
                                                                <TableCell align="center">
                                                                    <strong>Download As CSV</strong>
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {backgroundJobDetail.started_at
                                                                    ? backgroundJobDetail.started_at
                                                                    : 'NA'}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {backgroundJobDetail.finished_at
                                                                    ? backgroundJobDetail.finished_at
                                                                    : 'NA'}
                                                            </TableCell>
                                                            {backgroundJobDetail.job_with_file && (
                                                                <TableCell>
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center">
                                                                        <MuiThemeProvider
                                                                            theme={theme}>
                                                                            <Tooltip
                                                                                arrow
                                                                                placement="top"
                                                                                title={getTooltipTitle(
                                                                                    backgroundJobDetail,
                                                                                )}>
                                                                                <Box minWidth="20px">
                                                                                    <IconButton
                                                                                        disabled={
                                                                                            !backgroundJobDetail.download_centre_file_detail_id ||
                                                                                            getDateFromPastMonth(
                                                                                                backgroundJobDetail,
                                                                                            ) ||
                                                                                            backgroundJobDetail.status !=
                                                                                                'Completed'
                                                                                        }
                                                                                        onClick={e =>
                                                                                            getDownloadCenterFile(
                                                                                                backgroundJobDetail,
                                                                                            )
                                                                                        }>
                                                                                        <GetApp />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </MuiThemeProvider>
                                                                        {(backgroundJobDetail.job_type ===
                                                                            'Add Bulk Tags' ||
                                                                            backgroundJobDetail.job_type ===
                                                                                'Bulk Update Subscription End Date') && (
                                                                            <InfoIconTooltip
                                                                                placement="top"
                                                                                title="Download CSV is made available only for Failed Records">
                                                                                <IconButton>
                                                                                    <InfoIcon />
                                                                                </IconButton>
                                                                            </InfoIconTooltip>
                                                                        )}
                                                                    </Box>
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <Box>
                                                <a
                                                    color="primary"
                                                    className={classes.showExtraInfoLink}
                                                    onClick={() =>
                                                        handleShowExtraInfo(backgroundJobDetail.id)
                                                    }>
                                                    {showExtraInfo == backgroundJobDetail.id
                                                        ? 'Hide extra info'
                                                        : 'Show extra info'}
                                                </a>
                                            </Box>
                                            {showExtraInfo == backgroundJobDetail.id && (
                                                <Box>
                                                    <ul>
                                                        <li>
                                                            <b> Response - </b>{' '}
                                                            {backgroundJobDetail.response
                                                                ? backgroundJobDetail.response
                                                                : 'NA'}
                                                        </li>
                                                        <li>
                                                            <b> Description - </b>{' '}
                                                            {backgroundJobDetail.description
                                                                ? backgroundJobDetail.description
                                                                : 'NA'}
                                                        </li>
                                                    </ul>
                                                </Box>
                                            )}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>

                    <Box className={classes.paginationSection}>
                        <Pagination
                            color="primary"
                            count={totalPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handleChangePage}
                        />
                    </Box>
                </>
            ) : (
                <Typography className={classes.noActivityFound}>
                    <strong> No Activity Found!! </strong>
                </Typography>
            )}
        </>
    );
};

const BackgroundTaskListHeader = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.flexRoot}>
            <Box className={clsx(classes.headerItem, classes.itemOne)}>ADMIN</Box>
            <Box className={clsx(classes.headerItem, classes.itemTwo)}>ACTIVITY</Box>
            <Box className={clsx(classes.headerItem, classes.itemThree)}>DATE</Box>
            <Box className={clsx(classes.headerItem, classes.itemTwo)}>STATUS</Box>
            <Box className={clsx(classes.headerItem, classes.itemFive)}>PROGRESS</Box>
            <Box className={clsx(classes.headerItem, classes.itemFive)}></Box>
        </Paper>
    );
};

export default BulkActionStatusList;
