import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    createMuiTheme,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MuiThemeProvider,
    Popover,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import SearchBox from 'components/utils/SearchBox';
import { cloneDeep, indexOf as lodashIndexOf, remove } from 'lodash';
import useSearchHook from 'components/profiles/Hooks/useSearchHook';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(-4px, -7px) scale(1)',
            },
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'none !important',
                },
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    selectButton: {
        width: '190px',
        height: 40,
        alignItems: 'Left',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        fontSize: 13,
        paddingRight: '5px 0px 5px 10px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    FormControl: {
        border: '1px solid #ced4da',
        borderRadius: 4,
    },
    selectRegionDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'none',
    },
    listText: {
        fontSize: '12px',
    },
    dropdownActionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    regionsList: {
        maxHeight: '260px',
        overflow: 'auto',
    },
    dropdownSearchBar: {
        backgroundColor: 'white',
        margin: '10px',
    },
}));

const CustomRegionMultiSelect = props => {
    const classes = useStyles();
    const { allRegions, selectedRegion, setSelectedRegion, setHideClearButton, setFilterClicked } =
        props;

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchString, setSearchString] = useState('');

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setSearchString('');
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleOkClick = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-popover' : undefined;

    const handleCancel = () => {
        setAnchorEl(null);
        setOpen(false);
        setSelectedRegion([]);
        setHideClearButton(true);
    };

    const handleFilterChange = event => {
        setFilterClicked(false);
        setHideClearButton(false);
        let selectedRegionNames = event.target.value;
        let newSelected = cloneDeep(selectedRegion);
        lodashIndexOf(newSelected, selectedRegionNames) != -1
            ? remove(newSelected, n => n == selectedRegionNames)
            : newSelected.push(selectedRegionNames);
        setSelectedRegion(newSelected);
    };

    /**
     * Store search string to render searched app list
     * @param {*} event
     */

    const onChange = event => {
        setSearchString(event.target.value);
    };

    const { _list } = useSearchHook({
        searchString: searchString,
        keys: ['name'],
        list: allRegions,
    });
    return (
        <MuiThemeProvider theme={theme}>
            <form autoComplete="off">
                <FormControl className={classes.FormControl} variant="outlined">
                    <InputLabel
                        style={{
                            backgroundColor: 'white',
                            paddingLeft: '10px',
                            justifyContent: 'center !important',
                            display: 'flex !important',
                            paddingRight: '10px',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: 'inherit',
                            marginLeft: '62px',
                        }}>
                        Regions
                    </InputLabel>
                    <Button
                        className={classes.selectButton}
                        id="mp-filter-select-region"
                        disableRipple={true}
                        disableFocusRipple={true}
                        onClick={handleClick}>
                        <div className={classes.selectRegionDiv}>
                            {selectedRegion.length == 0
                                ? 'Filter By Region'
                                : `Selected ${selectedRegion.length} Region(s)`}
                            <ArrowDropDown />
                        </div>
                    </Button>
                </FormControl>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Box>
                        <Box className={classes.dropdownSearchBar}>
                            <SearchBox
                                id="filter-by-region-search-input"
                                inputProps={{ placeholder: 'Search Regions' }}
                                onChange={onChange}
                            />
                        </Box>
                        <Box>
                            <List className={classes.regionsList}>
                                {_list &&
                                    _list.map((region, index) => (
                                        <ListItem key={index}>
                                            <Checkbox
                                                onClick={handleFilterChange}
                                                edge="start"
                                                color="primary"
                                                value={region.name}
                                                checked={selectedRegion.indexOf(region.name) > -1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': `checkbox-list-label-${region.name}`,
                                                    'data-select-region-checkbox': `${region.name}`,
                                                }}
                                            />
                                            <ListItemText
                                                className={classes.listText}
                                                id={`checkbox-list-label-${region.name}`}
                                                primary={region.name}
                                            />
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                        <Box className={classes.dropdownActionButton}>
                            <Button onClick={handleCancel} color="primary">
                                CANCEL
                            </Button>
                            <Button onClick={handleOkClick} color="primary">
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Popover>
            </form>
        </MuiThemeProvider>
    );
};

export default CustomRegionMultiSelect;
