import React, { Fragment, useState, useContext } from 'react';
import {
    Typography,
    IconButton,
    Popover,
    makeStyles,
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';
import http from 'core/http';
import { UserContext } from 'components/providers/UserProvider';
import { SET_LOGIN_STATE } from 'components/providers/actions';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        minWidth: 300,
        fontSize: '1rem',
    },

    name: {
        marginLeft: 15,
        fontWeight: 500,
    },

    avatar: {
        background: theme.palette.primary.main,
    },

    button: {
        fontSize: 14,
    },
}));

function ListItemLink(props) {
    return <ListItem button component="button" {...props} />;
}

export default props => {
    const { user } = props;
    const classes = useStyles();
    // For navigation methods
    let history = useHistory();

    const [state, dispatch] = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = e => {
        setAnchorEl(e.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // TODO: error handling
    function logOut() {
        http.delete('/users/sign_out.json').then(() => {
            history.push('/moderator-dash/login');
            dispatch({
                type: SET_LOGIN_STATE,
                payload: false,
            });
        });
    }

    function goToMyProfile() {
        // Close popup
        handlePopoverClose();
        // Navigate to route
        history.push(process.env.PUBLIC_URL + '/my-profile');
    }

    // For Account Settings Navigation Link
    function goToSettings() {
        handlePopoverClose();
        history.push(process.env.PUBLIC_URL + '/account-settings');
    }

    return (
        <Fragment>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handlePopoverOpen}
                id="user-account-menu-button">
                <AccountCircleIcon />
            </IconButton>

            {user.name && (
                <Popover
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    open={open}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <Box className={classes.root}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Avatar className={classes.avatar} alt={user.name}>
                                {user.name.slice(0, 1)}
                            </Avatar>
                            <Box>
                                <Typography className={classes.name}>{user.name}</Typography>
                                <Typography className={classes.name}>{user.email}</Typography>
                            </Box>
                        </Box>
                        <Divider style={{ marginTop: 10 }} />
                        <List component="nav" aria-label="main account logout">
                            <ListItemLink
                                onClick={goToMyProfile}
                                className={classes.button}
                                id="user-account-link">
                                Your Account
                            </ListItemLink>
                            <ListItemLink
                                onClick={goToSettings}
                                className={classes.button}
                                id="settings">
                                Settings
                            </ListItemLink>
                            <ListItemLink onClick={logOut} className={classes.button} id="logout">
                                Logout
                            </ListItemLink>
                        </List>
                    </Box>
                </Popover>
            )}
        </Fragment>
    );
};
