import http from 'core/http';

const loadUserDetails = async () => {
    // try {
    return await http.get('/api/v3/me.json');
    // console.log(data.user)
    // return data.user
    // } catch (error) {
    //     // TODO: redirect to login page
    //     console.log(error)
    //     return error
    // }
};

const createUser = async params => {
    return await http.post(`/api/v3/users`, params);
};

const fetchQRCodeData = async userId => {
    try {
        const { data } = await http.get(`/api/v3/users/${userId}/qr_code_data`);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteUser = async (userId, password) => {
    try {
        const { data } = await http.delete(`/api/v3/users/${userId}`, {
            params: { password: password },
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteDevice = async (userId, deviceId, password) => {
    try {
        const { data } = await http.delete(
            `/api/v3/users/${userId}/device_user_devices/${deviceId}`,
            { params: { password: password } },
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const removeTags = async (userId, tag) => {
    try {
        const { data } = await http.put(`/api/v3/users/${userId}/device_user_tags/remove_tag`, {
            tags: tag,
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const addTags = async (userId, tag) => {
    try {
        const { data } = await http.post(`/api/v3/users/${userId}/device_user_tags`, { tags: tag });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const loadMasterProfileList = async userId => {
    try {
        const { data } = await http.get(`/api/v3/users/${userId}/profile_list.json`);
        // console.log(data);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const loadDuplicateDevices = async parameters => {
    try {
        const { data } = await http.get(`/api/v3/users/duplicate_devices.json`, {
            params: parameters,
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteDuplicateDevices = async deviceIds => {
    try {
        const { data } = await http.delete(`/api/v3/users/delete_duplicate_devices.json`, {
            params: { device_user_device_ids: deviceIds },
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateSubscriptionDate = async (userId, subscriptionDate) => {
    try {
        const { data } = await http.post(`/api/v3/users/${userId}/update_subscription`, {
            subscription_end_date: subscriptionDate,
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const loadUsersOnMultipleDevices = async parameters => {
    try {
        const { data } = await http.get(`/api/v3/users/enrolled_on_multiple_devices.json`, {
            params: parameters,
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const bulkDeleteDevices = async (userId, deviceIds) => {
    // try {
    return await http.delete(
        `/api/v3/users/${userId}/device_user_devices/bulk_delete_device_user_devices`,
        { params: { device_user_device_ids: deviceIds } },
    );
    //     return data;
    // } catch (error) {
    //     console.log(error);
    //     return error;
    // }
};

const getUnusedTagsApi = async parameters => {
    try {
        const { data } = await http.get(`/api/v3/users_utilities/unused_tags`, {
            params: parameters,
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const removeUnusedTagsApi = async (unused_tags, password) => {
    try {
        const { data } = await http.post(`/api/v3/users_utilities/remove_unused_tags`, {
            unused_tags: unused_tags,
            password: password,
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const addBulkTagsApi = async (tags, file, password) => {
    const _data = new FormData();
    _data.append('tags', JSON.stringify(tags.map(tag => tag)));
    _data.append('file', file);
    _data.append('password', password);

    try {
        const { data } = await http.post(`/api/v3/users_utilities/add_bulk_tags`, _data);
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateSubscriptionApi = async (subscription_end_date, file, password) => {
    const _data = new FormData();
    _data.append('subscription_end_date', subscription_end_date);
    _data.append('file', file);
    _data.append('password', password);

    try {
        const { data } = await http.post(
            `/api/v3/users_utilities/bulk_update_subscription_end_date`,
            _data,
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const unlockDevice = async (userId, deviceId, password) => {
    try {
        const { data } = await http.put(
            `/api/v3/users/${userId}/device_user_devices/${deviceId}/unlock_device`,
            { password: password },
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export default {
    loadUserDetails,
    createUser,
    fetchQRCodeData,
    deleteUser,
    deleteDevice,
    loadMasterProfileList,
    loadDuplicateDevices,
    deleteDuplicateDevices,
    addTags,
    removeTags,
    updateSubscriptionDate,
    loadUsersOnMultipleDevices,
    bulkDeleteDevices,
    getUnusedTagsApi,
    removeUnusedTagsApi,
    addBulkTagsApi,
    updateSubscriptionApi,
    unlockDevice,
};
