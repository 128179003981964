import React, { Suspense, useContext } from 'react';
import './App.scss';

import CircularProgress from '@material-ui/core/CircularProgress';
import AppNavBar from 'components/navbar/AppNavBar';

import { ThemeProvider } from '@material-ui/core/styles';
import Theme from 'theme';
import { CssBaseline } from '@material-ui/core';

import { Switch, Route, Redirect } from 'react-router-dom';

// import all routes
import useRouterHook from './Hooks/useRoutesHook';
import Authenticator from 'components/utils/Authenticator';
import { UserContext } from 'components/providers/UserProvider';

function App() {
    const [{ permissions }] = useContext(UserContext);
    const { Routes } = useRouterHook(permissions);

    return (
        <div className="app" style={{ paddingTop: 60 }}>
            {/* <UserProvider> */}
            <Authenticator>
                <ThemeProvider theme={Theme}>
                    <CssBaseline />
                    <AppNavBar />
                    <main style={{ height: 'calc(100% - 49px)' }}>
                        <Suspense fallback={<CircularProgress size={24} />}>
                            <Switch>
                                {Routes.map((route, index) =>
                                    route.redirect ? (
                                        <Redirect key={route.path} from={route.path} to="/" />
                                    ) : (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ),
                                )}
                            </Switch>
                        </Suspense>
                    </main>
                </ThemeProvider>
            </Authenticator>
            {/* </UserProvider> */}
        </div>
    );
}

export default App;
