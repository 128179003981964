import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, FormControl, Select, MenuItem, Button } from '@material-ui/core/';
import { FilterList } from '@material-ui/icons';
import clsx from 'clsx';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import DateRange from '../DateRange';
import { capitalize } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                // // For Outline box
                // marginLeft: '-25px',
                width: '65%',
                // textAlign: 'center',
                padding: 0,
                marginBottom: 5,
            },
            root: {
                justifyContent: 'center',
            },
        },
        MuiInputAdornment: {
            root: {
                width: 'max-content',
                marginBottom: 7,
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(21px, 14px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '11px',
            },
        },
        MuiSelect: {
            outlined: {
                marginBottom: '3px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        width: '25%',
        marginRight: 15,
    },
    filters: {
        background: 'white',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        padding: 10,
    },
    capitalize: {
        textTransform: 'capitalize',
    },
}));

const Filters = props => {
    const classes = useStyles();
    const { filters, setFilters, taskTypes, fetchData, getFromDate, getToDate, setPage } = props;
    const [clearClicked, setClearClicked] = useState(false);

    const handleFilterChange = (key, event) => {
        setFilters({ ...filters, [key]: event.target.value });
        setClearClicked(false);
    };

    // const handleDateFilterChange = date => {
    //     setFilters({ ...filters, ['date']: date });
    // };

    const handleFromDateChange = date => {
        setFilters({ ...filters, ['from_date']: date });
        setClearClicked(false);
    };

    const handleToDateChange = date => {
        setFilters({ ...filters, ['to_date']: date });
        setClearClicked(false);
    };

    const clearFilters = () => {
        setClearClicked(true);
        setFilters({
            // date: new Date(),
            from_date: getFromDate(),
            to_date: getToDate(),
            task_type: 'all',
        });
        setPage(1);
    };

    useEffect(() => {
        if (clearClicked) fetchData();
    }, [clearClicked]);

    return (
        <Box className={classes.filters}>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between">
                <Box display="flex" style={{ marginTop: '' }}>
                    <FilterList className={classes.spaceAround} />
                    <Typography> Filter Activities </Typography>
                </Box>
                {/* --------------Performed at filter------------------------- */}
                {/* <Box>
                    <FormControl className={classes.dateControl}>
                        <Box border="1px solid #ced4da" borderRadius="4px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginLeft="auto"
                                marginRight="auto"
                                width="max-content"
                                paddingLeft="10px"
                                paddingRight="10px"
                                style={{ background: 'white', marginTop: '-10px' }}>
                                Data Ingestion on
                            </Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <MuiThemeProvider theme={theme}>
                                    <Box textAlign="center" marginBottom="5px" marginTop="5px">
                                        <KeyboardDatePicker
                                            style={{ width: '60%', marginTop: '0px' }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            autoOk
                                            inputVariant="standard"
                                            // label="App Usage on"
                                            format="dd MMM yyyy"
                                            value={filters.date}
                                            InputAdornmentProps={{ position: 'start' }}
                                            clearable={false}
                                            disableFuture
                                            allowKeyboardControl={false}
                                            onChange={date => handleDateFilterChange(date)}
                                        />
                                    </Box>
                                </MuiThemeProvider>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </FormControl>
                </Box> */}
                <Box>
                    <FormControl className={classes.dateControl}>
                        <DateRange
                            fromDate={filters.from_date}
                            toDate={filters.to_date}
                            handleFromDateChange={handleFromDateChange}
                            handleToDateChange={handleToDateChange}
                            datePickerLabel="Data Ingestion Between"
                        />
                    </FormControl>
                </Box>
                {/* --------------TASK TYPE filter------------------------- */}
                <Box>
                    <MuiThemeProvider theme={theme}>
                        <FormControl className={classes.formControl} variant="outlined">
                            <Select
                                className={(classes.dropdown, classes.capitalize)}
                                value={filters.task_type}
                                classes={{ root: classes.select, icon: classes.selectIcon }}
                                onChange={e => handleFilterChange('task_type', e)}
                                MenuProps={MenuProps}>
                                <MenuItem value="all">All</MenuItem>
                                {Object.entries(taskTypes).map(([key, value]) => (
                                    <MenuItem key={key} value={key} className={classes.capitalize}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MuiThemeProvider>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            disabled={
                                filters.from_date == 'Invalid Date' ||
                                filters.from_date == null ||
                                filters.to_date == 'Invalid Date' ||
                                filters.to_date == null
                            }
                            onClick={fetchData}>
                            <strong>FILTER</strong>
                        </Button>
                    </Box>
                    <Box marginLeft="15px">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            className={clsx(classes.button, {})}
                            onClick={clearFilters}>
                            <strong>Clear</strong>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Filters;
