/**
 * Scalefusion Apps page
 */
import React, { useState } from 'react';
import {
    Checkbox,
    ListItemText,
    ListItem,
    List,
    Popover,
    TextField,
    Chip,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Box, FormControl, Select, InputBase, MenuItem, Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import DateRange from '../DateRange';
import useSearchHook from 'components/profiles/Hooks/useSearchHook';
import SearchBox from 'components/utils/SearchBox';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                marginLeft: '-15px',
            },
        },
    },
});

const theme1 = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
    },
});

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        width: 200,
        // marginLeft: 20,
        height: 40,
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 10,
        padding: '5px 0px 5px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'Camphor,sans-serif',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 18,
        marginBottom: 20,
        color: 'inherit',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },
    searchField: {
        width: '30%',
        marginBottom: 'auto',
        border: '1px solid #ced4da',
        borderRadius: 4,
        // height: 1.1875em;
        marginRight: 10,
        display: 'block',
        padding: '12px 8px',
        paddingTop: '14px',
        paddingBottom: '14px',
        fontSize: 14,
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        // '&:focus': {
        //     outline: 'none',
        //     borderBottom: `1px solid ${theme.palette.primary.main}`,
        // },
        marginTop: 5,
    },
    serialNoSearchField: {
        width: '100%',
        border: '1px solid #ced4da',
        borderRadius: 4,
        display: 'block',
        padding: '12px 8px',
        fontSize: 14,
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        marginTop: 25,
    },
    clearBtn: {
        transition: 'all 0.2s ease-in',
        marginLeft: 17,
        padding: '3px 15px',
    },
    dropdown: {
        minWidth: '280px',
    },
    selectButton: {
        borderRadius: 4,
        width: '160px',
        // marginLeft: 20,
        height: 40,
        alignItems: 'Left',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 13,
        paddingRight: '5px 0px 5px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'Camphor,sans-serif',
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    selectProfileDiv: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '180px',
        textTransform: 'none',
    },
    selectSerialNos: {
        width: 230,
    },
    autoCompleteTags: {
        padding: '5px !important',
        borderRadius: '4px !important',
    },

    autoComplete: {
        padding: '2px !important',
        borderRadius: '4px !important',
    },

    listText: {
        fontSize: '12px',
    },

    select: {
        height: 29,
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
    },

    selectLabel: {
        marginBottom: 5,
    },

    sticky: {
        borderTop: '1px solid',
        position: 'sticky',
        width: '100%',
        background: '#fff',
        bottom: 0,
    },

    selectIcon: {
        marginRight: 12,
    },

    arrowDown: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    hideClrButton: {
        visibility: 'hidden',
    },
    searchTagField: {
        width: '200px',
        marginLeft: 10,
        marginBottom: 0,
        marginRight: 20,
        marginTop: '-10px',
    },
    row1: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '20px',
    },
    row2: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        marginBottom: '20px',
    },
    row3: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    masterProfileList: {
        maxHeight: '260px', // Earlier maxHeight: '300px'
        overflow: 'auto',
    },

    dropdownSearchBar: {
        backgroundColor: 'white',
        margin: '10px',
    },

    dropdownActionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    dateRangeLabelBox: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'max-content',
        paddingLeft: '10px',
        paddingRight: '10px',
        background: 'white',
        marginTop: '-10px',
    },
    notAllowed: {
        cursor: 'not-allowed !important',
    },
}));

const ReportFilterBar = props => {
    const classes = useStyles();

    const {
        masterProfiles,
        filters,
        setFilters,
        setPage,
        loadDataWithFilter,
        allTags,
        sortBy,
        setSortBy,
        showDeviceStatusFilter,
        showSerialNoFilter,
        clearFilter,
        loading,
        selectedRegion,
        hideClearButton,
        setHideClearButton,
    } = props;

    const [selectedTags, setSelectedTags] = useState([]);
    const isRegionSelected = selectedRegion && selectedRegion.length == 0;

    const clearAllFilters = () => {
        setHideClearButton(true);
        setSelectedTags([]);
        clearFilter();
    };

    const handleSubmitClick = () => {
        loadDataWithFilter();
    };

    const handleSearchTextChange = event => {
        setHideClearButton(false);
        setFilters({ ...filters, ['query']: event.target.value });
        setPage(1);
    };

    const handleSearchSerialNumber = event => {
        setHideClearButton(false);
        setFilters({ ...filters, ['serialNos']: event.target.value });
        setPage(1);
    };

    const handleTagFilterChange = tags => {
        setHideClearButton(false);
        setSelectedTags(tags);
        var nameArray = tags.map(function (el) {
            return el.name;
        });
        setFilters({ ...filters, ['tags']: nameArray });
        setPage(1);
    };

    const handleFromDateChange = date => {
        setHideClearButton(false);
        setFilters({ ...filters, ['fromDate']: date });
    };

    const handleToDateChange = date => {
        setHideClearButton(false);
        setFilters({ ...filters, ['toDate']: date });
    };

    return (
        <Paper className={classes.toolBar}>
            <Box display="flex" flexDirection="column" width="100%">
                <Box className={classes.row1}>
                    {showDeviceStatusFilter && (
                        <Box
                            flexDirection="column"
                            alignItems="flex-start"
                            display="flex"
                            mr="20px">
                            <span className={classes.selectLabel}>Device Subscription Status</span>
                            <DeviceStatusDropdown
                                filters={filters}
                                setFilters={setFilters}
                                setPage={setPage}
                                isRegionSelected={isRegionSelected}
                                setHideClearButton={setHideClearButton}
                            />
                        </Box>
                    )}
                    {masterProfiles.length > 0 && (
                        <Box
                            flexDirection="column"
                            alignItems="flex-start"
                            display="flex"
                            mr="20px">
                            <span className={classes.selectLabel}>Master Profiles</span>
                            <MasterProfileDropdown
                                data={masterProfiles}
                                filters={filters}
                                setFilters={setFilters}
                                setPage={setPage}
                                isRegionSelected={isRegionSelected}
                                setHideClearButton={setHideClearButton}
                            />
                        </Box>
                    )}
                    <Box mr="40px">
                        <input
                            placeholder="Search by Serial Numbers"
                            id="search"
                            type="search"
                            className={clsx(classes.serialNoSearchField, {
                                [classes.notAllowed]: isRegionSelected,
                            })}
                            value={filters.serialNos}
                            onChange={e => handleSearchSerialNumber(e)}
                            disabled={isRegionSelected}
                        />
                    </Box>
                    {sortBy && (
                        <Box
                            flexDirection="column"
                            alignItems="flex-start"
                            display="flex"
                            mr="20px">
                            <span className={classes.selectLabel}>Sort By</span>
                            <UsersSortByDropdown
                                setHideClearButton={setHideClearButton}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                            />
                        </Box>
                    )}
                </Box>
                <Box
                    className={classes.row2}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-end">
                    <input
                        placeholder="Search by User IDs separated by comma"
                        id="search"
                        type="search"
                        className={clsx(classes.searchField, {
                            [classes.notAllowed]: isRegionSelected,
                        })}
                        value={filters.query}
                        onChange={e => handleSearchTextChange(e)}
                        disabled={isRegionSelected}
                    />
                    <Box className={classes.searchTagField}>
                        <MuiThemeProvider theme={theme1}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={allTags}
                                value={selectedTags}
                                classes={{ inputRoot: classes.autoCompleteTags }}
                                disabled={isRegionSelected}
                                onChange={(event, newInputValue) => {
                                    handleTagFilterChange(newInputValue);
                                }}
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        // label="Search tags"
                                        placeholder="Search tags"
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            variant="outlined"
                                            label={option.name}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />
                        </MuiThemeProvider>
                    </Box>
                    {filters.reportType != 'subscription_transfer' ? (
                        <Box marginBottom="0">
                            <SubscriptionUsageDateRange
                                fromDate={filters.fromDate}
                                toDate={filters.toDate}
                                isRegionSelected={isRegionSelected}
                                handleFromDateChange={handleFromDateChange}
                                handleToDateChange={handleToDateChange}
                            />
                        </Box>
                    ) : (
                        <Box border="1px solid #ced4da" borderRadius="4px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginLeft="auto"
                                marginRight="auto"
                                width="max-content"
                                paddingLeft="10px"
                                paddingRight="10px"
                                style={{ background: 'white', marginTop: '-10px' }}>
                                {' '}
                                Transfer End Date Between{' '}
                            </Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Box textAlign="center" marginBottom="5px">
                                    <MuiThemeProvider theme={theme}>
                                        <KeyboardDatePicker
                                            clearable="true"
                                            value={filters.fromDate}
                                            onChange={handleFromDateChange}
                                            minDate={new Date()}
                                            format="dd/MM/yyyy"
                                            style={{ width: '122px', marginTop: '0px' }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            variant="inline"
                                            InputAdornmentProps={{ position: 'start' }}
                                        />

                                        <KeyboardDatePicker
                                            value={filters.toDate}
                                            onChange={handleToDateChange}
                                            minDate={filters.fromDate}
                                            format="dd/MM/yyyy"
                                            style={{ width: '122px', marginTop: '0px' }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            variant="inline"
                                            InputAdornmentProps={{ position: 'start' }}
                                        />
                                    </MuiThemeProvider>
                                </Box>
                            </MuiPickersUtilsProvider>
                        </Box>
                    )}
                </Box>
                <Box className={classes.row3}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={clsx(classes.clearBtn, hideClearButton && classes.hideClrButton)}
                        onClick={clearAllFilters}>
                        <strong>Clear</strong>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            loading ||
                            isRegionSelected ||
                            filters.fromDate == 'Invalid Date' ||
                            filters.fromDate == null ||
                            filters.toDate == 'Invalid Date' ||
                            filters.toDate == null
                        }
                        className={clsx(classes.clearBtn)}
                        onClick={handleSubmitClick}>
                        <strong>FILTER</strong>
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

const DeviceStatusDropdown = props => {
    const classes = useStyles();
    const { filters, setFilters, setPage, setHideClearButton, isRegionSelected } = props;

    const handleChange = event => {
        setHideClearButton(false);
        setFilters({ ...filters, ['status']: event.target.value });
        setPage(1);
    };

    return (
        <form autoComplete="off">
            <FormControl>
                <Select
                    className={classes.dropdown}
                    value={filters.status}
                    classes={{
                        root: classes.select,
                        icon: classes.selectIcon,
                        disabled: classes.notAllowed,
                    }}
                    disabled={isRegionSelected}
                    onChange={handleChange}
                    input={<BootstrapInput name="filter" />}>
                    <MenuItem value="all">All Devices</MenuItem>
                    <MenuItem value={'Active'}>Active</MenuItem>
                    <MenuItem value={'Expired'}>Expired</MenuItem>
                </Select>
            </FormControl>
        </form>
    );
};

const MasterProfileDropdown = props => {
    const classes = useStyles();
    const { data, filters, setFilters, setPage, setHideClearButton, isRegionSelected } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // Search string
    const [searchString, setSearchString] = useState('');

    const handleChange = event => {
        setHideClearButton(false);
        let selectedProfiles = filters.masterProfileIds;
        let index = selectedProfiles.indexOf(parseInt(event.target.value));
        if (index > -1) {
            selectedProfiles.splice(index, 1);
        } else {
            selectedProfiles.push(parseInt(event.target.value));
        }
        setFilters({ ...filters, ['masterProfileIds']: selectedProfiles });
        setPage(1);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setSearchString('');
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleCancel = () => {
        setFilters({ ...filters, ['masterProfileIds']: [] });
        handleClose();
    };

    const id = open ? 'simple-popover' : undefined;

    /**
     * Store search string to render searched app list
     * @param {*} event
     */

    const onChange = event => {
        setSearchString(event.target.value);
    };

    const { _list } = useSearchHook({
        searchString: searchString,
        keys: ['name'],
        list: data,
    });

    return (
        <form autoComplete="off">
            <FormControl className={classes.marginLeft}>
                <span style={{ cursor: 'not-allowed' }}>
                    <Button
                        className={classes.selectButton}
                        onClick={handleClick}
                        disabled={isRegionSelected}>
                        <div className={classes.selectProfileDiv}>
                            <div>
                                {filters.masterProfileIds.length == 0
                                    ? 'Select Profiles'
                                    : `${filters.masterProfileIds.length} selected`}
                            </div>
                            <ArrowDropDown className={classes.arrowDown} />
                        </div>
                    </Button>
                </span>
            </FormControl>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Box>
                    <Box className={classes.dropdownSearchBar}>
                        <SearchBox
                            id="mp-master-profile-app-search-input"
                            inputProps={{ placeholder: 'Search Master Profiles' }}
                            onChange={onChange}
                        />
                    </Box>
                    <Box>
                        <List className={classes.masterProfileList}>
                            {_list &&
                                _list.map(profile => (
                                    <ListItem key={profile.id}>
                                        <Checkbox
                                            onClick={handleChange}
                                            edge="start"
                                            value={profile.id}
                                            checked={
                                                filters.masterProfileIds.indexOf(profile.id) > -1
                                            }
                                            disableRipple
                                            // inputProps={{
                                            //     'aria-labelledby': `checkbox-list-label-${profile.name}`,
                                            // }}
                                            color="primary"
                                        />
                                        <ListItemText
                                            className={classes.listText}
                                            id={`checkbox-list-label-${profile.name}`}
                                            primary={profile.name}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    </Box>
                    <Box className={classes.dropdownActionButton}>
                        <Button onClick={handleCancel} color="primary">
                            CANCEL
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            OK
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </form>
    );
};

const UsersSortByDropdown = props => {
    const classes = useStyles();
    const { sortBy, setSortBy, setHideClearButton } = props;

    const handleChange = event => {
        setHideClearButton(false);
        setSortBy(event.target.value);
    };

    return (
        <form autoComplete="off">
            <FormControl>
                <Select
                    className={classes.dropdown}
                    value={sortBy}
                    classes={{ root: classes.select, icon: classes.selectIcon }}
                    onChange={handleChange}
                    input={<BootstrapInput name="filter" />}>
                    <MenuItem value={'subscription_transfer_expiry'}>
                        Subscription Transfer Window Expiry
                    </MenuItem>
                    <MenuItem value={'max_pool_impact'}>Maximum Impact on Pool</MenuItem>
                    <MenuItem value="15_days_grace_period">
                        Grace Period Expiring in 15 days
                    </MenuItem>
                    <MenuItem value={'new_to_old'}>Newest to Oldest</MenuItem>
                    <MenuItem value={'old_to_new'}>Oldest to Newest</MenuItem>
                </Select>
            </FormControl>
        </form>
    );
};

const SubscriptionUsageDateRange = props => {
    const classes = useStyles();
    const { fromDate, toDate, handleFromDateChange, handleToDateChange, isRegionSelected } = props;

    return (
        <Box border="1px solid #ced4da" borderRadius="4px" marginBottom="auto">
            <Box className={classes.dateRangeLabelBox}>Subscription End Date Between</Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box textAlign="center" marginBottom="5px">
                    <MuiThemeProvider theme={theme}>
                        <KeyboardDatePicker
                            style={{ width: '122px', marginTop: '0px' }}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    disabled: classes.notAllowed,
                                },
                            }}
                            variant="inline"
                            autoOk={true}
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            disabled={isRegionSelected}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            InputAdornmentProps={{ position: 'start' }}
                        />

                        <KeyboardDatePicker
                            style={{ width: '122px', marginTop: '0px' }}
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    disabled: classes.notAllowed,
                                },
                            }}
                            variant="inline"
                            autoOk={true}
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            value={toDate}
                            onChange={handleToDateChange}
                            disabled={isRegionSelected}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            InputAdornmentProps={{ position: 'start' }}
                        />
                    </MuiThemeProvider>
                </Box>
            </MuiPickersUtilsProvider>
        </Box>
    );
};

ReportFilterBar.defaultProps = {
    masterProfiles: [],
    serialNos: [],
    filters: {},
    allTags: [],
    sortBy: undefined,
    showDeviceStatusFilter: true,
    setPage: undefined,
    showSerialNoFilter: false,
};

export default ReportFilterBar;
