/**
 * Scalefusion Apps page
 */
import React, { useState } from 'react';
import { Checkbox, ListItemText, ListItem, List, Popover, InputLabel } from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { cloneDeep, indexOf as lodashIndexOf, remove, shuffle } from 'lodash';
import { Box, FormControl, Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import useSearchHook from 'components/profiles/Hooks/useSearchHook';
import SearchBox from 'components/utils/SearchBox';
const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(-4px, -7px) scale(1)',
            },
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'none !important',
                },
            },
        },
    },
});
const useStyles = makeStyles(theme => ({
    selectButton: {
        width: '162px',
        height: 40,
        alignItems: 'Left',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        fontSize: 13,
        textTransform: 'none',
        paddingRight: '5px 0px 5px 10px',
        fontFamily: 'Camphor,sans-serif',
    },
    FormControl: {
        border: '1px solid #ced4da',
        borderRadius: 4,
    },
    selectProfileDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        textTransform: 'none',
    },
    listText: {
        fontSize: '12px',
    },
    sticky: {
        zIndex: 99999,
        borderTop: '1px solid',
        position: 'sticky',
        width: '100%',
        background: '#fff',
        bottom: 0,
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    masterProfileList: {
        maxHeight: '260px', // Earlier maxHeight: '300px'
        overflow: 'auto',
    },
    dropdownSearchBar: {
        backgroundColor: 'white',
        margin: '10px',
    },
    dropdownActionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
}));
const FilterByMasterProfiles = props => {
    const classes = useStyles();
    const {
        selectedMasterProfileIds,
        setSelectedMasterProfileIds,
        handleMasterProfileFilterChange,
        profiles,
        disableFilter,
    } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // Search string
    const [searchString, setSearchString] = useState('');
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setSearchString('');
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const handleCancel = () => {
        setSelectedMasterProfileIds([]);
        handleClose();
    };
    const id = open ? 'simple-popover' : undefined;
    /**
     * Store search string to render searched app list
     * @param {*} event
     */
    const onChange = event => {
        setSearchString(event.target.value);
    };
    const { _list } = useSearchHook({
        searchString: searchString,
        keys: ['name'],
        list: profiles,
    });
    return (
        <Box>
            <MuiThemeProvider theme={theme}>
                <form autoComplete="off">
                    <FormControl className={classes.FormControl} variant="outlined">
                        <InputLabel
                            style={{
                                backgroundColor: 'white',
                                paddingLeft: '10px',
                                justifyContent: 'center !important',
                                display: 'flex !important',
                                paddingRight: '10px',
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontSize: 'inherit',
                                marginLeft: '35px',
                            }}>
                            Master Profiles
                        </InputLabel>
                        <Button
                            className={classes.selectButton}
                            disableRipple={true}
                            disableFocusRipple={true}
                            disabled={disableFilter}
                            id="users-filter-select-master-profile"
                            onClick={handleClick}>
                            <div className={classes.selectProfileDiv}>
                                {selectedMasterProfileIds.length == 0
                                    ? 'Select Profiles'
                                    : `Selected ${selectedMasterProfileIds.length} Profiles`}
                                <ArrowDropDown />
                            </div>
                        </Button>
                    </FormControl>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <Box>
                            <Box className={classes.dropdownSearchBar}>
                                <SearchBox
                                    id="mp-master-profile-app-search-input"
                                    inputProps={{ placeholder: 'Search Master Profiles' }}
                                    onChange={onChange}
                                />
                            </Box>
                            <Box>
                                <List className={classes.masterProfileList}>
                                    {_list &&
                                        _list.map(profile => (
                                            <ListItem key={profile.id}>
                                                <Checkbox
                                                    onClick={handleMasterProfileFilterChange}
                                                    edge="start"
                                                    color="primary"
                                                    value={profile.id}
                                                    checked={
                                                        selectedMasterProfileIds.indexOf(
                                                            profile.id,
                                                        ) > -1
                                                    }
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': `checkbox-list-label-${profile.name}`,
                                                        'data-select-mp-checkbox': `${profile.name}`,
                                                    }}
                                                />
                                                <ListItemText
                                                    className={classes.listText}
                                                    id={`checkbox-list-label-${profile.email}`}
                                                    primary={profile.name}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                            <Box className={classes.dropdownActionButton}>
                                <Button onClick={handleCancel} color="primary">
                                    CANCEL
                                </Button>
                                <Button onClick={handleClose} color="primary">
                                    OK
                                </Button>
                            </Box>
                        </Box>
                    </Popover>
                </form>
            </MuiThemeProvider>
        </Box>
    );
};
export default FilterByMasterProfiles;
