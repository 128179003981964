import React from 'react';
import { Box, createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const theme = createMuiTheme({
    overrides: {
        MuiIconButton: {
            root: {
                padding: '1px',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px',
            },
        },
    },
});

const CustomDatePicker = props => {
    const { label, value, onChange, disabled } = props;

    return (
        <Box marginRight="10px" marginTop="5px">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={theme}>
                    <KeyboardDatePicker
                        autoOk
                        inputVariant="outlined"
                        clearable
                        value={value}
                        onChange={onChange}
                        format="dd/MM/yyyy"
                        error={false}
                        helperText={''}
                        InputAdornmentProps={{ position: 'start' }}
                        label={label}
                        disabled={disabled}
                    />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </Box>
    );
};

export default CustomDatePicker;
