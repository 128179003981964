import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Link,
} from '@material-ui/core/';
import LoadMoreButton from './LoadMoreButton';
import AppVersionListHeader from './AppVersionListHeader';
import Pagination from '@material-ui/lab/Pagination';
import CustomTooltip from './CustomTooltip';
import DownloadCsvButton from './DownloadCsvButton';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '30px',
    },
    noActivityFound: {
        marginTop: '5%',
        textAlign: 'center',
    },

    tableContainer: {
        maxHeight: 500,
    },

    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },

    pannedDetails: {
        borderTop: '1px solid #ddd',
    },

    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const AppVersionList = props => {
    const classes = useStyles();
    const {
        apps,
        page,
        setPage,
        totalRecords,
        totalPages,
        noAppMessage,
        isLoading,
        selectedApps,
        selectedMasterProfiles,
        selectedAppVersions,
        query,
        tags,
        excludeTags,
        selectedDeviceOSVersionOptions,
    } = props;
    const [expandedAppId, setExpandedAppId] = React.useState(false);
    const [expandedAppVersion, setExpandedAppVersion] = React.useState(false);

    const handleChange = (appId, appVersion) => (event, isExpanded) => {
        setExpandedAppId(isExpanded ? appId : false);
        setExpandedAppVersion(isExpanded ? appVersion : false);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    // Params for download CSV
    const formatData = app => {
        let selectedAppPackages =
            selectedApps &&
            selectedApps.map(function (app) {
                return app.package;
            });
        const selectedPackage = selectedAppPackages.find(
            selected_package => app.package === selected_package,
        );

        let selectedMasterProfileIds =
            selectedMasterProfiles &&
            selectedMasterProfiles.map(function (masterProfile) {
                return masterProfile.value;
            });

        let selectedAppVersion =
            selectedAppVersions &&
            selectedAppVersions.map(function (appVersion) {
                return appVersion.value;
            });

        const params = {
            params: {
                app_id: app.id,
                version: app.version,
                package: app.package,
                selected_app_packages: selectedPackage,
                master_profile_ids: selectedMasterProfileIds,
                query: query,
                versions: selectedAppVersion,
                tags: tags,
                exclude_tags: excludeTags,
                device_os_versions: selectedDeviceOSVersionOptions,
            },
        };
        return params;
    };

    return (
        <>
            {apps.length > 0 ? (
                <Box className={classes.paper}>
                    <AppVersionListHeader />
                    <Box>
                        {apps.map((app, index) => (
                            <Accordion
                                key={index}
                                classes={{ root: classes.expansionPannelRoot }}
                                expanded={
                                    expandedAppId === apps.id && expandedAppVersion === app.version
                                }
                                onChange={handleChange(apps.id, app.version)}>
                                <AccordionSummary
                                    expandIcon={<CustomTooltip variable={expandedAppId} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header">
                                    <Box display="flex" alignItems="cneter" width="100%">
                                        <Box flex="3">
                                            <Box>{app.name}</Box>
                                            <Box>{app.package}</Box>
                                        </Box>
                                        <Box flex="2">{app.version}</Box>
                                        <Box flex="1" textAlign="center">
                                            {app.user_count}
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                {app.user_count > 0 && (
                                    <AccordionDetails className={classes.pannedDetails}>
                                        <strong>Users : &nbsp; </strong>
                                        <DownloadCsvButton
                                            csvPath="/api/v3/app_version_report/download_app_version_csv.csv"
                                            parameters={formatData(app)}
                                            fileName="app_version"
                                            text="true"
                                        />
                                    </AccordionDetails>
                                )}
                            </Accordion>
                        ))}
                    </Box>
                    <Box className={classes.paginationSection}>
                        <Pagination
                            color="primary"
                            count={totalPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handlePageChange}
                        />
                    </Box>
                    {/* <LoadMoreButton
                totalRecords={totalRecords}
                presentRecords={apps.length}
                setRecordsPerPage={setRecordsPerPage}
                perPage={perPage}
                increment={5}
                errorMessage="No More Activities to Load!"
            /> */}
                </Box>
            ) : (
                <Typography className={classes.noActivityFound}>
                    <strong> {noAppMessage} </strong>
                </Typography>
            )}
        </>
    );
};

export default AppVersionList;
