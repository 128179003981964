import { Box, Menu, Tooltip, IconButton, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import TuneIcon from '@material-ui/icons/Tune';
import UserInventoryReportSerialNumberCSVDialog from './UserInventoryReportSerialNumberCSVDialog';
import UserInventoryReportUserIdCSVDialog from './UserInventoryReportUserIdCSVDialog';

const UserInventoryReportUploadCSVButton = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [isSerialNumberCSVDialogOpen, setIsSerialNumberCSVDialogOpen] = useState(false);
    const [isUserIdCSVDialogOpen, setIsUserIdCSVOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [csv, setCsv] = useState([]);

    const handleMenuButtonClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuButtonClose = () => {
        setAnchorEl(null);
    };

    const openSerialNumberDownloadCSVDialog = () => {
        setIsSerialNumberCSVDialogOpen(true);
    };

    const closeSerialNumberDownloadCSVDialog = () => {
        setIsSerialNumberCSVDialogOpen(false);
        setCsv('');
        setPassword('');
    };

    const openUserIdCSVDialog = () => {
        setIsUserIdCSVOpen(true);
    };

    const closeUserIdCSVDialog = () => {
        setIsUserIdCSVOpen(false);
        setCsv('');
        setPassword('');
    };

    return (
        <>
            <Box width="max-content" mr="10px">
                <Box>
                    <Tooltip title="Inventory Utilities">
                        <IconButton onClick={handleMenuButtonClick}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openMenu}
                    onClose={handleMenuButtonClose}>
                    <MenuItem
                        id="download-serial-number-csv-button"
                        onClick={openSerialNumberDownloadCSVDialog}>
                        Download Serial Number CSV
                    </MenuItem>
                    <MenuItem id="download-user-id-csv-button" onClick={openUserIdCSVDialog}>
                        Download User ID CSV
                    </MenuItem>
                </Menu>
            </Box>
            <UserInventoryReportSerialNumberCSVDialog
                isSerialNumberCSVDialogOpen={isSerialNumberCSVDialogOpen}
                closeSerialNumberDownloadCSVDialog={closeSerialNumberDownloadCSVDialog}
                csv={csv}
                setCsv={setCsv}
                password={password}
                setPassword={setPassword}
            />
            <UserInventoryReportUserIdCSVDialog
                isUserIdCSVDialogOpen={isUserIdCSVDialogOpen}
                closeUserIdCSVDialog={closeUserIdCSVDialog}
                csv={csv}
                setCsv={setCsv}
                password={password}
                setPassword={setPassword}
            />
        </>
    );
};

export default UserInventoryReportUploadCSVButton;
