import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core/';
import LoadMoreButton from './LoadMoreButton';
import AccountActivityListHeader from './AccountActivityListHeader';
import ReactJson from 'react-json-view';
import http from 'core/http';
import fileDownload from 'js-file-download';
import CustomTooltip from './CustomTooltip';
import { Pagination } from '@material-ui/lab';
import { UserContext } from 'components/providers/UserProvider';
import { SHOW_TOAST } from 'components/providers/actions';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    paper: {
        marginTop: '30px',
    },
    noActivityFound: {
        marginTop: '5%',
        textAlign: 'center',
    },

    tableContainer: {
        maxHeight: 500,
    },

    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },

    pannedDetails: {
        borderTop: '1px solid #ddd',
    },

    browserSection: {
        display: 'flex',
        width: '100%',
    },

    browser: {
        flex: 1.4,
    },

    osPlaform: {
        flex: 2.9,
    },

    jsonData: {
        marginTop: 10,
    },

    showMoreLink: {
        cursor: 'pointer',
        paddingBottom: '1px',
        borderBottom: '1px solid',
        fontWeight: 500,
        display: 'inline-block',
        marginTop: 10,
        color: '#3F51B5',
    },

    activityInformation: {
        width: '100%',
    },

    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const AccountActivityList = props => {
    const classes = useStyles();
    const { accountActivities, totalPages, setPage, page } = props;
    const [expanded, setExpanded] = useState(false);
    const [showJson, setShowJson] = useState(false);
    const [{ user }, userDispatch] = useContext(UserContext);

    function showSuccessToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    }

    const handleChange = panel => (event, isExpanded) => {
        setShowJson(false);
        setExpanded(isExpanded ? panel : false);
    };

    const handleShowJson = panel => {
        if (panel == showJson) {
            setShowJson(false);
        } else {
            setShowJson(panel);
        }
    };

    const handleSerialNoCSVdownload = activityId => {
        handleCSVdownload('serial_nos', activityId);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleUserCSVdownload = activityId => {
        handleCSVdownload('user_ids', activityId);
    };

    const handleCSVdownload = (csvFor, activityId) => {
        let url = `/cloud/audit/activities/${activityId}/user_ids_csv.csv`;
        if (csvFor == 'serial_nos') {
            url = `/cloud/audit/activities/${activityId}/serial_nos_csv.csv`;
        }
        // Dowload CSV File
        http.get(url, {})
            .then(response => {
                showSuccessToast(
                    'Action for downloading the CSV is successful, for status please visit reports > bulk action status.',
                );
                fileDownload(
                    response.data,
                    response.headers['content-disposition']
                        .split('filename=')[1]
                        .replace(/['"]/g, ''),
                );
            })
            .catch(error => {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {});
    };

    return (
        <>
            {accountActivities.length > 0 ? (
                <Box className={classes.paper}>
                    <AccountActivityListHeader />
                    <Box>
                        {accountActivities.map((accountActivity, index) => (
                            <Accordion
                                key={index}
                                classes={{ root: classes.expansionPannelRoot }}
                                expanded={expanded === accountActivity.id}
                                onChange={handleChange(accountActivity.id)}>
                                <AccordionSummary
                                    expandIcon={<CustomTooltip variable={expanded} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header">
                                    <Box display="flex" alignItems="cneter" width="100%">
                                        <Box flex="3">{accountActivity.user}</Box>
                                        <Box flex="3">{accountActivity.activity}</Box>
                                        <Box flex="3" textAlign="center">
                                            {accountActivity.date}
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className={classes.pannedDetails}>
                                    <Box className={classes.activityInformation}>
                                        <Box>
                                            {accountActivity.browser && (
                                                <Box className={classes.browserSection}>
                                                    <Box className={classes.browser}>
                                                        <strong>Browser: </strong>
                                                        {accountActivity.browser}
                                                    </Box>
                                                    <Box className={classes.osPlaform}>
                                                        <strong>OS Platform: </strong>
                                                        {accountActivity.os_platform}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            dangerouslySetInnerHTML={{
                                                __html: accountActivity.content,
                                            }}
                                        />
                                        <Box>
                                            <a
                                                color="primary"
                                                className={classes.showMoreLink}
                                                onClick={() => handleShowJson(accountActivity.id)}>
                                                {showJson == accountActivity.id
                                                    ? 'Hide more info'
                                                    : 'Show more info'}
                                            </a>
                                        </Box>
                                        {showJson == accountActivity.id && (
                                            <Box className={classes.jsonData}>
                                                <ReactJson
                                                    src={accountActivity.json_data}
                                                    enableClipboard={false}
                                                    name={false}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    collapsed={1}
                                                />
                                                {accountActivity.device_user_count >= 5 && (
                                                    <Box>
                                                        <a
                                                            color="primary"
                                                            className={classes.showMoreLink}
                                                            onClick={() =>
                                                                handleUserCSVdownload(
                                                                    accountActivity.id,
                                                                )
                                                            }>
                                                            Download Users CSV
                                                        </a>
                                                    </Box>
                                                )}
                                                {accountActivity.serial_nos_count >= 5 && (
                                                    <Box>
                                                        <a
                                                            color="primary"
                                                            className={classes.showMoreLink}
                                                            onClick={() =>
                                                                handleSerialNoCSVdownload(
                                                                    accountActivity.id,
                                                                )
                                                            }>
                                                            Download Serial nos. CSV
                                                        </a>
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>

                    {/* <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>USER</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>ACTIVITY</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>DATE</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accountActivities
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(accountActivity => (
                                        <TableRow key={accountActivity.id}>
                                            <TableCell component="th" scope="accountActivity">
                                                {accountActivity.user}
                                            </TableCell>
                                            <TableCell align="right">
                                                {accountActivity.activity}
                                            </TableCell>
                                            <TableCell align="right">
                                                {accountActivity.date}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}

                    {accountActivities.length > 0 && (
                        <Box className={classes.paginationSection}>
                            <Pagination
                                color="primary"
                                count={totalPages}
                                page={page}
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                onChange={handleChangePage}
                            />
                        </Box>
                    )}
                </Box>
            ) : (
                <Typography className={classes.noActivityFound}>
                    <strong> No Activity Found!! </strong>
                </Typography>
            )}
        </>
    );
};

export default AccountActivityList;
