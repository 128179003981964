import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
    TableCell,
    Button,
    Box,
    List,
    ListItem,
    ListItemText,
    Link,
} from '@material-ui/core';

import { useState, useContext } from 'react';
import MuiDialog from 'components/dialog/MuiDialog';
import { UserContext } from 'components/providers/UserProvider';
import UserApi from 'components/providers/UserApi';
import { SHOW_TOAST } from 'components/providers/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import http from 'core/http';
import PasswordDialog from 'components/dialog/PasswordDialog';
import CopyComponent from 'components/users/CopyComponent';

const useStyles = makeStyles(theme => ({
    p: {
        color: '#5E5E5E',
    },

    row: {
        padding: '0px',
    },
    cell: {
        padding: '0px 10px',
    },
    cell: {
        padding: '10px 10px',
    },
    checkboxCell: {
        paddingLeft: '20px',
    },

    lineHeight: {
        lineHeight: '12px',
        fontWeight: 600,
    },

    iconColor: {
        color: '#777',
    },

    tableBorder: {
        borderCollapse: 'inherit',
        border: '1px solid rgb(239, 239, 239)',
        borderRadius: 4,
        borderBottom: 'transparent',
    },

    tableHead: {
        backgroundColor: 'rgba(189, 185, 255, 0.24)',
    },

    tableContainer: {
        height: 300,
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    transferBtn: {
        color: '#fff',
        background: 'green',
        '&:hover': {
            color: '#fff',
            background: '#3cb371',
        },
    },
}));

const DevicesList = props => {
    const classes = useStyles();
    const {
        listHeaders,
        devices,
        allowedUpdate,
        userId,
        selectedUser,
        fetchUsersMultipleDeviceInfo,
    } = props;

    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [transferAlertOpen, setTransferAlertOpen] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState({});
    const [{ user }, userDispatch] = useContext(UserContext);

    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordDialogText, setPasswordDialogText] = useState('');
    const [passwordAction, setPasswordAction] = useState(null);

    const serialNoRef = useRef(new Array());

    const settingDevicesData = device => {
        let devicesData = [];
        if (device) {
            devicesData = [
                { key: 'serial_no', value: device.serial_no },
                { key: 'user_type', value: device.user_type },
                { key: 'enrolled_on', value: device.enrolled_on },
                { key: 'expiry', value: device.subscription_end_date },
                { key: 'impact_on_pool', value: device.impact_on_pool },
                { key: 'user_count', value: device.user_count },
            ];
        }
        return devicesData;
    };

    const handleAlertClose = cancelled => {
        setDeleteAlertOpen(false);
        if (!cancelled) {
            if (selectedDevice.primary_user || selectedDevice.user_count == 1) {
                setPasswordDialogText(
                    'This action would factory reset the device. Please enter your password to confirm.',
                );
                setOpenPasswordDialog(true);
                setPasswordAction('delete');
            } else {
                deleteDevice();
            }
        }
    };

    const deleteDevice = () => {
        setLoading(true);
        let url = `/api/v3/users/${userId}/delete_device.json`;
        http.delete(url, { params: { password: password, device_id: selectedDevice.id } })
            .then(response => {
                fetchUsersMultipleDeviceInfo();
                setOpenPasswordDialog(false);
                showSuccessToast('Device Deleted Successfully!');
            })
            .catch(error => {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Unable To Delete Device!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    function showSuccessToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    }

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    function oldDevice() {
        return new Date(devices[0].enrolled_on) < new Date(devices[1].enrolled_on)
            ? devices[0]
            : devices[1];
    }
    function newDevice() {
        return new Date(devices[0].enrolled_on) > new Date(devices[1].enrolled_on)
            ? devices[0]
            : devices[1];
    }

    function openDeleteAlert(device) {
        setSelectedDevice(device);
        setDeleteAlertOpen(true);
    }

    function openTransferAlert(device) {
        setSelectedDevice(device);
        setTransferAlertOpen(true);
    }

    const handleTransferAlertClose = () => {
        setTransferAlertOpen(false);
    };

    const handleTransferPasswordPrompt = () => {
        setPasswordDialogText(
            `This action will transfer subscription from ${oldDevice().serial_no} to ${
                newDevice().serial_no
            } device. Please enter your password to confirm.`,
        );
        setPasswordAction('transfer');
        setOpenPasswordDialog(true);
    };

    const handleTransfer = () => {
        setLoading(true);
        handleTransferAlertClose();
        let url = `/api/v3/users/${userId}/transfer_months_from_old_device.json`;
        http.post(url, { password: password })
            .then(response => {
                showSuccessToast('Device subscription transfered Successfully!');
                fetchUsersMultipleDeviceInfo();
            })
            .catch(error => {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Unable To Transfer Device!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const List = ({ device }) => {
        const serialNoRef = useRef(null);
        return (
            <>
                {settingDevicesData(device).map((data, index) => (
                    <TableCell align="left" key={data.key} className={classes.cell}>
                        {data.key == 'serial_no' ? (
                            <>
                                <Link
                                    underline="always"
                                    target="_blank"
                                    // rel="noopener noreferrer"
                                    href={process.env.PUBLIC_URL + `/users?q=${data.value}`}>
                                    {data.value}
                                </Link>
                                <CopyComponent inputRef={serialNoRef} inputValue={data.value} />
                            </>
                        ) : (
                            <Typography
                                key={index}
                                component="p"
                                color="inherit"
                                className={classes.p}>
                                {data.value || 'NA'}
                            </Typography>
                        )}
                    </TableCell>
                ))}
                {allowedUpdate && (
                    <TableCell align="left" className={classes.cell}>
                        {oldDevice().serial_no == device.serial_no ? (
                            <Button
                                variant="contained"
                                className={classes.transferBtn}
                                size="small"
                                onClick={() => openTransferAlert(device)}
                                startIcon={<SyncAltIcon />}>
                                Transfer
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                onClick={() => openDeleteAlert(device)}>
                                Delete
                            </Button>
                        )}
                    </TableCell>
                )}
            </>
        );
    };

    return (
        <div>
            <TableContainer className={classes.tableContainer}>
                {devices.length > 0 ? (
                    <Table classes={{ root: classes.tableBorder }} stickyHeader>
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.row}>
                                {listHeaders.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        className={clsx(classes.p, classes.lineHeight)}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.map((device, index) => (
                                <TableRow className={classes.row} key={index}>
                                    <List device={device} />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    'No data found'
                )}
            </TableContainer>

            <DeleteDialog
                deleteAlertOpen={deleteAlertOpen}
                handleAlertClose={handleAlertClose}
                selectedDevice={selectedDevice}
            />
            <TransferDialog
                transferAlertOpen={transferAlertOpen}
                handleAlertClose={handleTransferAlertClose}
                handleTransfer={handleTransfer}
                selectedDevice={selectedDevice}
                oldDevice={oldDevice}
                newDevice={newDevice}
                selectedUser={selectedUser}
                handleTransferPasswordPrompt={handleTransferPasswordPrompt}
            />
            <PasswordDialog
                password={password}
                setPassword={setPassword}
                openPasswordDialog={openPasswordDialog}
                setOpenPasswordDialog={setOpenPasswordDialog}
                handleAccept={passwordAction == 'delete' ? deleteDevice : handleTransfer}
                acceptBtnText={passwordAction == 'delete' ? 'Delete' : 'Transfer'}
                loading={loading}
                dialogText={passwordDialogText}
            />
        </div>
    );
};

const DeleteDialog = props => {
    const classes = useStyles();

    const { deleteAlertOpen, handleAlertClose, selectedDevice } = props;
    return (
        <MuiDialog
            maxWidth={'sm'}
            fullWidth
            open={deleteAlertOpen}
            title="Confirm Action?"
            onAcceptText="CONFIRM"
            onReject={() => handleAlertClose(true)}
            onAccept={() => handleAlertClose(false)}>
            <Box display="flex" classes={{ root: classes.formControlRoot }}>
                <Typography component="p" color="inherit" className={classes.p}>
                    Are you sure you want to delete this device <b> ({selectedDevice.serial_no})</b>{' '}
                    for this user? The following actions will be taken,
                </Typography>
            </Box>
            <Box>
                <List className={classes.list}>
                    <ListItem>
                        {selectedDevice.primary_user || selectedDevice.user_count == 1 ? (
                            <ListItemText
                                primary="Factory Reset"
                                secondary="Device will be Factory Reset"
                            />
                        ) : (
                            <ListItemText primary="Factory Reset" secondary="No Impact" />
                        )}
                    </ListItem>
                    <ListItem>
                        {selectedDevice.primary_user ? (
                            <ListItemText
                                primary="Impact on Other User"
                                secondary={
                                    selectedDevice.user_count > 1
                                        ? `${
                                              selectedDevice.user_count - 1
                                          } other users will be removed from this device`
                                        : 'No Impact'
                                }
                            />
                        ) : (
                            <ListItemText primary="Impact on Other User" secondary="No Impact" />
                        )}
                    </ListItem>
                </List>
            </Box>
        </MuiDialog>
    );
};

const TransferDialog = props => {
    const classes = useStyles();
    const {
        transferAlertOpen,
        handleAlertClose,
        handleTransferPasswordPrompt,
        selectedDevice,
        oldDevice,
        newDevice,
        selectedUser,
    } = props;

    return (
        <MuiDialog
            maxWidth={'sm'}
            fullWidth
            open={transferAlertOpen}
            title="Confirm Action?"
            onAcceptText="CONFIRM"
            onReject={() => handleAlertClose(true)}
            onAccept={handleTransferPasswordPrompt}>
            <Box display="flex" classes={{ root: classes.formControlRoot }}>
                <Typography component="p" color="inherit" className={classes.p}>
                    This action would transfer the license from <b>{oldDevice().serial_no}</b> to{' '}
                    <b>{newDevice().serial_no}</b>.
                </Typography>
            </Box>
            <Box>
                <List className={classes.list}>
                    <ListItem>
                        <ListItemText
                            primary="Factory Reset"
                            secondary={`No Impact (Consider factory resetting/ deleting ${
                                oldDevice().serial_no
                            } device later.)`}
                        />
                    </ListItem>
                    <ListItem>
                        {oldDevice().is_in_trial ? (
                            <ListItemText primary="Transfer" secondary="No Impact" />
                        ) : (
                            <ListItemText
                                primary="Transfer"
                                secondary={`${selectedUser.months_left} months from
                                ${oldDevice().serial_no} to ${newDevice().serial_no}.`}
                            />
                        )}
                    </ListItem>
                </List>
            </Box>
        </MuiDialog>
    );
};

DevicesList.defaultProps = {
    devices: [],
    allowedUpdate: false,
};

DevicesList.propTypes = {
    allowedUpdate: PropTypes.bool,
    userId: PropTypes.string.isRequired,
    fetchUsersMultipleDeviceInfo: PropTypes.func.isRequired,
    listHeaders: PropTypes.array.isRequired,
    selectedUser: PropTypes.object.isRequired,
};
export default DevicesList;
