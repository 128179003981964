import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '15px',
    },

    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },

    pannedDetails: {
        borderTop: '1px solid #ddd',
    },

    browserSection: {
        display: 'flex',
        width: '100%',
    },

    browser: {
        flex: 1.4,
    },

    osPlaform: {
        flex: 2.9,
    },

    activityInformation: {
        width: '100%',
    },

    flexRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px 0 24px',
        background: '#9baaba',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },

    headerItem: {
        fontWeight: 500,
        alignItems: 'center',
        padding: '18px 0',
        color: '#fff',
    },

    noData: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bolder',
    },

    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const ScreenTimeList = props => {
    const classes = useStyles();
    const { appCategory, page, setPage, totalPages } = props;
    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <>
            <Box className={classes.paper}>
                {appCategory.length > 0 ? (
                    <>
                        <Paper>
                            <Paper className={classes.flexRoot}>
                                <Box className={classes.headerItem}>User ID</Box>
                                <Box className={classes.headerItem} ml="410px">
                                    Reported App Count
                                </Box>
                                <Box className={classes.headerItem}></Box>
                            </Paper>
                            {appCategory.map((category, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expanded === category.user_id}
                                    onChange={handleChange(category.user_id)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon fontSize="large" />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Box display="flex" alignItems="center" width="100%">
                                            <Box flex="5">{category.user_id}</Box>
                                            <Box flex="2">{category.reported_app_count}</Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box width="100%">
                                            <TableContainer>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <strong>App Name</strong>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <strong>Current Category</strong>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <strong>Reported Category</strong>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <strong>Last Updated On</strong>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {category.data.map((data, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell
                                                                    component="th"
                                                                    scope="user">
                                                                    {data.app_name}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {data.current_category}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {data.reported_category}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {data.updated_at}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Paper>
                        <Box className={classes.paginationSection}>
                            <Pagination
                                color="primary"
                                count={totalPages}
                                page={page}
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                onChange={handleChangePage}
                            />
                        </Box>
                    </>
                ) : (
                    <Box className={classes.noData}> No Reported App Category Data Found !!</Box>
                )}
            </Box>
        </>
    );
};

export default ScreenTimeList;
