import { makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
    toolTipWidth: {
        width: 70,
        height: 25,
        fontSize: 12,
        textAlign: 'center',
    },
});

const CustomTooltip = props => {
    const classes = useStyles();
    const { variable } = props;

    return (
        <>
            <Tooltip
                arrow
                placement="top"
                classes={{ tooltip: classes.toolTipWidth }}
                title={variable ? 'Collapse' : 'Expand'}>
                <ExpandMoreIcon fontSize="large" />
            </Tooltip>
        </>
    );
};

export default CustomTooltip;
