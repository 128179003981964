import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    createMuiTheme,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    MuiThemeProvider,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import CustomDatePicker from './CustomDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { FilterList, Title } from '@material-ui/icons';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(9px, 9px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '8px',
            },
        },
        MuiSelect: {
            select: {
                minWidth: 120,
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: 7,
        marginLeft: 4,
        display: 'flex',
        flexDirection: 'row',
    },

    clearButton: {
        visibility: 'hidden',
    },
}));

const UserInventoryAdvanceSearch = props => {
    const classes = useStyles();
    const {
        searchData,
        advSearchAPI,
        searchBy,
        setSearchBy,
        clearClicked,
        setClearClicked,
        setPage,
    } = props;

    const [isTitleSelected, setIsTitleSelected] = useState(false);
    const [isOperatorSelected, setIsOperatorSelected] = useState(false);
    const [isOptionsSelected, setIsOptionsSelected] = useState(false);
    const [addQueryClicked, setAddQueryClicked] = useState(false);
    const getOperators = title => {
        var newItem = searchData.find(x => x.title === title);
        return newItem.operators;
    };

    const getOptions = title => {
        var newItem = searchData.find(x => x.title === title);
        return newItem.options;
    };

    const getType = title => {
        var newItem = searchData.find(x => x.title === title);
        return newItem.type;
    };

    const getPlaceholder = title => {
        var newItem = searchData.find(x => x.title === title);
        return newItem.placeholder;
    };

    const removedTitleFromSearchData = obj => {
        let searchDataClone = cloneDeep(searchData);

        let a = searchBy.map(t => t.title);

        let b = searchDataClone.map(i => i.title);

        b = b.filter(val => (obj && val == obj.title) || !a.includes(val));

        return b;
    };

    const handleTitleClick = (event, obj, index) => {
        let searchByClone = cloneDeep(searchBy);
        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.title = event.target.value;
                obj.operators = getOperators(event.target.value);
                obj.operator = '';
                obj.options = getOptions(event.target.value);
                obj.value = [];
                obj.type = getType(event.target.value);
                obj.placeholder = getPlaceholder(event.target.value);
            }
            if (event.target.value) setIsTitleSelected(true);
        });
        setSearchBy(searchByClone);
    };

    const handleOperatorClick = (event, obj, index) => {
        let searchByClone = cloneDeep(searchBy);
        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.operator = event.target.value;
                obj.dateRange = { from: null, to: null };
                obj.value = [];
            }
            if (event.target.value) setIsOperatorSelected(true);
        });
        setSearchBy(searchByClone);
    };

    const getSelectedOption = (index, value, obj) => {
        var newItem = searchBy.find(x => x.index == index);

        if (newItem.type == 'date' && newItem.operator == 'between') {
            return [newItem.dateRange.from, newItem.dateRange.to];
        } else if (newItem.type == 'date' && newItem.operator != 'between')
            return newItem.dateRange.from;
        else {
            return value;
        }
    };

    // Use it for Error msgs on the Textfield if needed
    // const [invalidInput, setInvalidInput]= useState(false)
    // const errorMessages = (obj) => {

    //     let msg = ""
    //     if (obj.title && obj.operator && obj.value) {
    //         switch (obj.title) {
    //         case "User ID":
    //             if (obj.value.length > 4) {
    //                 msg = "wow"
    //                 // setInvalidInput(true)
    //             }else {
    //                 msg = ""
    //                 // setInvalidInput(false)
    //             }
    //             break
    //         default:
    //             msg = ""
    //         }
    //     }
    //     return msg

    // }

    const handleOptionsClick = (event, obj, index) => {
        setClearClicked(false);
        let searchByClone = cloneDeep(searchBy);
        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.value = getSelectedOption(index, event.target.value, obj);
            }
        });
        setSearchBy(searchByClone);
        if (event.target.value) setIsOptionsSelected(true);
    };

    const handleMultiSelectChange = (newInputValue, index) => {
        let searchByClone = cloneDeep(searchBy);

        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.value = newInputValue;
            }
        });

        setClearClicked(false);
        setSearchBy(searchByClone);

        if (newInputValue.length > 0) setIsOptionsSelected(true);
    };

    const getOptionsLabel = (list, option) => {
        let label = list.find(i => i.value == option);
        return label.title;
    };

    const handleFromDateChange = (date, obj, index) => {
        setClearClicked(false);
        var fromDate = new Date(date);

        var dateString =
            fromDate.getFullYear() +
            '-' +
            ('0' + (fromDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + fromDate.getDate()).slice(-2);

        let searchByClone = cloneDeep(searchBy);

        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.dateRange.from = dateString;
                if (obj.type == 'date' && obj.operator == 'between') {
                    obj.value = [obj.dateRange.from, obj.dateRange.to];
                } else {
                    obj.value = obj.dateRange.from ? obj.dateRange.from : null;
                }
            }
        });
        setSearchBy(searchByClone);
        if (dateString) setIsOptionsSelected(true);
    };

    const handleToDateChange = (date, obj, index) => {
        setClearClicked(false);
        var toDate = new Date(date);

        var dateString =
            toDate.getFullYear() +
            '-' +
            ('0' + (toDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + toDate.getDate()).slice(-2);

        let searchByClone = cloneDeep(searchBy);
        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.dateRange.to = dateString;
                obj.value = [obj.dateRange.from, obj.dateRange.to];
            }
        });
        setSearchBy(searchByClone);
        if (dateString) setIsOptionsSelected(true);
    };

    const handleFilterClick = () => {
        setPage(1);
        advSearchAPI();
        setClearClicked(false);
    };

    const CloseButton = ({ index, obj }) => {
        const handleClose = event => {
            let searchByClone = cloneDeep(searchBy);

            searchByClone.splice(index, 1);

            searchByClone.forEach((obj, index) => {
                obj.index = index;
            });

            setSearchBy(searchByClone);
        };

        return (
            <IconButton
                color="inherit"
                id="ent-apps-view-close-dialog-button"
                onClick={event => handleClose(event)}
                aria-label="close">
                <CloseIcon />
            </IconButton>
        );
    };

    // Button to Add Query
    function addQuery(event) {
        let searchByClone = cloneDeep(searchBy);
        searchByClone.push({
            index: searchByClone.length,
            title: '',
            operators: [],
            operator: '',
            options: [],
            // value: '',
            value: [],
            dateRange: { from: null, to: null },
            type: '',
            placeholder: '',
            queryType: 'AND',
        });
        setSearchBy(searchByClone);
        setAddQueryClicked(true);
        // setInvalidInput(false)
    }

    const disableAddQueryAndSearch = () => {
        let flag = false;
        searchBy.map(i => {
            // if (!i.value) {
            //     flag = true;
            //     return;
            // }
            if (i.value.length == 0) {
                flag = true;
                return;
            } else if (i.operator == 'between' && (!i.dateRange.from || !i.dateRange.to)) {
                flag = true;
                return;
            }
        });
        return flag;
    };

    const handleClearClick = () => {
        setClearClicked(true);
        setPage(1);
        setSearchBy([
            {
                index: 0,
                title: '',
                operators: [],
                operator: '',
                options: [],
                // value: '',
                value: [],
                dateRange: { from: null, to: null },
                type: '',
                placeholder: '',
                queryType: 'AND',
            },
        ]);
    };

    const handleQueryType = (event, obj, index) => {
        let searchByClone = cloneDeep(searchBy);
        searchByClone.forEach(obj => {
            if (obj.index == index) {
                obj.queryType = event.target.value;
            }
        });
        setSearchBy(searchByClone);
    };

    // console.log("searchBy", searchBy.map((s) => s));

    return (
        <>
            <Container
                style={{
                    maxHeight: '170px',
                    maxWidth: '85%',
                    overflow: 'auto',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    margin: '0px',
                }}>
                <Box display="flex" style={{ margin: '5px' }}>
                    <FilterList style={{ marginRight: 5 }} />
                    <Typography> Filter Users</Typography>
                </Box>
                {searchBy.map((obj, index) => (
                    <Box display="flex" flexDirection="row" alignItems="center" key={index}>
                        <MuiThemeProvider theme={theme}>
                            {/* QUERY TYPE SELECTOR */}
                            {addQueryClicked && obj.index != 0 && (
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    style={{ marginRight: '20px' }}>
                                    <InputLabel id="demo-simple-select-label">
                                        Query Type
                                    </InputLabel>
                                    <Select
                                        id="outlined-search"
                                        type="search"
                                        value={obj.queryType}
                                        label="Query Type"
                                        onChange={event => handleQueryType(event, obj, index)}>
                                        <MenuItem key={'AND'} value={'AND'}>
                                            AND
                                        </MenuItem>
                                        <MenuItem key={'OR'} value={'OR'}>
                                            OR
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}

                            {/* TITLE */}
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                style={{ marginRight: '20px' }}>
                                <InputLabel id="demo-simple-select-label">Select Title</InputLabel>
                                <Select
                                    id="outlined-search"
                                    type="search"
                                    value={obj.title}
                                    label="Select Title"
                                    onChange={event => handleTitleClick(event, obj, index)}>
                                    {/* {searchData.map((i, index) => ( */}
                                    {removedTitleFromSearchData(obj).map((i, index) => (
                                        <MenuItem key={index} value={i}>
                                            {i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* OPERATORS */}
                            {isTitleSelected && (
                                <Box>
                                    {obj.operators && (
                                        <>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                style={{ marginRight: '20px' }}>
                                                <InputLabel id="demo-simple-select-label">
                                                    Select Operator
                                                </InputLabel>
                                                <Select
                                                    disabled={!obj.title}
                                                    id="outlined-search"
                                                    type="search"
                                                    value={obj.operator}
                                                    label="Select Operator"
                                                    onChange={event =>
                                                        handleOperatorClick(event, obj, index)
                                                    }>
                                                    {obj.operators &&
                                                        obj.operators.map((o, index) => (
                                                            <MenuItem value={o} key={index}>
                                                                {o}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </>
                                    )}
                                </Box>
                            )}

                            {/* OPTIONS */}
                            {isTitleSelected && isOperatorSelected && (
                                <Box>
                                    {obj.options ? (
                                        <>
                                            {/* Uncomment the below line & delete the next line after that for mumdm-1045 changes which were accidentally pushed to Prod before requirement */}
                                            {/* {obj.title != 'Tag' && obj.title != 'Region' ? ( */}
                                            {obj.title != 'Tag' &&
                                            obj.title != 'Region' &&
                                            obj.title != 'Device OS Version' &&
                                            obj.title != 'Master Profile' ? (
                                                <FormControl
                                                    variant="outlined"
                                                    className={classes.formControl}>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Select Option
                                                    </InputLabel>
                                                    <Select
                                                        disabled={!obj.title || !obj.operator}
                                                        id="outlined-search"
                                                        type="search"
                                                        value={obj.value}
                                                        label="Select Option"
                                                        onChange={event =>
                                                            handleOptionsClick(event, obj, index)
                                                        }>
                                                        {obj.options.map((i, index) => (
                                                            <MenuItem value={i.value} key={index}>
                                                                {i.title}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <Autocomplete
                                                    className={classes.formControl}
                                                    disabled={!obj.title || !obj.operator}
                                                    style={{ minWidth: '150px' }}
                                                    componentsProps={{
                                                        paper: {
                                                            sx: {
                                                                width: 'max-content',
                                                            },
                                                        },
                                                    }}
                                                    multiple
                                                    limitTags={2}
                                                    id="tags-outlined"
                                                    options={obj.options.map(i => i.value)}
                                                    getOptionLabel={option =>
                                                        getOptionsLabel(obj.options, option)
                                                    }
                                                    onChange={(event, newInputValue) =>
                                                        handleMultiSelectChange(
                                                            newInputValue,
                                                            index,
                                                        )
                                                    }
                                                    value={obj.value}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            //Uncomment the below line & delete the next line after that for mumdm-1045 changes which were accidentally pushed to Prod before requirement
                                                            // label={
                                                            //     obj.title == 'Tag'
                                                            //         ? 'Select Tags'
                                                            //         : 'Select Region/s'
                                                            // }
                                                            // label="Select Tags"
                                                            label={
                                                                obj.title == 'Tag'
                                                                    ? 'Select Tags'
                                                                    : obj.title == 'Region'
                                                                    ? 'Select Region/s'
                                                                    : obj.title == 'Master Profile'
                                                                    ? 'Master Profile/s'
                                                                    : 'OS Version'
                                                            }
                                                            // placeholder={
                                                            //     obj.title == 'Tag'
                                                            //         ? 'Tags'
                                                            //         : 'Region/s'
                                                            // }
                                                            // placeholder="Tags"
                                                            placeholder={
                                                                obj.title == 'Tag'
                                                                    ? 'Tags'
                                                                    : obj.title == 'Region'
                                                                    ? 'Region/s'
                                                                    : obj.title == 'Master Profile'
                                                                    ? 'Master Profile/s'
                                                                    : 'OS Version'
                                                            }
                                                        />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                // key={index}
                                                                variant="outlined"
                                                                size="small"
                                                                // label={option}
                                                                label={getOptionsLabel(
                                                                    obj.options,
                                                                    option,
                                                                )}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                />
                                            )}
                                        </>
                                    ) : obj.type == 'date' && obj.operator == 'between' ? (
                                        <Box display={'flex'} maxWidth={'300px'}>
                                            <CustomDatePicker
                                                label="Start Date"
                                                value={obj.dateRange.from}
                                                onChange={date =>
                                                    handleFromDateChange(date, obj, index)
                                                }
                                                disabled={!obj.title || !obj.operator}
                                            />
                                            <CustomDatePicker
                                                label="End Date"
                                                value={obj.dateRange.to}
                                                onChange={date =>
                                                    handleToDateChange(date, obj, index)
                                                }
                                                disabled={!obj.title || !obj.operator}
                                            />
                                        </Box>
                                    ) : obj.type == 'date' && obj.operator != 'between' ? (
                                        <Box display={'flex'} maxWidth={'150px'}>
                                            <CustomDatePicker
                                                label="Select Date"
                                                value={obj.dateRange.from}
                                                onChange={date =>
                                                    handleFromDateChange(date, obj, index)
                                                }
                                                disabled={!obj.title || !obj.operator}
                                            />
                                        </Box>
                                    ) : (
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}>
                                            <TextField
                                                disabled={!obj.title || !obj.operator}
                                                value={obj.value || ''}
                                                id="outlined-search"
                                                type={obj.type == 'text'}
                                                placeholder={obj.placeholder}
                                                onChange={e => handleOptionsClick(e, obj, index)}
                                                // error={errorMessages(obj)}
                                                // helperText={errorMessages(obj)}
                                            />
                                        </FormControl>
                                    )}
                                </Box>
                            )}

                            {/* CROSS ICON */}
                            {searchBy.length > 1 && (
                                <Box>
                                    <CloseButton index={index} obj={obj} />
                                </Box>
                            )}
                        </MuiThemeProvider>
                    </Box>
                ))}
            </Container>
            <Box>
                {isTitleSelected && isOperatorSelected && isOptionsSelected && !clearClicked && (
                    <Box display={'flex'} flexDirection={'column'}>
                        <Button
                            variant="contained"
                            disabled={disableAddQueryAndSearch()}
                            color="gray"
                            size="medium"
                            onClick={addQuery}
                            style={{ marginTop: '14px', marginBottom: '9px' }}>
                            <strong> Add Query </strong>
                        </Button>
                        <Button
                            variant="contained"
                            disabled={disableAddQueryAndSearch()}
                            color="primary"
                            size="medium"
                            onClick={handleFilterClick}
                            style={{ marginTop: '14px', marginBottom: '9px' }}>
                            <strong>{searchBy.length <= 1 ? 'Filter' : 'Advanced Search'}</strong>
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            onClick={handleClearClick}
                            style={{ marginTop: '14px', marginBottom: '9px' }}>
                            <strong> Clear Search </strong>
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default UserInventoryAdvanceSearch;
