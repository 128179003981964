import React, { useContext, useState, useEffect } from 'react';
import 'date-fns';
import {
    Container,
    Box,
    Button,
    Paper,
    Card,
    CardContent,
    Typography,
    CardHeader,
    IconButton,
    Tooltip,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import Heading from 'components/header/Heading';
import http from 'core/http';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import usePermissionsHook from 'Hooks/usePermissionHook';
import { StyledTabs, StyledTab, Tabpanel } from 'components/tabs/StyledTabs';
import ReportTabs from './subscriptionReports/reportTabs';
import LoadingProgress from 'components/progress/LoadingProgress';
import ThreeDotsDark from '../../images/three-dots-dark.svg';
import clsx from 'clsx';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const PERMISSION_SLUG = 'Byjus/Reports';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    heading: {
        fontSize: '15px',
        fontWeight: 600,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
    },
    paperContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        '& > *': {
            width: theme.spacing(25),
            height: theme.spacing(20),
        },
    },
    card: {
        margin: '15px 10px',
        boxShadow:
            '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        height: 180,
        '& .MuiCardHeader-root': {
            fontSize: 13,
            fontWeight: 400,
            padding: '10px',
        },
        '& .MuiCardContent-root': {
            padding: '10px',
        },
    },
    infoIcon: {
        fontSize: 16,
        marginRight: 5,
        marginLeft: 5,
    },
    marginTop: {
        marginTop: '10px',
    },
    data: {
        textAlign: 'center',
        fontSize: '1.875rem',
    },
    validity: {
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '2.43',
    },
    loadingImg: {
        height: 19,
        padding: 5,
    },
    regionList: {
        listStyleType: 'none',
        padding: 0,
        fontSize: 13,
        fontWeight: 550,
        marginTop: 0,
        marginBottom: 0,
        lineHeight: 1.8,
    },
    toolTipWidth: {
        maxWidth: 350,
        fontSize: 14,
        textAlign: 'center',
    },
}));

const SubscriptionReport = props => {
    const classes = useStyles();
    const { allowed } = usePermissionsHook(PERMISSION_SLUG);
    const [{ user }, userDispatch] = React.useContext(UserContext);

    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [activeAndExpiredData, setActiveAndExpiredData] = useState(null);
    const [loading, setLoading] = useState(false);

    function fetchSubscriptionDetails() {
        setLoading(true);
        async function fetchData() {
            const response = await http.get('/api/v3/subscription_pool.json');
            setSubscriptionDetails(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function fetchSubscriptionActiveAndExpiredData() {
        setLoading(true);
        async function fetchData() {
            const response = await http.get('/api/v3/subscription_reports/usage.json');
            setActiveAndExpiredData(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const subscriptionPoolValidityExpired = () => {
        if (subscriptionDetails && subscriptionDetails.expires_on) {
            let validityExpiryDate = subscriptionDetails.expires_on;
            if (validityExpiryDate.length == 0) {
                return false;
            } else {
                return Boolean(
                    new Date(validityExpiryDate).setHours(23, 59, 59, 999) <= new Date().getTime(),
                );
            }
        }
        return false;
    };

    useEffect(() => {
        fetchSubscriptionDetails();
        fetchSubscriptionActiveAndExpiredData();
    }, []);

    const currentMonth = () => {
        var months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        var date = new Date();
        return months[date.getMonth()] + ' ' + date.getFullYear();
    };

    const currentDay = () => {
        var day = new Date();
        return day.getDate();
    };

    return (
        <>
            <Container maxWidth="lg" className={classes.root}>
                <Heading
                    title={
                        <>
                            <Box>
                                <Button color="primary" onClick={goBack}>
                                    Reports&nbsp; <ArrowForward fontSize="small" />
                                </Button>
                                <span className={classes.reportName}>Subscription Reports</span>
                            </Box>
                            <Box fontSize="13px" marginLeft="8px" color="gray">
                                View & download reports for subscription pool, usage, info and
                                manage subscription transfers.
                            </Box>
                        </>
                    }
                />
                {!subscriptionPoolValidityExpired() && (
                    <Paper>
                        <Box padding="10px">
                            <Box className={classes.heading}>
                                Initial Subscription Pool Data{' '}
                                <InfoIcon className={classes.infoIcon} />
                            </Box>
                            <Box className={classes.paperContainer}>
                                <CardBox
                                    title="Total Subscription Months"
                                    data={
                                        loading ? (
                                            <img
                                                src={ThreeDotsDark}
                                                alt="loading"
                                                className={classes.loadingImg}
                                            />
                                        ) : (
                                            subscriptionDetails &&
                                            subscriptionDetails.total_subscription_months
                                        )
                                    }
                                    helpText="Total Subscription Months"
                                />
                                <CardBox
                                    title="Validity"
                                    data={
                                        loading ? (
                                            <img
                                                src={ThreeDotsDark}
                                                alt="loading"
                                                className={classes.loadingImg}
                                            />
                                        ) : (
                                            subscriptionDetails &&
                                            `Activated On: ${subscriptionDetails.activated_on} \n Expires On: ${subscriptionDetails.expires_on}`
                                        )
                                    }
                                    helpText="Validity"
                                />
                                <CardBox
                                    title="Available Months"
                                    data={
                                        loading ? (
                                            <img
                                                src={ThreeDotsDark}
                                                alt="loading"
                                                className={classes.loadingImg}
                                            />
                                        ) : (
                                            subscriptionDetails &&
                                            subscriptionDetails.available_months
                                        )
                                    }
                                    helpText="Available Months"
                                />
                                <CardBox
                                    title="Used Months"
                                    data={
                                        loading ? (
                                            <img
                                                src={ThreeDotsDark}
                                                alt="loading"
                                                className={classes.loadingImg}
                                            />
                                        ) : (
                                            <>
                                                {(subscriptionDetails &&
                                                    subscriptionDetails.total_used_months) ||
                                                    0}{' '}
                                                <br />
                                                {subscriptionDetails && (
                                                    <ul className={classes.regionList}>
                                                        {activeAndExpiredData &&
                                                            activeAndExpiredData.used_months.map(
                                                                used_month => (
                                                                    <li>
                                                                        {used_month.region}:{' '}
                                                                        {used_month.count}
                                                                    </li>
                                                                ),
                                                            )}
                                                    </ul>
                                                )}
                                            </>
                                        )
                                    }
                                    helpText="Used Months"
                                />
                                {subscriptionDetails &&
                                    new Date(subscriptionDetails.expires_on) < new Date() && (
                                        <CardBox
                                            title="Lapsed Subscriptions"
                                            data={
                                                loading ? (
                                                    <img
                                                        src={ThreeDotsDark}
                                                        alt="loading"
                                                        className={classes.loadingImg}
                                                    />
                                                ) : (
                                                    subscriptionDetails &&
                                                    subscriptionDetails.lapsed_subscription
                                                )
                                            }
                                            helpText="Lapsed Subscriptions"
                                        />
                                    )}
                            </Box>
                        </Box>
                    </Paper>
                )}
                <Paper className={classes.marginTop}>
                    <Box padding="10px">
                        <Box className={classes.heading}>
                            Subscription Usage Data <InfoIcon className={classes.infoIcon} />
                        </Box>
                        <Box className={classes.paperContainer}>
                            <CardBox
                                title="Total Active Devices"
                                data={
                                    loading ? (
                                        <img
                                            src={ThreeDotsDark}
                                            alt="loading"
                                            className={classes.loadingImg}
                                        />
                                    ) : (
                                        // (subscriptionDetails &&
                                        //     subscriptionDetails.total_active_devices) ||
                                        // 0
                                        (activeAndExpiredData &&
                                            activeAndExpiredData.total_active_devices) ||
                                        0
                                    )
                                }
                                helpText="Count of Unique Serial Number Devices with an Active Subscription"
                            />
                            <CardBox
                                title="Total Expired Devices"
                                data={
                                    loading ? (
                                        <img
                                            src={ThreeDotsDark}
                                            alt="loading"
                                            className={classes.loadingImg}
                                        />
                                    ) : (
                                        // (subscriptionDetails &&
                                        //     subscriptionDetails.total_expired_devices) ||
                                        // 0
                                        (activeAndExpiredData &&
                                            activeAndExpiredData.total_expired_devices) ||
                                        0
                                    )
                                }
                                helpText="Count of Unique Serial Number Devices with an Expired Subscription"
                            />
                            <CardBox
                                title="Active Subscriptions"
                                data={
                                    loading ? (
                                        <img
                                            src={ThreeDotsDark}
                                            alt="loading"
                                            className={classes.loadingImg}
                                        />
                                    ) : (
                                        <>
                                            {(subscriptionDetails &&
                                                subscriptionDetails.active_subscriptions) ||
                                                0}{' '}
                                            <br />
                                            {subscriptionDetails && (
                                                <ul className={classes.regionList}>
                                                    {activeAndExpiredData &&
                                                        activeAndExpiredData.used_months.map(
                                                            used_month => (
                                                                <li>
                                                                    {used_month.region}:{' '}
                                                                    {used_month.count}
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            )}
                                        </>
                                    )
                                }
                                helpText="Sum of Total Used Months from Initial Subscription Pool and Total Out of Pool Month Consumed"
                            />
                            <CardBox
                                title="Total Out of Pool Used Months"
                                data={
                                    loading ? (
                                        <img
                                            src={ThreeDotsDark}
                                            alt="loading"
                                            className={classes.loadingImg}
                                        />
                                    ) : (
                                        <>
                                            {(subscriptionDetails &&
                                                subscriptionDetails.out_of_pool_used_months) ||
                                                0}{' '}
                                            <br />
                                            {subscriptionDetails && (
                                                <ul className={classes.regionList}>
                                                    {activeAndExpiredData &&
                                                        activeAndExpiredData.out_of_pool_used_months.map(
                                                            out_of_pool_used_month => (
                                                                <li>
                                                                    {out_of_pool_used_month.region}:{' '}
                                                                    {out_of_pool_used_month.count}
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            )}
                                        </>
                                    )
                                }
                                helpText="Total months used post expiry of initial subscription pool or after consuming all available months pool from initial subscription pool"
                            />
                            <CardBox
                                title={`Out of Pool Used Months for ${currentMonth()}`}
                                data={
                                    loading ? (
                                        <img
                                            src={ThreeDotsDark}
                                            alt="loading"
                                            className={classes.loadingImg}
                                        />
                                    ) : (
                                        <>
                                            {(subscriptionDetails &&
                                                subscriptionDetails.current_month_out_of_pool_used) ||
                                                0}
                                            <br />
                                            {subscriptionDetails && (
                                                <ul className={classes.regionList}>
                                                    {subscriptionDetails &&
                                                        subscriptionDetails.region_current_month_out_of_pool_used.map(
                                                            current_month => (
                                                                <li>
                                                                    {current_month.region}:{' '}
                                                                    {current_month.count}
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            )}
                                        </>
                                    )
                                }
                                helpText="Total months used post expiry of initial subscription pool or after consuming all available months pool from initial subscription pool in the current month"
                            />
                            <CardBox
                                title={`M.A.U from 8th Sept 2022 to Today ${currentDay()}th ${currentMonth()}`}
                                data={
                                    loading ? (
                                        <img
                                            src={ThreeDotsDark}
                                            alt="loading"
                                            className={classes.loadingImg}
                                        />
                                    ) : (
                                        <>
                                            {(activeAndExpiredData &&
                                                activeAndExpiredData.monthly_active_units) ||
                                                0}{' '}
                                            <br />
                                            {subscriptionDetails && (
                                                <ul className={classes.regionList}>
                                                    {activeAndExpiredData &&
                                                        activeAndExpiredData.monthly_active_units_region_wise.map(
                                                            mau => (
                                                                <li>
                                                                    {mau.region}: {mau.count}
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            )}
                                        </>
                                    )
                                }
                                helpText={`Enrolled after 8th September 2022 to today ${currentDay()}th ${currentMonth()} and in enrolled state`}
                            />
                        </Box>
                    </Box>
                </Paper>
                <ReportTabs />
            </Container>
        </>
    );
};

const CardBox = props => {
    const classes = useStyles();
    const { title, data, helpText } = props;
    return (
        <>
            <Card className={classes.card} variant="outlined">
                <CardHeader
                    action={
                        <Tooltip arrow title={helpText} classes={{ tooltip: classes.toolTipWidth }}>
                            <IconButton aria-label="settings">
                                <InfoIcon className={classes.infoIcon} />
                            </IconButton>
                        </Tooltip>
                    }
                    title={title}
                    titleTypographyProps={{ variant: 'body1' }}
                />
                <CardContent>
                    {title != 'Validity' ? (
                        <Typography className={classes.data} variant="body2" component="p">
                            {data}
                        </Typography>
                    ) : (
                        <Typography className={classes.validity} variant="body2" component="p">
                            {data}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default SubscriptionReport;
