import React from 'react';
import { Button } from '@material-ui/core';
import ProfileIcon from '@material-ui/icons/DeveloperBoard';

import { NavLink } from 'react-router-dom';

// see https://material-ui.com/guides/composition/#button
export default props => {
    // const classes = useStyles()

    return (
        <>
            <Button
                to={process.env.PUBLIC_URL + '/profiles'}
                color="inherit"
                activeClassName="active"
                id="master-profiles-link"
                component={NavLink}>
                <ProfileIcon /> Master Profiles
            </Button>
        </>
    );
};
