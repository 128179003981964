import React, { useRef, useState, useEffect, useContext } from 'react';
import 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import http from 'core/http';
import usePermissionsHook from 'Hooks/usePermissionHook';
import { StyledTabs, StyledTab, Tabpanel } from 'components/tabs/StyledTabs';
import SubscriptionUsageInfo from './SubscriptionUsageInfo';
import ReportFilterBar from './ReportFilterBar';
import UsersMultipleDevicesInfo from './UsersMultipleDevicesInfo';
import { Box, CircularProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import DownloadCsvButton from '../DownloadCsvButton';
import UserApi from 'components/providers/UserApi';
import { UserContext } from 'components/providers/UserProvider';
import CustomRegionMultiSelect from 'components/Regions/CustomRegionMultiSelect';
import { SHOW_TOAST } from 'components/providers/actions';

const PERMISSION_SLUG = 'Byjus/Reports';
const PER_PAGE = 10;

const useStyles = makeStyles(theme => ({
    tabContainer: {
        backgroundColor: '#9baaba !important', //theme.palette.background.paper,
        marginTop: 25,
        marginBottom: 5,
        padding: '6px 0 0',
        borderRadius: 3,
        boxShadow: '0 2px 4px 0 rgba(50,50,93,.1)',

        '& > .tabpanel': {
            minHeight: 460,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
    },
    centeredProgress: {
        height: 600,
        position: 'relative',
        left: '45%',
        top: '40%',
    },

    centeredText: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        fontSize: 14,
        marginTop: 10,
        marginBottom: 12,
    },
    downloadCsvButton: {
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 10,
    },
    downloadAsCsv: {
        display: 'flex',
        marginBottom: 10,
        justifyContent: 'space-between',
    },
    selectedRegion: {
        border: '1px solid #ced4da',
        padding: 7,
        marginLeft: 20,
        borderRadius: 24,
        display: 'flex',
        alignItems: 'center',
    },
}));

const ReportTabs = props => {
    const classes = useStyles();
    const { allowed } = usePermissionsHook(PERMISSION_SLUG);
    const queryParameter = useRef(null);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [{ user }, userDispatch] = useContext(UserContext);
    const [currentTab, setCurrentTab] = useState(0);
    const [subscriptionUsageInfo, setSubscriptionUsageInfo] = useState([]);
    const [usersMultipleDevicesInfo, setUsersMultipleDevicesInfo] = useState([]);
    const [hideClearButton, setHideClearButton] = useState(true);
    const [allTags, setAllTags] = useState([]);
    const [masterProfiles, setMasterProfiles] = useState([]);
    const [totalReports, setTotalReports] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [usersMultipleDevicesInfoPage, setUsersMultipleDevicesInfoPage] = useState(1);

    const [usersOnMultipleDevicesCount, setUsersOnMultipleDevicesCount] = useState(0);
    const [transferInfo, setTransferInfo] = useState({});
    const [loading, setLoading] = useState(false);
    // For Region
    const [allRegions, setAllRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [filterClicked, setFilterClicked] = useState(true);

    // Filters on "Subscription Usage Information" Tab
    const [filters, setFilters] = React.useState({
        status: 'all',
        query: '',
        masterProfileIds: [],
        serialNos: '',
        tags: [],
        toDate: null,
        fromDate: null,
    });

    // Filters on "Users on Multiple Devices" Tab
    const [sortBy, setSortBy] = React.useState('subscription_transfer_expiry');
    const [filters2, setFilters2] = React.useState({
        status: 'all',
        query: '',
        masterProfileIds: [],
        serialNos: '',
        tags: [],
        toDate: null,
        fromDate: null,
        reportType: 'subscription_transfer',
    });

    const handleTabChange = (event, newVal) => {
        setCurrentTab(newVal);
        clearFilters();
        clearFilters2();
    };

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }
    // Fetch All MAster Profiles
    const fetchMasterProfiles = () => {
        setLoading(true);
        UserApi.loadMasterProfileList(user.id)
            .then(response => {
                setMasterProfiles(response);
            })
            .catch(error => {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => setLoading(false));
    };

    // Fetch All Tags
    function fetchAllTags() {
        async function fetchData() {
            const response = await http.get('/api/v3/tags.json');
            setAllTags(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    }

    const formatDate = date => {
        return date && date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    };

    function fetchSubscriptionReport(clearParams = false) {
        setFilterClicked(true);
        setLoading(true);
        let params = {};
        if (clearParams) {
            params = {
                query: '',
                serial_nos: '',
                status: 'all',
                tags: [],
                master_profile_ids: [],
                toDate: formatDate(getToDate()),
                fromDate: formatDate(getFromDate()),
                page: 1,
                per_page: 10,
                regions: [],
            };
        } else {
            params['query'] = filters.query;
            params['status'] = filters.status;
            params['serial_nos'] = filters.serialNos;
            params['master_profile_ids'] = filters.masterProfileIds;
            params['tags'] = filters.tags;
            params['from_date'] = formatDate(filters.fromDate);
            params['to_date'] = formatDate(filters.toDate);
            params['page'] = page;
            params['per_page'] = PER_PAGE;
            params['regions'] = selectedRegion;
        }
        async function fetchData() {
            const response = await http.get('/api/v3/subscription_reports.json', { params });
            setSubscriptionUsageInfo(response.data.subscription_usage_info);
            setTotalReports(response.data.total_reports);
            setTotalPages(Math.ceil(response.data.total_reports / 10));
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function fetchUsersMultipleDeviceInfo(clearParams = false) {
        setLoading(true);
        let params = {};
        if (clearParams) {
            params = {
                query: '',
                serial_nos: '',
                status: 'all',
                masterProfileIds: [],
                tags: [],
                sortBy: 'subscription_transfer_expiry',
                toDate: formatDate(getToDateForTransfer()),
                fromDate: formatDate(getFromDateForTransfer()),
                page: 1,
                per_page: 10,
            };
        } else {
            params['query'] = filters2.query;
            params['serial_nos'] = filters2.serialNos;
            params['master_profile_ids'] = filters2.masterProfileIds;
            params['tags'] = filters2.tags;
            params['sort_by'] = sortBy;
            params['from_date'] = formatDate(filters2.fromDate);
            params['to_date'] = formatDate(filters2.toDate);
            params['query_parameter'] = queryParameter.current;
            params['page'] = usersMultipleDevicesInfoPage;
            params['per_page'] = 10;
        }
        async function fetchData() {
            const response = await http.get(
                '/api/v3/subscription_reports/users_on_multiple_devices.json',
                { params },
            );
            let sortedByTransferDate = response.data.users_on_multiple_devices;
            if (sortBy == 'subscription_transfer_expiry') {
                sortedByTransferDate = response.data.users_on_multiple_devices.sort(function (
                    a,
                    b,
                ) {
                    return Date.parse(a['transfer_end_date']) - Date.parse(b['transfer_end_date']);
                });
            }
            // setTotalReports(response.data.total_reports);
            setUsersOnMultipleDevicesCount(response.data.total_reports);
            setTransferInfo(response.data.transfer_info);
            setUsersMultipleDevicesInfo(sortedByTransferDate);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        let toDate = getToDate();
        let fromDate = getFromDate();
        let toDateForTransfer = getToDateForTransfer();
        let fromDateForTransfer = getFromDateForTransfer();
        let a = '';
        if (query.get('q')) {
            // Query params is used for Users on multiple devices tab for transfer action
            queryParameter.current = query.get('q');
            a = query.get('q');
            setFilters2({ ...filters, ...{ ['query']: a } });
            setCurrentTab(1);
        }
        setFilters({ ...filters, ...{ ['toDate']: toDate, ['fromDate']: fromDate, ['query']: a } });
        setFilters2({
            ...filters2,
            ...{ ['toDate']: toDateForTransfer, ['fromDate']: fromDateForTransfer, ['query']: a },
        });
    }, []);

    useEffect(() => {
        fetchAllTags();
        fetchAllRegions();
        fetchMasterProfiles();
    }, []);
    useEffect(() => {
        fetchSubscriptionReport();
    }, [page]);

    useEffect(() => {
        fetchUsersMultipleDeviceInfo();
    }, [usersMultipleDevicesInfoPage]);

    const getToDate = () => {
        const currentDate = new Date();
        let toDate = new Date(
            currentDate.getFullYear() +
                '-' +
                (currentDate.getMonth() + 1) +
                '-' +
                currentDate.getDate(),
        );
        return toDate;
    };
    const getFromDate = () => {
        const currentDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(currentDate.getDate() - 6);
        fromDate = new Date(fromDate.toDateString());
        fromDate = new Date(
            fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate(),
        );
        return fromDate;
    };

    // Fot Subscription Transfer Info
    const getFromDateForTransfer = () => {
        const currentDate = new Date();
        let fromDate = new Date(
            currentDate.getFullYear() +
                '-' +
                (currentDate.getMonth() + 1) +
                '-' +
                currentDate.getDate(),
        );
        return fromDate;
    };

    const getToDateForTransfer = () => {
        const currentDate = new Date();
        let toDate = new Date();
        toDate.setDate(currentDate.getDate() + 6);
        toDate = new Date(toDate.toDateString());
        toDate = new Date(
            toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate(),
        );
        return toDate;
    };

    const clearFilters = () => {
        let fromDate = getFromDate();
        let toDate = getToDate();
        setFilters({
            status: 'all',
            query: '',
            masterProfileIds: [],
            serialNos: '',
            tags: [],
            toDate: toDate,
            fromDate: fromDate,
            reportType: 'subscription_usage',
        });
        setSelectedRegion([]);
        queryParameter.current = null;
        if (currentTab == 0) {
            fetchSubscriptionReport(true);
        }
    };
    const clearFilters2 = () => {
        let fromDate = getFromDateForTransfer();
        let toDate = getToDateForTransfer();
        setFilters2({
            status: 'all',
            query: '',
            masterProfileIds: [],
            serialNos: '',
            tags: [],
            toDate: toDate,
            fromDate: fromDate,
            reportType: 'subscription_transfer',
        });
        setSortBy('subscription_transfer_expiry');
        queryParameter.current = null;
        if (currentTab == 1) {
            fetchUsersMultipleDeviceInfo(true);
        }
    };

    const paramsForCSV = () => {
        let params = {
            params: {
                query: filters2.query,
                serial_nos: filters2.serialNos,
                master_profile_ids: filters2.masterProfileIds,
                tags: filters2.tags,
                sort_by: sortBy,
                from_date: formatDate(filters2.fromDate),
                to_date: formatDate(filters2.toDate),
                query_parameter: queryParameter.current,
            },
        };
        return params;
    };

    const subscriptionInfoParams = () => {
        let params = {
            params: {
                query: filters.query,
                status: filters.status,
                serial_nos: filters.serialNos,
                master_profile_ids: filters.masterProfileIds,
                tags: filters.tags,
                from_date: formatDate(filters.fromDate),
                to_date: formatDate(filters.toDate),
            },
        };
        return params;
    };

    // Fetch Regions
    function fetchAllRegions() {
        async function fetchData() {
            const response = await http.get('/api/v3/regions.json');
            setAllRegions(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    }

    // Selected Regions for label
    const selectedRegions = allRegions.filter(region =>
        selectedRegion.some(name => region.name === name),
    );

    return (
        <div className={classes.tabContainer}>
            <StyledTabs onChange={handleTabChange} centered value={currentTab}>
                <StyledTab label="Subscription Usage Information" />
                <StyledTab label="Subscription Transfer Information" />
                {/* <StyledTab label="Enrollment Information" /> */}
            </StyledTabs>
            <Tabpanel index={0} value={currentTab}>
                <SubscriptionUsageHeader
                    loading={loading}
                    allRegions={allRegions}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    selectedRegions={selectedRegions}
                    fetchSubscriptionReport={fetchSubscriptionReport}
                    subscriptionInfoParams={subscriptionInfoParams}
                    subscriptionUsageInfo={subscriptionUsageInfo}
                    setHideClearButton={setHideClearButton}
                    setFilterClicked={setFilterClicked}
                />
                <ReportFilterBar
                    masterProfiles={masterProfiles}
                    showSerialNoFilter={true}
                    filters={filters}
                    setFilters={setFilters}
                    setPage={setPage}
                    loadDataWithFilter={fetchSubscriptionReport}
                    allTags={allTags}
                    clearFilter={clearFilters}
                    loading={loading}
                    selectedRegion={selectedRegion}
                    hideClearButton={hideClearButton}
                    setHideClearButton={setHideClearButton}
                />
                {loading ? (
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.centeredProgress}
                    />
                ) : (
                    <SubscriptionUsageInfo
                        subscriptionUsageInfo={subscriptionUsageInfo}
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        selectedRegion={selectedRegion}
                        filterClicked={filterClicked}
                    />
                )}
            </Tabpanel>
            <Tabpanel index={1} value={currentTab}>
                <Box className={classes.downloadCsvButton}>
                    <DownloadCsvButton
                        csvPath="/api/v3/subscription_reports/users_on_multiple_devices.csv"
                        parameters={paramsForCSV()}
                        fileName="users_on_multiple_devices"
                        disabled={loading || usersMultipleDevicesInfo.length == 0}
                    />
                </Box>
                <ReportFilterBar
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    showDeviceStatusFilter={false}
                    masterProfiles={masterProfiles}
                    filters={filters2}
                    setFilters={setFilters2}
                    loadDataWithFilter={fetchUsersMultipleDeviceInfo}
                    allTags={allTags}
                    setPage={setPage}
                    clearFilter={clearFilters2}
                    loading={loading}
                    hideClearButton={hideClearButton}
                    setHideClearButton={setHideClearButton}
                />

                {!loading && (
                    <Box className={classes.centeredText}>
                        <b>{`Total Users: ${usersOnMultipleDevicesCount}`}</b>
                        <b>{`Subscription Transferred Count: ${
                            transferInfo.subscription_transferred_count || 0
                        }`}</b>
                        <b>{`Missed Subscription Transfers Count:  ${
                            transferInfo.missed_subscription_transfers_count || 0
                        }`}</b>
                        <b>{`Missed Subscription Transfers & Deleted Count:  ${
                            transferInfo.missed_subscription_transfers_and_deleted_count || 0
                        }`}</b>
                    </Box>
                )}

                {loading ? (
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.centeredProgress}
                    />
                ) : (
                    <UsersMultipleDevicesInfo
                        fetchUsersMultipleDeviceInfo={fetchUsersMultipleDeviceInfo}
                        usersMultipleDevicesInfo={usersMultipleDevicesInfo}
                        totalReports={usersOnMultipleDevicesCount}
                        page={usersMultipleDevicesInfoPage}
                        setPage={setUsersMultipleDevicesInfoPage}
                    />
                )}
            </Tabpanel>
            {/* <Tabpanel index={2} value={currentTab}>
                To be implemented...
            </Tabpanel> */}
        </div>
    );
};

const SubscriptionUsageHeader = props => {
    const classes = useStyles();
    const {
        loading,
        allRegions,
        selectedRegion,
        setSelectedRegion,
        selectedRegions,
        fetchSubscriptionReport,
        subscriptionInfoParams,
        subscriptionUsageInfo,
        setHideClearButton,
        setFilterClicked,
    } = props;
    return (
        <Box className={classes.downloadAsCsv}>
            <Box display="flex">
                <CustomRegionMultiSelect
                    allRegions={allRegions}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    handleAccept={fetchSubscriptionReport}
                    setHideClearButton={setHideClearButton}
                    setFilterClicked={setFilterClicked}
                />
                {selectedRegions.map(region => (
                    <Box className={classes.selectedRegion}>
                        <Box marginRight="4px" marginTop="2px">
                            <img
                                width="19"
                                src={`https://flagcdn.com/w20/${region.region_code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${region.region_code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                        </Box>
                        {region.name}
                    </Box>
                ))}
            </Box>
            <DownloadCsvButton
                csvPath="/api/v3/subscription_reports/users_subscription_usage_info.csv"
                parameters={subscriptionInfoParams()}
                fileName="subscription_usage_information"
                disabled={loading || subscriptionUsageInfo.length == 0}
            />
        </Box>
    );
};

export default ReportTabs;
