import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import CopyIcon from '@material-ui/icons/FileCopy';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
    hiddenInput: {
        opacity: 0,
        position: 'absolute',
    },

    copyIcon: {
        marginLeft: 15,
        textAlign: 'left',
        padding: 0,
    },
    tooltip: {
        display: 'flex',
    },
}));

const CopyComponent = props => {
    const { inputRef, inputValue } = props;

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    function copyFunction() {
        inputRef.current.select();
        document.execCommand('copy');
        setOpen(true);
    }

    return (
        <>
            {/* {inputValue} */}
            <input
                ref={inputRef}
                className={classes.hiddenInput}
                type="text"
                readOnly
                value={inputValue || ''}
            />
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    title="Copied"
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                        disablePortal: true,
                    }}>
                    <IconButton
                        className={classes.copyIcon}
                        id="users-copy-user-id-button"
                        onClick={copyFunction}>
                        <CopyIcon />
                    </IconButton>
                </Tooltip>
            </ClickAwayListener>
        </>
    );
};

export default CopyComponent;
