import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

export default props => {
  return (
    <OutlinedInput
      fullWidth
      margin="dense"
      onChange={props.onChange}
      type="search"
      inputProps={{ placeholder: "Search" }}
      startAdornment={
        <InputAdornment>
          <SearchIcon color="disabled" style={{ marginRight: 5 }} />
        </InputAdornment>
      }
      {...props}
    />
  );
};
