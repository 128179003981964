import http from 'core/http';
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    Select,
} from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';
import MultiSelect from 'react-multi-select-component';
import _ from 'lodash';
import TotalAppUsageData from './TotalAppUsageData';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    filters: {
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
    },
    select: {
        marginTop: '16px',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        width: '25%',
        marginRight: 15,
    },

    dateControl: {
        width: '60%',
        marginRight: 15,
    },

    loading: {
        position: 'absolute',
        marginTop: '5%',
    },
    searchField: {
        margin: 0,
    },
    tags_filter: {
        width: '250px',
        marginTop: '16px',
    },

    actionButton: {
        marginLeft: 'auto',
        marginRight: 15,
        fontSize: 11,
    },

    topContainer: {
        width: 'calc(100% - 300px)',
        marginLeft: 20,
    },

    disabled: {
        pointerEvents: 'none',
        opacity: 0.6,
    },

    profileSelect: {
        maxWidth: 300,
        minWidth: 200,
    },

    dateControl: {
        marginRight: 15,
        marginLeft: 15,
    },
    label: {
        visibility: 'hidden',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                // // For Outline box
                // marginLeft: '-25px',
                width: '65%',
                // textAlign: 'center',
                padding: 0,
                marginBottom: 5,
            },
            root: {
                justifyContent: 'center',
            },
        },
        MuiInputAdornment: {
            root: {
                width: 'max-content',
                marginBottom: 7,
            },
        },
        MuiIconButton: {
            root: {
                padding: 0,
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(21px, 14px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '11px',
            },
        },
    },
});

const TotalAppUsage = props => {
    const classes = useStyles();
    const {
        profiles,
        selectedProfiles,
        setSelectedProfiles,
        totalAppUsageData,
        setTotalAppUsageData,
    } = props;
    const [noDataMessage, setNoDataMessage] = useState(
        'Please select the master profile and desired date then click on Submit to view report.',
    );
    const [loading, setLoading] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);
    const [filterDate, setFilterDate] = useState(new Date());
    const [selectedDate, handleDateChange] = useState(null);

    const formatDate = date => {
        return date && date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate();
    };
    const fetchData = () => {
        setTotalAppUsageData([]);
        setNoDataMessage('Fetching Data, Please wait!');
        setLoading(true);
        const data = {
            params: {
                date: formatDate(filterDate),
                profile_ids: selectedProfiles.value,
            },
        };
        // http.get(`/api/v3/app_data_usage_report/total_app_usage.json`, formatData())
        http.get(`/api/v3/app_data_usage_report/total_app_usage.json`, data)
            .then(response => {
                console.log('response->', response);
                setTotalAppUsageData(response.data.data);
                setLoading(false);
                setResetFilter(false);
                if (response.data.data.length === 0) setNoDataMessage('No Data Found!!!');
            })
            .catch(error => {});
    };

    useEffect(() => {
        if (profiles.length > 0 && totalAppUsageData.length === 0) {
            fetchData();
        }
    }, []);

    // // USE THIS FUNCTION IN CASE OF MULTI SELECT

    /**
     * Format Parameter Data
     */
    // const formatData = () => {
    //     const data = {
    //         params: {
    //             date: formatDate(filterDate),
    //         },
    //     };

    //     if (selectedProfiles.length === 0 || selectedProfiles.length === profiles.length)
    //         return { data };
    //     let selectedProfileIds = selectedProfiles.map(function (profile) {
    //         return profile.value;
    //     });

    //     data.params.profile_ids = selectedProfileIds;
    //     return data;
    // };

    const isClearButtonVisible = () => {
        return (
            selectedProfiles.length > 0 ||
            (filterDate && filterDate.toDateString() != new Date().toDateString())
        );
    };

    const clearFilters = () => {
        // setSelectedProfiles([]);
        setResetFilter(true);
        setFilterDate(new Date());
    };

    useEffect(() => {
        if (resetFilter) fetchData();
    }, [resetFilter]);

    const onChangeProfileHandler = data => {
        if (data.length === 0) setResetFilter(true);
        setSelectedProfiles(data);
    };

    const handleFilterDateChange = date => {
        setFilterDate(date);
    };

    const [age, setAge] = React.useState('');

    const handleChange = event => {
        setSelectedProfiles(event.target.value);
    };

    return (
        <>
            <Box style={{ marginTop: '20px' }} className={classes.filters}>
                <Box display="flex" ml="15px" alignItems="center">
                    <FilterList className={classes.spaceAround} />
                    <Box ml="5px"> Filter Records </Box>
                </Box>
                <Box className={classes.topContainer}>
                    <Box display="flex" alignItems="center" m="10px">
                        {/* <FormControl className={classes.profileSelect}>
                            <MultiSelect
                                options={profiles}
                                value={selectedProfiles}
                                onChange={onChangeProfileHandler}
                                labelledBy={'Select'}
                                hasSelectAll={false}
                                overrideStrings={{
                                    selectSomeItems: 'Select Master Profiles',
                                    search: 'Search Master Profiles',
                                }}
                            />
                        </FormControl> */}

                        <MuiThemeProvider theme={theme}>
                            <FormControl className={classes.formControl} variant="outlined">
                                <InputLabel
                                    shrink={false}
                                    className={selectedProfiles.value && classes.label}
                                    id="activity-type-label">
                                    Select Master Profiles
                                </InputLabel>
                                <Select
                                    value={selectedProfiles.label}
                                    // onChange={onChangeProfileHandler}
                                    // input={<Input />}
                                    renderValue={selected => selected}
                                    MenuProps={MenuProps}>
                                    {profiles.map(profile => (
                                        <MenuItem
                                            key={profile.value}
                                            value={profile.label}
                                            onClick={() => onChangeProfileHandler(profile)}>
                                            <ListItemText primary={profile.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </MuiThemeProvider>

                        <FormControl className={classes.dateControl}>
                            <Box border="1px solid #ced4da" borderRadius="4px">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    marginLeft="auto"
                                    marginRight="auto"
                                    width="max-content"
                                    paddingLeft="10px"
                                    paddingRight="10px"
                                    style={{ background: 'white', marginTop: '-10px' }}>
                                    App Usage on
                                </Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MuiThemeProvider theme={theme}>
                                        <Box textAlign="center" marginBottom="5px" marginTop="5px">
                                            <KeyboardDatePicker
                                                style={{ width: '60%', marginTop: '0px' }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                autoOk
                                                inputVariant="standard"
                                                // label="App Usage on"
                                                format="dd MMM yyyy"
                                                value={filterDate}
                                                InputAdornmentProps={{ position: 'start' }}
                                                clearable={false}
                                                disableFuture
                                                allowKeyboardControl={false}
                                                onChange={date => handleFilterDateChange(date)}
                                            />
                                        </Box>
                                    </MuiThemeProvider>
                                </MuiPickersUtilsProvider>
                            </Box>
                        </FormControl>
                    </Box>
                </Box>
                {isClearButtonVisible() && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.actionButton}
                        onClick={clearFilters}>
                        Clear
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.actionButton}
                    disabled={loading || selectedProfiles.length === 0 || !filterDate}
                    onClick={fetchData}>
                    <strong>FILTER</strong>
                </Button>
            </Box>
            <TotalAppUsageData
                loading={loading}
                noDataMessage={noDataMessage}
                totalAppUsageData={totalAppUsageData}
            />
        </>
    );
};

export default TotalAppUsage;
