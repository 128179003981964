import http from 'core/http';
import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Grid,
    Select,
    MenuItem,
    FormControl,
    Input,
    Chip,
    TextField,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Heading from 'components/header/Heading';
import { ArrowForward, FilterList } from '@material-ui/icons';
import DownloadCsvButton from './DownloadCsvButton';
import AppVersionList from './AppVersionList';
import MultiSelect from 'react-multi-select-component';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import CustomMultiSelectDropdown from './CustomMultiSelectDropdown';
import { UserContext } from 'components/providers/UserProvider';
import { SHOW_TOAST } from 'components/providers/actions';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    filters: {
        background: 'white',
        display: 'flex',
        alignItems: 'center',
    },
    select: {
        marginTop: '16px',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        // minWidth: 120,
        // maxWidth: 300,
        width: '30%',
        marginRight: 15,
    },
    loading: {
        position: 'absolute',
        marginTop: '5%',
    },
    searchFieldFormControl: {
        width: '100%',
    },
    searchField: {
        margin: 0,
    },
    tags_filter: {
        // width: '250px',
        marginTop: '16px',
    },

    actionButton: {
        marginLeft: 'auto',
        marginRight: 15,
        fontSize: 11,
    },

    topContainer: {
        width: 'calc(100% - 300px)',
        marginLeft: 20,
    },

    disabled: {
        pointerEvents: 'none',
        opacity: 0.6,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
    tagsBorder: {
        border: '1px solid #ced4da',
        borderRadius: '4px',
        padding: '0 10px',
        backgroundColor: '#fff',
        marginTop: '10px',
    },
    dropdownContent: {
        '& .dropdown-content': {
            width: 'max-content !important',
            minWidth: '100%',
        },
    },
}));

const theme = createMuiTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                borderRadius: '4px',
                padding: '2px',
            },
        },
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ApplicationVersionReport = props => {
    const classes = useStyles();
    const [apps, setApps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [masterProfiles, setMasterProfiles] = useState([]);
    const [appList, setAppList] = useState([]);
    const [selectedApps, setSelectedApps] = useState([]);
    const [selectedMasterProfiles, setSelectedMasterProfiles] = useState([]);
    const [totalAppCount, setTotalAppCount] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [query, setQuery] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [noAppMessage, setAppMessage] = useState('Select App(s) to fetch report');
    const [selectedAppVersions, setSelectedAppVersions] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [excludeTags, setExcludeTags] = useState(false);
    const [{ user }, userDispatch] = useContext(UserContext);

    const [disableIfAppNotSelected, setDisableIfAppNotSelected] = useState(true);
    const [appVersionDisabled, setAppVersionDisabled] = useState(true);
    const [allAppVersions, setAllAppVersions] = useState([]);
    const [appVersions, setAppVersions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [deviceOSVersion, setDeviceOSVersion] = useState([]);
    const [selectedDeviceOSVersions, setSelectedDeviceOSVersions] = useState([]);

    const [selectedMasterProfileOptions, setSelectedMasterProfileOptions] = useState([]);
    const [selectedAppVersionOptions, setSelectedAppVersionOptions] = useState([]);
    const [selectedDeviceOSVersionOptions, setSelectedDeviceOSVersionOptions] = useState([]);

    useEffect(() => {
        fetchInitialData();
        fetchAllTags();
    }, []);

    function fetchAllTags() {
        async function fetchData() {
            const response = await http.get('/api/v3/tags.json');
            setAllTags(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    }

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    const fetchInitialData = () => {
        setIsLoading(true);
        http.get(`/api/v3/app_version_report/initial_data.json`)
            .then(response => {
                setMasterProfiles(response.data.master_profiles);
                // setAllAppVersions(response.data.app_versions);
                setAppList(response.data.apps);
                // setAllTags(response.data.user_tags);
                setDeviceOSVersion(response.data.device_os_versions);
            })
            .catch(error => {
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchApps = (refreshList = false) => {
        const updateList = refreshList;
        if (updateList) {
            setPage(1);
        }
        setIsLoading(true);
        if (selectedApps.length > 0) {
            const data = formatData();
            http.get(`/api/v3/app_version_report/fetch_apps_data.json`, data)
                .then(response => {
                    setApps(response.data.apps);
                    if (response.data.apps.length === 0) {
                        setAppMessage('No App(s) Found!!!');
                    }
                    setTotalAppCount(response.data.total_apps_count);
                    setTotalPages(response.data.total_pages);
                })
                .catch(error => {})
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        fetchApps(false);
    }, [page]);

    // useEffect(() => {
    //     setSelectedAppVersions([]);
    //     if (selectedApps.length === 1) {
    //         let selectedApplication = allAppVersions.filter(
    //             x => x.package === selectedApps[0].package,
    //         )[0];
    //         setAppVersions(selectedApplication ? selectedApplication.options : []);
    //         setAppVersionDisabled(false);
    //     } else {
    //         setAppVersionDisabled(true);
    //         setAppVersions([]);
    //     }
    // }, [selectedApps]);

    const fetchAppVersions = () => {
        // setIsLoading(true);
        let selectedAppPackages = selectedApps.map(function (app) {
            return app.package;
        });
        const params = {
            params: {
                packages: selectedAppPackages,
            },
        };
        http.get(`/api/v3/app_version_report/version_filter_data.json`, params)
            .then(response => {
                setAllAppVersions(response.data.app_versions);
            })
            .catch(error => {})
            .finally(() => {
                // setIsLoading(false);
            });
    };

    useEffect(() => {
        setSelectedAppVersions([]);
        if (selectedApps.length == 1) {
            const selectedApplication = allAppVersions.filter(
                a => a.package == selectedApps[0].package,
            )[0];
            setAppVersions(selectedApplication ? selectedApplication.options : []);
            setDisableIfAppNotSelected(false);
            setAppVersionDisabled(false);
        } else if (selectedApps.length >= 1) {
            setDisableIfAppNotSelected(false);
            setAppVersionDisabled(true);
            setAppVersions([]);
        } else {
            setDisableIfAppNotSelected(true);
            setAppVersionDisabled(true);
            setAppVersions([]);
        }
    }, [selectedApps, allAppVersions]);

    useEffect(() => {
        if (selectedApps.length == 1) {
            fetchAppVersions();
        }
    }, [selectedApps]);

    /**
     * Format Parameter Data
     */
    const formatData = () => {
        let selectedAppPackages = selectedApps.map(function (app) {
            return app.package;
        });

        // let selectedMasterProfileIds = selectedMasterProfiles.map(function (masterProfile) {
        //     return masterProfile.value;
        // });
        let selectedMasterProfileIds = selectedMasterProfileOptions;

        // let selectedAppVersion = selectedAppVersions.map(function (appVersion) {
        //     return appVersion.value;
        // });
        let selectedAppVersion = selectedAppVersionOptions;

        // let selectedDeviceOSVersion = selectedDeviceOSVersions.map(function (deviceOSVersion) {
        //     return deviceOSVersion.value;
        // });
        let selectedDeviceOSVersion = selectedDeviceOSVersionOptions;

        const params = {
            params: {
                selected_app_packages: selectedAppPackages,
                master_profile_ids: selectedMasterProfileIds,
                query: query,
                per_page: perPage,
                page: page,
                versions: selectedAppVersion,
                tags: tags,
                exclude_tags: excludeTags,
                device_os_versions: selectedDeviceOSVersion,
            },
        };
        return params;
    };
    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    const handleSearchTextChange = event => {
        setQuery(event.target.value);
    };

    const incomingTags = allTags.map(tag => tag.name);

    const handleTagChange = newTags => {
        var nameArray = newTags.map(function (tag) {
            return tag;
        });
        setTags(nameArray);
        if (nameArray.length == 0) {
            setExcludeTags(false);
        }
    };

    function appDropdownRenderer({ checked, option, onClick, disabled }) {
        return (
            <Box display="flex" alignItems="center">
                <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex={-1}
                    disabled={disabled}
                />
                <Box ml="5px">{option.label}</Box>
            </Box>
        );
    }

    const handleAppSelect = selectedAppArray => {
        if (selectedAppArray.length === 1 || selectedAppArray.length == appList.length) {
            setSelectedApps(selectedAppArray);
        } else if (selectedAppArray.length == appList.length - 1) {
            setSelectedApps(appList.filter(x => !selectedAppArray.includes(x)));
        } else {
            setSelectedApps(selectedAppArray.splice(1));
        }
        appSelectedClearFilters();
    };

    const appSelectedClearFilters = () => {
        // setSelectedApps([]); //We do not need to Clear selected app
        setSelectedMasterProfiles([]);
        setQuery('');
        setSelectedAppVersions([]);
        setTags([]);
        setApps([]);
        setExcludeTags(false);
        setSelectedDeviceOSVersions([]);
        setSelectedMasterProfileOptions([]);
        setSelectedAppVersionOptions([]);
        setSelectedDeviceOSVersionOptions([]);
        setAppVersions([]);
        setPage(1);
    };

    const clearFilters = () => {
        setSelectedApps([]);
        setSelectedMasterProfiles([]);
        setQuery('');
        setSelectedAppVersions([]);
        setTags([]);
        setApps([]);
        setExcludeTags(false);
        setSelectedDeviceOSVersions([]);
        setSelectedMasterProfileOptions([]);
        setSelectedAppVersionOptions([]);
        setSelectedDeviceOSVersionOptions([]);
        setAppVersions([]);
        setPage(1);
    };

    const isClearButtonVisible = () => {
        return Boolean(
            selectedApps.length ||
                selectedMasterProfiles.length ||
                query.length ||
                selectedAppVersions.length ||
                tags.length ||
                selectedDeviceOSVersions.length ||
                selectedMasterProfileOptions.length ||
                selectedAppVersionOptions.length ||
                selectedDeviceOSVersionOptions.length,
        );
    };

    const handleExcludeTags = event => {
        setExcludeTags(event.target.checked);
    };

    const filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'), 'i');
        return options.filter(value => value.label && value.label.match(re));
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <Box>
                                <Button color="primary" onClick={goBack}>
                                    Reports&nbsp; <ArrowForward fontSize="small" />
                                </Button>
                                <span className={classes.reportName}>Application Version</span>
                            </Box>
                            <Box fontSize="13px" marginLeft="8px" color="gray">
                                View and download application version reports from here.
                            </Box>
                        </Box>
                        <Box>
                            <DownloadCsvButton
                                csvPath="/api/v3/app_version_report/fetch_apps_data.csv"
                                parameters={formatData()}
                                fileName="appVersion"
                                disabled={isLoading || apps.length === 0}
                            />
                        </Box>
                    </Box>
                }
            />
            <Box style={{ marginTop: '20px' }} className={classes.filters}>
                <Box display="flex" ml="15px" alignItems="center">
                    <FilterList className={classes.spaceAround} />
                    <Box ml="5px"> Fetch Records </Box>
                </Box>
                <Box className={classes.topContainer}>
                    <Box display="flex" alignItems="center" m="10px">
                        <FormControl className={classes.formControl}>
                            <MultiSelect
                                options={appList}
                                value={selectedApps}
                                ItemRenderer={appDropdownRenderer}
                                onChange={handleAppSelect}
                                labelledBy={'Select'}
                                overrideStrings={{
                                    selectSomeItems: 'Select Apps...',
                                    allItemsAreSelected: 'All apps are selected.',
                                    selectAll: 'Select All Apps',
                                    search: 'Search Apps',
                                }}
                                filterOptions={filterOptions} // attribute to override fuzzy search algorithm
                            />
                        </FormControl>
                        {/* <FormControl className={classes.formControl}>
                            <MultiSelect
                                options={masterProfiles}
                                value={selectedMasterProfiles}
                                onChange={setSelectedMasterProfiles}
                                labelledBy={'Select'}
                                hasSelectAll={false}
                                overrideStrings={{
                                    selectSomeItems: 'Select Master Profiles...',
                                    search: 'Search Master Profiles',
                                }}
                                filterOptions={filterOptions}
                            />
                        </FormControl> */}
                        <Box
                            className={clsx(classes.formControl, {
                                [classes.disabled]: disableIfAppNotSelected,
                            })}>
                            <CustomMultiSelectDropdown
                                showLabel={false}
                                // inputLabel={"Master Profile"}
                                label={'Select Master Profile'}
                                labelAfterSelecting={'Selected Master Profile/s'}
                                searchBoxLabel={'Search Master Profile'}
                                options={masterProfiles}
                                selectedOptions={selectedMasterProfileOptions}
                                setSelectedOptions={setSelectedMasterProfileOptions}
                                labelKey={'label'}
                                valueKey={'value'}
                            />
                        </Box>
                        <Box
                            className={clsx(classes.formControl, {
                                [classes.disabled]: disableIfAppNotSelected,
                            })}>
                            <FormControl className={classes.searchFieldFormControl}>
                                <MuiThemeProvider theme={theme}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        type="search"
                                        placeholder="Search by user ids separated by comma"
                                        id="search"
                                        className={classes.searchField}
                                        value={query}
                                        onChange={e => handleSearchTextChange(e)}
                                    />
                                </MuiThemeProvider>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" m="10px">
                        {/* <FormControl
                            className={clsx(classes.formControl, {
                                [classes.disabled]: appVersionDisabled,
                            })}>
                            <MultiSelect
                                options={appVersions}
                                value={selectedAppVersions}
                                onChange={setSelectedAppVersions}
                                labelledBy={'Select'}
                                hasSelectAll={false}
                                overrideStrings={{
                                    selectSomeItems: 'Select Version',
                                    search: 'Search App Version',
                                    allItemsAreSelected: 'All versions are selected.',
                                }}
                                filterOptions={filterOptions}
                            />
                        </FormControl> */}
                        <Box
                            className={clsx(classes.formControl, {
                                [classes.disabled]: appVersionDisabled,
                            })}>
                            <CustomMultiSelectDropdown
                                showLabel={false}
                                // inputLabel={"App Version"}
                                label={'Select App Version'}
                                labelAfterSelecting={'Selected App Version/s'}
                                searchBoxLabel={'Search App Version'}
                                options={appVersions}
                                selectedOptions={selectedAppVersionOptions}
                                setSelectedOptions={setSelectedAppVersionOptions}
                                labelKey={'label'}
                                valueKey={'value'}
                            />
                        </Box>
                        <Box
                            className={clsx(classes.formControl, {
                                [classes.disabled]: disableIfAppNotSelected,
                            })}>
                            {/* <Box className={classes.tagsBorder} marginRight="15px"> */}
                            <Box className={classes.tagsBorder}>
                                <Autocomplete
                                    multiple
                                    limitTags={2}
                                    id="multiple-limit-tags"
                                    options={incomingTags}
                                    value={tags}
                                    className={classes.tags_filter}
                                    getOptionLabel={option => option}
                                    renderTags={(value, getTagProps) =>
                                        tags.map((option, index) => (
                                            <Chip
                                                key={index}
                                                variant="outlined"
                                                size="small"
                                                label={option}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    onChange={(event, newInputValue) => {
                                        handleTagChange(newInputValue);
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Search by Tags"
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={excludeTags}
                                            onChange={handleExcludeTags}
                                            disabled={tags.length == 0}
                                            color="primary"
                                        />
                                    }
                                    label="Exclude Tags"
                                />
                            </Box>
                        </Box>
                        {/* For Device OS Version */}
                        {/* <FormControl className={classes.formControl}>
                            <MultiSelect
                                className={classes.dropdownContent}
                                options={deviceOSVersion}
                                value={selectedDeviceOSVersions}
                                onChange={setSelectedDeviceOSVersions}
                                labelledBy={'Select'}
                                hasSelectAll={false}
                                overrideStrings={{
                                    selectSomeItems: 'Select Device OS Version...',
                                    search: 'Search Device OS Version',
                                }}
                                filterOptions={filterOptions}
                            />
                        </FormControl> */}
                        <Box
                            className={clsx(classes.formControl, {
                                [classes.disabled]: disableIfAppNotSelected,
                            })}>
                            <CustomMultiSelectDropdown
                                showLabel={false}
                                // inputLabel={"Master Profile"}
                                label={'Select Device OS Version'}
                                labelAfterSelecting={'Selected Device OS Version/s'}
                                searchBoxLabel={'Search Device OS Version'}
                                options={deviceOSVersion}
                                selectedOptions={selectedDeviceOSVersionOptions}
                                setSelectedOptions={setSelectedDeviceOSVersionOptions}
                                labelKey={'label'}
                                valueKey={'value'}
                            />
                        </Box>
                    </Box>
                </Box>
                {isClearButtonVisible() && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.actionButton}
                        onClick={clearFilters}>
                        Clear
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.actionButton}
                    onClick={() => fetchApps(true)}
                    disabled={isLoading || selectedApps.length === 0}>
                    <strong>FILTER</strong>
                </Button>
            </Box>

            {isLoading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={24} />
                </div>
            ) : (
                <AppVersionList
                    apps={apps}
                    page={page}
                    setPage={setPage}
                    totalRecords={totalAppCount}
                    totalPages={totalPages}
                    isLoading={isLoading}
                    tags={tags}
                    query={query}
                    excludeTags={excludeTags}
                    noAppMessage={noAppMessage}
                    selectedApps={selectedApps}
                    selectedAppVersions={selectedAppVersions}
                    selectedMasterProfiles={selectedMasterProfiles}
                    selectedDeviceOSVersionOptions={selectedDeviceOSVersionOptions}
                />
            )}
        </Container>
    );
};

export default ApplicationVersionReport;
