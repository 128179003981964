import React, { useContext, useState } from 'react';
import {
    Box,
    makeStyles,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
} from '@material-ui/core';
import { Email } from '@material-ui/icons';
import ProgressButton from 'components/utils/ProgressButton';
import http from 'core/http';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },

    formControlRoot: {
        margin: '12px 0',
    },

    resetPasswordText: {
        fontSize: 22,
        fontWeight: 500,
        color: '#323232',
        marginBottom: 15,
    },

    helpText: {
        marginTop: 10,
        color: '#545454',
        lineHeight: '1.5',
    },

    button: {
        width: '-webkit-fill-available',
        marginTop: 20,
    },

    greyIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
    },

    signInText: {
        fontWeight: 500,
        color: '#3f51b5',
        cursor: 'pointer',
        marginLeft: '5px',
        fontSize: 'larger',
    },

    signIn: {
        textAlign: 'center',
        marginTop: 35,
    },
}));

const ForgetPassword = props => {
    const classes = useStyles();
    const { closeFogetPassword, isLoading, setIsLoading } = props;
    const [{ user }, userDispatch] = useContext(UserContext);
    const [email, setEmail] = useState('');

    const updateEmail = event => {
        setEmail(event.target.value);
    };

    function showSuccessToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    }

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    // Reset Password API
    function resetPassword() {
        setIsLoading(true);
        let data = new FormData();
        data.append('user[email]', email);

        http.post('/users/password.json', data)
            .then(res => {
                showSuccessToast(
                    'You will receive an email with instructions on how to reset your password in a few minutes.',
                );
                closeFogetPassword();
            })
            .catch(error => {
                showErrorToast(
                    'Email ' + error.response.data.errors.email || 'Something Went Wrong',
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    //Reset password when hot Enter key
    function onEnterPress(event) {
        if (event.key === 'Enter') {
            resetPassword();
        }
    }

    return (
        <>
            <Box className={classes.resetPasswordText}>Reset Your Password</Box>
            <Box className={classes.helpText}>
                Enter the email Address associated with your account <br />
                and we'll send you a link to reset your password.
            </Box>
            <Box mt="25px">
                <FormControl
                    fullWidth
                    className={classes.margin}
                    classes={{ root: classes.formControlRoot }}
                    variant="outlined">
                    <InputLabel htmlFor="email-input">Your Email</InputLabel>
                    <OutlinedInput
                        onKeyPress={onEnterPress}
                        id="email-input"
                        value={email}
                        onChange={updateEmail}
                        startAdornment={
                            <InputAdornment position="start">
                                <Email className={classes.greyIcon} />
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
            </Box>
            <ProgressButton
                id="reset-password-button"
                color="primary"
                variant="contained"
                styles={classes.button}
                isWaiting={isLoading}
                disabled={isLoading}
                onClick={resetPassword}>
                Reset Password
            </ProgressButton>
            <Box className={classes.signIn}>
                <span style={{ color: '#717171' }}>Already have an account?</span>
                <span className={classes.signInText} onClick={closeFogetPassword}>
                    SIGN IN
                </span>
            </Box>
        </>
    );
};

export default ForgetPassword;
