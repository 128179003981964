/**
 * All App routes, authenticated pages
 */
import React from 'react';
import loadable from '@loadable/component';
import LoadingProgress from 'components/progress/LoadingProgress';
import PermissionName from './Hooks/PermissionNames';
import AccountActivityReport from 'components/reports/AccountActivityReport';
import SubscriptionReport from 'components/reports/SubscriptionReport';
import ApplicationVersionReport from 'components/reports/ApplicationVersionReport';
import AppUsageReport from 'components/reports/AppUsageReport';
import DataIngestionReport from 'components/reports/dataIngestion/DataIngestionReport';
import BulkActionStatusReport from 'components/reports/BackgroundTaskStatus/BulkActionStatusReport';
import ScreenTimeReport from 'components/reports/ScreenTimeReport';
import UserInventoryReport from 'components/reports/UserInventoryReport';
import UserInventoryReportNew from 'components/reports/UserInventoryReportNew';

// import all routes
// import AccountConfig from './pages/AccountConfig'

// import MasterProfiles from './pages/MasterProfiles'
// import Apps from './pages/EnterpriseApps'
// import Reports from './pages/Reports'
// import Admins from './pages/Admins'

const AccountConfig = loadable(() => import('./pages/AccountConfig'), {
    fallback: <LoadingProgress />,
});
const MasterProfiles = loadable(() => import('./pages/MasterProfiles'), {
    fallback: <LoadingProgress />,
});
const Users = loadable(() => import('./pages/Users'), {
    fallback: <LoadingProgress />,
});
const Apps = loadable(() => import('./pages/EnterpriseApps'), {
    fallback: <LoadingProgress />,
});
const Reports = loadable(() => import('./pages/Reports'), {
    fallback: <LoadingProgress />,
});

const RemoteMirror = loadable(() => import('./pages/RemoteMirror'), {
    fallback: <LoadingProgress />,
});

const Admins = loadable(() => import('./pages/Admins'), {
    fallback: <LoadingProgress />,
});
const NewMasterProfile = loadable(() => import('./pages/NewMasterProfile'), {
    fallback: <LoadingProgress />,
});
const Login = loadable(() => import('./pages/Login'), {
    fallback: <LoadingProgress />,
});
const MyProfile = loadable(() => import('./pages/MyProfile'), {
    fallback: <LoadingProgress />,
});
const AccountSettings = loadable(() => import('./pages/AccountSettings'), {
    fallback: <LoadingProgress />,
});

const CreateAndEditRole = loadable(
    () => import('./components/admins_and_roles/CreateAndEditRole'),
    {
        fallback: <LoadingProgress />,
    },
);

const Session = loadable(() => import('./components/remote_mirror/Session'), {
    fallback: <LoadingProgress />,
});

const SystemRole = loadable(() => import('./components/admins_and_roles/SystemRole'), {
    fallback: <LoadingProgress />,
});

const RepoView = loadable(() => import('./components/enterprise_apps/VersionRepo/RepoView'), {
    fallback: <LoadingProgress />,
});

let routes = [
    {
        permissionName: PermissionName.COMMON,
        path: process.env.PUBLIC_URL + '/login',
        exact: true,
        component: Login,
    },
    {
        permissionName: PermissionName.COMMON,
        path: process.env.PUBLIC_URL + '/config',
        exact: true,
        component: AccountConfig,
    },

    {
        permissionName: PermissionName.MASTERPROFILES,
        path: process.env.PUBLIC_URL + '/profiles',
        exact: true,
        component: MasterProfiles,
    },

    {
        permissionName: PermissionName.MASTERPROFILES,
        path: process.env.PUBLIC_URL + '/profiles/new',
        component: NewMasterProfile,
    },

    {
        permissionName: PermissionName.MASTERPROFILES,
        path: process.env.PUBLIC_URL + '/profiles/:id/edit',
        component: NewMasterProfile,
    },

    {
        permissionName: PermissionName.USERS,
        path: process.env.PUBLIC_URL + '/users',
        exact: true,
        component: Users,
    },

    {
        permissionName: PermissionName.APPLICATIONS,
        path: process.env.PUBLIC_URL + '/apps',
        exact: true,
        component: Apps,
    },
    {
        permissionName: PermissionName.APPLICATIONS,
        path: process.env.PUBLIC_URL + '/apps/:id',
        exact: true,
        component: RepoView,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports',
        exact: true,
        component: Reports,
    },

    {
        permissionName: PermissionName.REMOTECAST,
        path: process.env.PUBLIC_URL + '/remote_mirror',
        exact: true,
        component: RemoteMirror,
    },
    {
        permissionName: PermissionName.REMOTECAST,
        path: process.env.PUBLIC_URL + '/remote_mirror/:device_id',
        exact: true,
        component: Session,
    },
    {
        permissionName: PermissionName.ADMINSANDROLES,
        path: process.env.PUBLIC_URL + '/admins',
        exact: true,
        component: Admins,
    },
    {
        permissionName: PermissionName.ADMINSANDROLES,
        path: process.env.PUBLIC_URL + '/roles/new',
        exact: true,
        component: CreateAndEditRole,
    },
    {
        permissionName: PermissionName.ADMINSANDROLES,
        path: process.env.PUBLIC_URL + '/roles/:id',
        exact: true,
        component: SystemRole,
    },
    {
        permissionName: PermissionName.ADMINSANDROLES,
        path: process.env.PUBLIC_URL + '/roles/:id/edit',
        exact: true,
        component: CreateAndEditRole,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/account_activities',
        exact: true,
        component: AccountActivityReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/user_inventory',
        exact: true,
        // component: UserInventoryReport,
        component: UserInventoryReportNew,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/subscriptions',
        exact: true,
        component: SubscriptionReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/application_version',
        exact: true,
        component: ApplicationVersionReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/app_usage',
        exact: true,
        component: AppUsageReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/app_category_mismatch',
        exact: true,
        component: ScreenTimeReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/data_ingestion',
        exact: true,
        component: DataIngestionReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/bulk_action_status',
        exact: true,
        component: BulkActionStatusReport,
    },
    {
        permissionName: PermissionName.REPORTS,
        path: process.env.PUBLIC_URL + '/reports/app_category_mismatch',
        exact: true,
        component: ScreenTimeReport,
    },
    {
        permissionName: PermissionName.COMMON,
        path: process.env.PUBLIC_URL + '/my-profile',
        exact: true,
        component: MyProfile,
    },
    {
        permissionName: PermissionName.COMMON,
        path: process.env.PUBLIC_URL + '/account-settings',
        exact: true,
        component: AccountSettings,
    },
];

export default routes;
