import React, { useContext, useRef, useState } from 'react';
import 'date-fns';
import {
    Box,
    Paper,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import usePermissionsHook from 'Hooks/usePermissionHook';
import List from 'components/utils/List';
import { Pagination } from '@material-ui/lab';
import CopyToClipboard from '../CopyToClipboard';
import CustomTooltip from '../CustomTooltip';

const PERMISSION_SLUG = 'Byjus/Reports';

const useStyles = makeStyles(theme => ({
    flexRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px 0 24px',
        background: '#9baaba',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },
    headerItem: {
        fontWeight: 500,
        alignItems: 'center',
        padding: '18px 0',
        color: '#fff',
    },
    icon: {
        fontSize: 13,
    },
    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
    pannedDetails: {
        borderTop: '1px solid #ddd',
    },
    userDetails: {
        width: '100%',
    },
    noReportsFound: {
        marginTop: 20,
        textAlign: 'center',
    },
    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const SubscriptionUsageInfo = props => {
    const classes = useStyles();
    const { allowed } = usePermissionsHook(PERMISSION_SLUG);
    const { page, setPage, totalPages, filterClicked, selectedRegion, subscriptionUsageInfo } =
        props;

    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div>
            <SubscriptionHeader />
            {selectedRegion.length > 0 && filterClicked ? (
                <>
                    {subscriptionUsageInfo && subscriptionUsageInfo.length > 0 ? (
                        <Box>
                            {subscriptionUsageInfo.map((usageInfo, index) => (
                                <Accordion
                                    key={index}
                                    classes={{ root: classes.expansionPannelRoot }}
                                    expanded={expanded === usageInfo.serial_no}
                                    onChange={handleChange(usageInfo.serial_no)}>
                                    <AccordionSummary
                                        expandIcon={<CustomTooltip variable={expanded} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Box display="flex" alignItems="center" width="100%">
                                            <Box flex="2.5">
                                                <Link
                                                    underline="always"
                                                    target="_blank"
                                                    // rel="noopener noreferrer"
                                                    className={classes.link}
                                                    href={
                                                        process.env.PUBLIC_URL +
                                                        `/users?q=${usageInfo.serial_no}`
                                                    }>
                                                    {usageInfo.serial_no}
                                                </Link>
                                                <CopyToClipboard text={usageInfo.serial_no} />
                                            </Box>
                                            <Box flex="2">{usageInfo.total_users}</Box>
                                            <Box flex="3">{usageInfo.trial_end_date}</Box>
                                            <Box flex="3">{usageInfo.subscription_end_date}</Box>
                                            <Box flex="2.4">{usageInfo.month_consumed}</Box>
                                            <Box flex="1.6">
                                                {usageInfo.region ? usageInfo.region : 'NA'}
                                            </Box>
                                            <Box flex="0.5">{usageInfo.current_status}</Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.pannedDetails}>
                                        <Box className={classes.userDetails}>
                                            <UserDetailsHeader users={usageInfo.users} />
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            <Box className={classes.paginationSection}>
                                <Pagination
                                    color="primary"
                                    count={totalPages}
                                    page={page}
                                    variant="outlined"
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    onChange={handlePageChange}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Typography className={classes.noReportsFound}>
                            <strong> No Reports Found!! </strong>
                        </Typography>
                    )}
                </>
            ) : (
                <Typography className={classes.noReportsFound}>
                    <strong>
                        {' '}
                        Please select a region and choose the filters as required to fetch the
                        records list.{' '}
                    </strong>
                </Typography>
            )}
        </div>
    );
};

const SubscriptionHeader = props => {
    const classes = useStyles();
    return (
        <Paper className={classes.flexRoot}>
            <Box className={classes.headerItem}>Serial No</Box>
            <Box className={classes.headerItem}>Total Users</Box>
            <Box className={classes.headerItem}>Trial Subscription End date</Box>
            <Box className={classes.headerItem}>Subscription End date</Box>
            <Box className={classes.headerItem}>Subscription Months</Box>
            <Box className={classes.headerItem}>Region</Box>
            <Box className={classes.headerItem}>Current Status</Box>
        </Paper>
    );
};

const UserDetailsHeader = props => {
    const classes = useStyles();
    const { users } = props;
    const headers = ['Id', 'User type', 'Enrollment Date', 'Expiry', 'Impact On Pool'];

    return (
        <List
            firstColAsUsersLink={true}
            listHeaders={headers}
            checkboxedList={false}
            rows={users}
        />
    );
};
export default SubscriptionUsageInfo;
