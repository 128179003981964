import React from 'react';
const AESETUP = 'Byjus/AESetup';
const DEVELOPERAPIKEY = 'Byjus/DeveloperAPIKey';
const MASTERPROFILES = 'Byjus/MasterProfiles';
const USERS = 'Byjus/Users';
const APPLICATIONS = 'Byjus/Applications';
const ADMINSANDROLES = 'Byjus/AdminsAndRoles';
const REPORTS = 'Byjus/Reports';
const COMMON = 'common';
const ACTIVITYLOG = 'Byjus/ActivityLog';
const REMOTECAST = 'Byjus/RemoteCast';
const CONFIGUREEXTRASTORAGE = 'Byjus/ConfigureExtraStorage';

export default {
    AESETUP,
    DEVELOPERAPIKEY,
    MASTERPROFILES,
    USERS,
    APPLICATIONS,
    ADMINSANDROLES,
    COMMON,
    REPORTS,
    ACTIVITYLOG,
    REMOTECAST,
    CONFIGUREEXTRASTORAGE,
};
