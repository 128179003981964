import http from 'core/http';
import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Grid,
    Select,
    MenuItem,
    FormControl,
    Input,
    Chip,
    TextField,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    InputLabel,
} from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Heading from 'components/header/Heading';
import { ArrowForward, FilterList } from '@material-ui/icons';
import DownloadCsvButton from './DownloadCsvButton';
import UserInventoryList from './UserInventoryList';
import LoadingProgress from 'components/progress/LoadingProgress';
import DateRange from './DateRange';
import { Autocomplete } from '@material-ui/lab';
import { setHours } from 'date-fns/esm';
import clsx from 'clsx';
import UserInventoryJyotiAdvanceSearch from './UserInventoryAdvanceSearch';
import UserInventoryAdvanceSearch from './UserInventoryAdvanceSearch';
import { cloneDeep } from 'lodash';
import { UserContext } from 'components/providers/UserProvider';
import { SHOW_TOAST } from 'components/providers/actions';
import UserInventoryReportUploadCSVButton from './UserInventoryReportUploadCSVButton';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            // outlined: {
            //     transform: 'translate(21px, 14px) scale(1)',
            // },
            outlined: {
                transform: 'translate(14px, 10px) scale(0.75)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '13px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    filters: {
        background: 'white',
        display: 'flex',
        padding: '10',
        borderRadius: '4px',
    },
    select: {
        marginTop: '16px',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        minWidth: 150,
        maxWidth: 300,
        marginTop: '-4px',
    },
    tagsBorder: {
        border: '1px solid #ced4da',
        borderRadius: '4px',
        padding: '0 10px',
        backgroundColor: '#fff',
        marginTop: '10px',
    },
    loading: {
        position: 'absolute',
        marginTop: '5%',
    },
    tags_filter: {
        width: '250px',
        marginTop: '16px',
    },
    totalUsers: {
        margin: '14px 0 !important',
        borderRadius: '4px',
        boxShadow:
            '0px 0px 1px rgb(0 0 0 / 4%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 10px 20px rgb(0 0 0 / 4%)',
        padding: '20px !important',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const UserInventoryReport = props => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userStatus, setUserStatus] = useState([
        'all',
        'registered',
        'enrolled_active',
        'enrolled_deleted',
    ]);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [totalPages, setTotalPages] = useState(0);
    const [fromDate, setFromDate] = React.useState();
    const [toDate, setToDate] = React.useState();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [hideClearButton, setHideClearButton] = useState(true);
    const [allTags, setAllTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [excludeTags, setExcludeTags] = useState(false);

    const [filteredUsersCount, setFilteredUsersCount] = useState(0);
    const [isUsersListLoaded, setIsUsersListLoaded] = useState(false);
    const [clearButtonClick, setClearButtonClick] = useState(false);

    useEffect(() => {
        const { from, to } = setDateRange();
        setFromDate(from);
        setToDate(to);
        fetchAllTags();
        getAdvancedSearchAPI();
    }, []);

    useEffect(() => {
        if (!isUsersListLoaded) fetchUsers();
    }, [fromDate, toDate, isUsersListLoaded]);

    useEffect(() => {
        if (clearButtonClick) fetchUsers(true);
    }, [clearButtonClick]);

    function fetchAllTags() {
        async function fetchData() {
            const response = await http.get('/api/v3/tags.json');
            setAllTags(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    }

    const fetchUsers = (refreshList = false) => {
        const data = formatData(refreshList);
        if (data.params.from_date == undefined && data.params.to_date == undefined) return;
        const updateList = refreshList;
        setIsLoading(true);
        if (updateList) {
            setPage(1);
        }
        http.get(`/api/v3/users.json`, formatData())
            .then(response => {
                setUsers(response.data.device_users);
                setTotalUserCount(response.data.total_users_count);
                setTotalPages(response.data.total_pages);
                setFilteredUsersCount(response.data.total_filtered_users);
                if (!isUsersListLoaded) setIsUsersListLoaded(true);
            })
            .catch(error => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    /**
     * Format Parameter Data
     */
    const formatData = isRefreshList => {
        const { from, to } = setDateRange();
        const params = {
            params: {
                from_date: isRefreshList
                    ? from
                    : fromDate &&
                      fromDate.getFullYear() +
                          '_' +
                          (fromDate.getMonth() + 1) +
                          '_' +
                          fromDate.getDate(),
                to_date: isRefreshList
                    ? to
                    : toDate &&
                      toDate.getFullYear() + '_' + (toDate.getMonth() + 1) + '_' + toDate.getDate(),
                filter: isRefreshList ? 'all' : selectedStatus,
                per_page: isRefreshList ? 10 : rowsPerPage,
                page: isRefreshList ? 0 : page,
                tags: isRefreshList ? [] : tags,
                user_invertory_report: true,
                exclude_tags: isRefreshList ? false : excludeTags,
            },
        };
        return params;
    };

    /**
     * This function set fromDate and toDate
     */
    const setDateRange = () => {
        const currentDate = new Date();
        let _fromDate = new Date();
        _fromDate.setDate(currentDate.getDate() - 6);
        _fromDate = new Date(_fromDate.toDateString());
        return {
            from: new Date(
                _fromDate.getFullYear() +
                    '-' +
                    (_fromDate.getMonth() + 1) +
                    '-' +
                    _fromDate.getDate(),
            ),
            to: new Date(
                currentDate.getFullYear() +
                    '-' +
                    (currentDate.getMonth() + 1) +
                    '-' +
                    currentDate.getDate(),
            ),
        };
    };

    const handleFromDateChange = date => {
        setFromDate(date);
        setHideClearButton(false);
        setClearButtonClick(false);
    };

    const handleToDateChange = date => {
        setToDate(date);
        setHideClearButton(false);
        setClearButtonClick(false);
    };

    /**
     * Clear All Selected Filters
     */
    const handleClearButtonClick = () => {
        setSelectedStatus('all');
        const { from, to } = setDateRange();
        setFromDate(from);
        setToDate(to);
        setPage(0);
        setRowsPerPage(10);
        setHideClearButton(true);
        setExcludeTags(false);
        setTags([]);
        setClearButtonClick(true);
    };

    const handleUserStatusChange = event => {
        setSelectedStatus(event.target.value);
        setHideClearButton(false);
        setClearButtonClick(false);
    };

    const setRecordsPerPage = count => {
        setRowsPerPage(count);
        setHideClearButton(false);
        setClearButtonClick(false);
    };

    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    const incomingTags = allTags.map(tag => tag.name);

    const handleTagChange = newTags => {
        setClearButtonClick(false);
        var nameArray = newTags.map(function (tag) {
            return tag;
        });
        setTags(nameArray);
        setHideClearButton(false);
        if (nameArray.length == 0) {
            setExcludeTags(false);
            setHideClearButton(true);
        }
    };

    const handleExcludeTags = event => {
        setExcludeTags(event.target.checked);
        setClearButtonClick(false);
    };

    const handleSubmitBtnClick = () => {
        fetchUsers();
    };

    const getDatePickerLabel = () => {
        if (selectedStatus == 'registered') {
            return 'Registered Between';
        } else if (['enrolled_active', 'enrolled_deleted'].includes(selectedStatus)) {
            return 'Enrolled Between';
        } else {
            return 'Registered or Enrolled Between';
        }
    };

    function titleCase(key) {
        var labelStr = key.toLowerCase().split('_');
        for (var i = 0; i < labelStr.length; i++) {
            labelStr[i] = labelStr[i][0].toUpperCase() + labelStr[i].slice(1);
        }
        labelStr = labelStr.join(' ');
        return labelStr;
    }

    const [searchData, setSearchData] = useState([]);

    const getAdvancedSearchAPI = () => {
        setIsLoading(true);
        async function fetchData() {
            const response = await http.get('/api/v3/users/search_options.json');
            setSearchData(response.data.options);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Advance Search

    const [{ user }, userDispatch] = useContext(UserContext);
    const [searchBy, setSearchBy] = useState([
        {
            index: 0,
            title: '',
            operators: [],
            operator: '',
            options: [],
            // value: '',
            value: [],
            dateRange: { from: null, to: null },
            type: '',
            placeholder: '',
            queryType: 'AND',
        },
    ]);
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [totalUsersCountAdv, setTotalUsersCountAdv] = useState(0);
    const [totalPagesAdv, setTotalPagesAdv] = useState(0);
    const [filteredUsersCountAdv, setFilteredUsersCountAdv] = useState(0);
    const [totalDevicesCount, setTotalDevicesCount] = useState(0);
    // const [ rowsPerPageAdv, setRowsPerPageAdv ] = React.useState(50);
    const perPageAdv = 10;
    const [pageAdv, setPageAdv] = useState(1);
    const [clearClicked, setClearClicked] = useState(false);
    const [showTitle, setShowTitle] = useState(false);

    useEffect(() => {
        advSearchAPI();
    }, [pageAdv]);

    useEffect(() => {
        if (clearClicked) advSearchAPI();
    }, [clearClicked]);

    // Search Data Post API

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    const advancedSearchParams = () => {
        const exceptKey = function (i, filters) {
            return Object.keys(i).reduce((accumulator, key) => {
                // Copy all except ones mentioned in Keys[]
                if (!filters.includes(key)) {
                    accumulator[key] = i[key];
                }
                return accumulator;
            }, {});
        };

        let searchByClone = cloneDeep(searchBy);

        let array = [];
        searchByClone.map((i, index) => {
            let reqParams = exceptKey(i, [
                'index',
                'operators',
                'dateRange',
                'options',
                'type',
                'placeholder',
                'queryType',
            ]);
            if (index == 0) delete i.queryType;
            if (i.title != '' && i.operator != '' && i.value != '') {
                array.push({ query: reqParams, keyword: i.queryType });
            }
        });

        const params = {
            params: {
                search_by: JSON.stringify(array),
                page: pageAdv,
                per_page: perPageAdv,
            },
        };

        return params;
    };

    const advSearchAPI = () => {
        setIsLoading(true);

        http.get('/api/v3/users/search_filters.json', advancedSearchParams())
            .then(response => {
                setSearchedUsers(response.data.device_users);
                setTotalUsersCountAdv(response.data.total_users_count);
                setTotalPagesAdv(response.data.total_pages);
                setFilteredUsersCountAdv(response.data.total_filtered_users);
                setTotalDevicesCount(response.data.total_devices_count);
            })
            .catch(error => {
                showErrorToast(Object.values(error.response.data.errors).join(', '));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <Box>
                                <Button color="primary" onClick={goBack}>
                                    Reports&nbsp; <ArrowForward fontSize="small" />
                                </Button>
                                <span className={classes.reportName}>User Inventory</span>
                            </Box>
                            <Box fontSize="13px" marginLeft="8px" color="gray">
                                View and download user inventory reports from here.
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <UserInventoryReportUploadCSVButton />
                            <DownloadCsvButton
                                // csvPath="/api/v3/users.csv"
                                csvPath="/api/v3/users/search_filters.csv"
                                // parameters={formatData()}
                                parameters={advancedSearchParams()}
                                fileName="user"
                                disabled={isLoading}
                            />
                        </Box>
                    </Box>
                }
            />
            {/* <Container style={{ marginTop: '20px' }} className={classes.filters}>
                <Grid container justify="space-between" style={{ padding: '10px' }}>
                    <Box display="flex" style={{ marginTop: '18px' }}>
                        <FilterList className={classes.spaceAround} />
                        <Typography> Filter Users</Typography>
                    </Box>
                    <Box style={{ marginTop: '10px' }}>
                        <DateRange
                            fromDate={fromDate}
                            toDate={toDate}
                            handleFromDateChange={handleFromDateChange}
                            handleToDateChange={handleToDateChange}
                            datePickerLabel={getDatePickerLabel()}
                        />
                    </Box>

                    <MuiThemeProvider theme={theme}>
                        <FormControl className={classes.formControl} variant="outlined">
                            <InputLabel
                                style={{
                                    backgroundColor: 'white',
                                    paddingLeft: '10px',
                                    justifyContent: 'center !important',
                                    display: 'flex !important',
                                    paddingRight: '10px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    fontSize: 'initial',
                                    marginLeft: '35px',
                                    marginTop: '16px',
                                }}>
                                Status
                            </InputLabel>
                            <Select
                                value={selectedStatus}
                                onChange={handleUserStatusChange}
                                // input={<Input />}
                                MenuProps={MenuProps}
                                className={classes.select}>
                                {userStatus.map(status => (
                                    <MenuItem key={status} value={status}>
                                        <>
                                            {titleCase(status)} {status.includes(status)}
                                        </>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MuiThemeProvider>

                    <Box className={classes.tagsBorder}>
                        <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={incomingTags}
                            value={tags}
                            className={classes.tags_filter}
                            getOptionLabel={option => option}
                            renderTags={(value, getTagProps) =>
                                tags.map((option, index) => (
                                    <Chip
                                        key={index}
                                        variant="outlined"
                                        size="small"
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            onChange={(event, newInputValue) => {
                                handleTagChange(newInputValue);
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Search by Tags"
                                />
                            )}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={excludeTags}
                                    onChange={handleExcludeTags}
                                    disabled={tags.length == 0}
                                    color="primary"
                                />
                            }
                            label="Exclude Tags"
                        />
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={isLoading}
                            style={{ marginTop: '14px', marginBottom: '9px' }}
                            onClick={handleSubmitBtnClick}>
                            <strong>FILTER</strong>
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ marginTop: '14px', marginBottom: '9px' }}
                            className={clsx({ [classes.clearButton]: hideClearButton })}
                            onClick={handleClearButtonClick}>
                            <strong>Clear</strong>
                        </Button>
                    </Box>
                </Grid>
            </Container> */}
            <Container
                style={{ marginTop: '20px', padding: '10px', justifyContent: 'space-between' }}
                className={classes.filters}>
                <UserInventoryAdvanceSearch
                    searchBy={searchBy}
                    setSearchBy={setSearchBy}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    advSearchAPI={advSearchAPI}
                    clearClicked={clearClicked}
                    setClearClicked={setClearClicked}
                    setPage={setPageAdv}
                />
            </Container>
            <Box className={classes.totalUsers}>
                <b>{`Total Users: ${filteredUsersCountAdv}`}</b>
                <b>{`Total Device Count: ${totalDevicesCount}`}</b>
            </Box>
            {isLoading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={24} />
                </div>
            ) : (
                // Commented Props are the ones used in Old Filter Search. Rest all will remain same
                <UserInventoryList
                    // users={users}
                    users={searchedUsers}
                    // page={page}
                    page={pageAdv}
                    // setPage={setPage}
                    setPage={setPageAdv}
                    // rowsPerPage={rowsPerPage}
                    // setRecordsPerPage={setRecordsPerPage}
                    // totalRecords={totalUserCount}
                    isLoading={isLoading}
                    // totalPages={totalPages}
                    totalPages={totalPagesAdv}
                    // fetchUsers={fetchUsers}
                    fetchUsers={advSearchAPI}
                    perPageAdv={perPageAdv}
                    showTitle={showTitle}
                    setShowTitle={setShowTitle}
                />
            )}
        </Container>
    );
};

export default UserInventoryReport;
