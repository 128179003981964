import React from 'react';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '600px',
    },
}));

// Inspired by the Facebook spinners.
const useStylesFacebook = makeStyles({
    root: {
        position: 'relative',
    },
    top: {
        color: '#eef3fd',
    },
    bottom: {
        color: '#6798e5',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
});

export const FacebookProgress = props => {
    const classes = useStylesFacebook();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.bottom}
                size={24}
                thickness={4}
                {...props}
            />
        </div>
    );
};

const LoadingProgress = () => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.root}>
            <FacebookProgress />
        </Box>
    );
};

export default LoadingProgress;
