import React, { useContext, useState } from 'react';
import { Box, Button, makeStyles, Tooltip } from '@material-ui/core';
import MuiDialog from 'components/dialog/MuiDialog';
import UploadCSV from 'components/users/UploadCSV';
import DownloadIcon from '@material-ui/icons/GetApp';
import sampleCSV from 'csv/UserInventoryReportsCSVSampleUserID.csv';
import PasswordDialog from 'components/dialog/PasswordDialog';
import http from 'core/http';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const useStyles = makeStyles(theme => ({
    list: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    downloadBtn: {
        display: 'flex',
        justifyContent: 'start',
        marginLeft: 'auto',
        width: 'fit-content',
        fontSize: 13,
    },
    info: {
        color: '#FF9800',
        backgroundColor: '#FFEDD6',
        borderColor: '#FFEDD6',
        padding: '5px',
        marginTop: '20px',
        borderRadius: 3.5,
        marginBottom: 15,
    },
}));

const UserInventoryReportUserIdCSVDialog = props => {
    const classes = useStyles();
    const [{ user }, userDispatch] = useContext(UserContext);
    const { isUserIdCSVDialogOpen, closeUserIdCSVDialog, csv, setCsv, password, setPassword } =
        props;
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const showErrorToast = message => {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    };

    const showSuccessToast = message => {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    };

    // DOWNLOAD Sample CSV
    const DownloadCsv = () => {
        let linkElement = document.createElement('a');
        let path = sampleCSV;

        linkElement.setAttribute('href', path);
        linkElement.setAttribute('download', 'SampleSerialNumber.csv');
        var clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: false,
            view: window,
        });
        linkElement.dispatchEvent(clickEvent);
    };

    const DialogHeader = () => {
        return (
            <Box display="flex" alignItems="center">
                <Box>Download User ID CSV</Box>
                <Button
                    variant="contained"
                    id="sample-csv-download-button"
                    color="primary"
                    className={classes.downloadBtn}
                    onClick={DownloadCsv}>
                    <DownloadIcon className={classes.buttonIcon} />
                    Download Sample Csv
                </Button>
            </Box>
        );
    };

    const showPasswordDialog = () => {
        setPasswordDialogOpen(true);
    };

    const uploadUserIdCSVApi = async (file, password) => {
        const _data = new FormData();
        _data.append('file', file);
        _data.append('password', password);

        try {
            const { data } = await http.post(`/api/v3/users/download_device_users`, _data);
            return data;
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    // API to Upload CSV using USER ID gets Called here :
    const uploadUserIdCSV = () => {
        setLoading(true);
        uploadUserIdCSVApi(csv, password)
            .then(response => {
                if (response.isAxiosError) {
                    if (response.response.status == 401) {
                        showErrorToast(true, 'Unauthorized!.', 'error');
                        setPasswordDialogOpen(false);
                        closeUserIdCSVDialog();
                    } else {
                        showErrorToast(response.response.data.errors);
                        setPasswordDialogOpen(false);
                        closeUserIdCSVDialog();
                    }
                } else {
                    setPasswordDialogOpen(false);
                    closeUserIdCSVDialog();
                    showSuccessToast(
                        'Upload CSV Action is successful. Please visit Reports > Bulk Action Status to Download User Id CSV',
                    );
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {isUserIdCSVDialogOpen && (
                <Tooltip title="">
                    <MuiDialog
                        open={isUserIdCSVDialogOpen}
                        onAcceptText={'UPLOAD'}
                        onAccept={showPasswordDialog}
                        onReject={closeUserIdCSVDialog}
                        onCloseText={'CANCEL'}
                        title={<DialogHeader />}
                        disableAccept={csv.length <= 0}
                        fullWidth={true}>
                        <Box className={classes.info}>
                            <ol>
                                <li className={classes.list}>
                                    Please upload a CSV File that contains Device Serial Number
                                    only.
                                </li>
                                <li className={classes.list}>
                                    You can download the sample CSV File from above for reference.
                                </li>
                            </ol>
                        </Box>
                        <UploadCSV csv={csv} setCsv={setCsv} />
                    </MuiDialog>
                </Tooltip>
            )}
            <PasswordDialog
                password={password}
                setPassword={setPassword}
                openPasswordDialog={passwordDialogOpen}
                setOpenPasswordDialog={setPasswordDialogOpen}
                handleAccept={uploadUserIdCSV}
                title="Are you sure ?"
                loading={loading}
                dialogText={
                    'This will upload the selected CSV File for the serial numbers. Please enter the password below and confirm this action.'
                }
                acceptBtnText="Upload"
            />
        </>
    );
};

export default UserInventoryReportUserIdCSVDialog;
