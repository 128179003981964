import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    createMuiTheme,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    MuiThemeProvider,
    Radio,
    Select,
    Typography,
} from '@material-ui/core';
import { ArrowDropDown, FilterList } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import DateRange from '../DateRange';
import SelectAdminDialog from './SelectAdminDialog';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(21px, 14px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '13px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    filters: {
        background: 'white',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        padding: 10,
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    formControl: {
        minWidth: 135,
        maxWidth: 300,
        '& #activity-type-label': {
            fontWeight: 500,
        },
    },

    label: {
        visibility: 'hidden',
    },
    selectUserButton: {
        color: 'grey',
        width: '100%',
        borderRadius: 4,
        paddingTop: 5,
        marginTop: 10,
    },
    selectUserDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '4%',
        paddingBottom: 2,
    },
    paper: {
        width: 'max-content',
        maxHeight: 435,
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        marginTop: '15px',
        marginBottom: '8px',
        justifySelf: 'center',
        alignSelf: 'center',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ActivityMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 336,
        },
    },
};

const BulkActionStatusFilter = props => {
    const classes = useStyles();
    const {
        activityTypes,
        jobStatus,
        users,
        filters,
        setFilters,
        getFromDate,
        getToDate,
        setPage,
        selectedUserEmail,
        setSelectedUserEmail,
        fetchBackgroundJobDeatils,
    } = props;

    const [clearClicked, setClearClicked] = useState(false);
    const [hideClearButton, setHideClearButton] = useState(true);

    useEffect(() => {
        if (clearClicked) fetchBackgroundJobDeatils(true);
    }, [clearClicked]);

    const handleFromDateChange = date => {
        setFilters({ ...filters, ['from_date']: date });
        setClearClicked(false);
        setHideClearButton(false);
    };

    const handleToDateChange = date => {
        setFilters({ ...filters, ['to_date']: date });
        setClearClicked(false);
        setHideClearButton(false);
    };

    const clearFilterClick = () => {
        setClearClicked(true);
        setFilters({
            from_date: getFromDate(),
            to_date: getToDate(),
            selectedActivityType: '',
            selectedJobStatus: '',
        });
        setSelectedUserEmail([]);
        setPage(1);
        setHideClearButton(true);
    };

    const handleSubmitClick = () => {
        fetchBackgroundJobDeatils(true);
    };

    return (
        <Container className={classes.filters}>
            <Grid className={classes.spaceBetween}>
                <Box display="flex" style={{ marginTop: '18px' }}>
                    <FilterList className={classes.spaceAround} />
                    <Typography> Filter Activities </Typography>
                </Box>
                <Box style={{ marginTop: '10px' }}>
                    <DateRange
                        fromDate={filters.from_date}
                        toDate={filters.to_date}
                        handleFromDateChange={handleFromDateChange}
                        handleToDateChange={handleToDateChange}
                        datePickerLabel="Activities Between"
                    />
                </Box>

                <ActivityType
                    filters={filters}
                    setFilters={setFilters}
                    activityTypes={activityTypes}
                    setClearClicked={setClearClicked}
                    setHideClearButton={setHideClearButton}
                />

                <BackgroundJobStatus
                    filters={filters}
                    setFilters={setFilters}
                    jobStatus={jobStatus}
                    setClearClicked={setClearClicked}
                    setHideClearButton={setHideClearButton}
                />

                <SelectAdmin
                    users={users}
                    selectedUserEmail={selectedUserEmail}
                    setSelectedUserEmail={setSelectedUserEmail}
                    setClearClicked={setClearClicked}
                    setHideClearButton={setHideClearButton}
                />

                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        className={clsx(classes.button, {
                            [classes.clearButton]: hideClearButton,
                        })}
                        onClick={clearFilterClick}>
                        <strong>Clear</strong>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={
                            filters.from_date == 'Invalid Date' ||
                            filters.from_date == null ||
                            filters.to_date == 'Invalid Date' ||
                            filters.to_date == null
                        }
                        style={{ marginTop: '15px', marginBottom: '8px', marginLeft: '10px' }}
                        onClick={handleSubmitClick}>
                        <strong>FILTER</strong>
                    </Button>
                </Box>
            </Grid>
        </Container>
    );
};

const ActivityType = props => {
    const classes = useStyles();
    const { activityTypes, setClearClicked, setHideClearButton, filters, setFilters } = props;

    const handleActivityChange = event => {
        setFilters({ ...filters, ['selectedActivityType']: event.target.value });
        setClearClicked(false);
        setHideClearButton(false);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ marginTop: '11px' }}>
                <InputLabel
                    shrink={false}
                    className={clsx({ [classes.label]: filters.selectedActivityType.length !== 0 })}
                    id="activity-type-label">
                    ACTIVITY
                </InputLabel>
                <Select
                    value={filters.selectedActivityType}
                    onChange={handleActivityChange}
                    renderValue={selected => selected}
                    MenuProps={ActivityMenuProps}>
                    {activityTypes.map(activityType => (
                        <MenuItem key={activityType.key} value={activityType.value}>
                            <Radio
                                checked={filters.selectedActivityType == activityType.key}
                                color="primary"
                            />
                            <ListItemText primary={activityType.value} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </MuiThemeProvider>
    );
};

const BackgroundJobStatus = props => {
    const classes = useStyles();
    const { filters, setFilters, jobStatus, setClearClicked, setHideClearButton } = props;

    const handleJobStatusChange = event => {
        setFilters({ ...filters, ['selectedJobStatus']: event.target.value });
        setClearClicked(false);
        setHideClearButton(false);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ marginTop: '11px' }}>
                <InputLabel
                    shrink={false}
                    className={clsx({ [classes.label]: filters.selectedJobStatus.length !== 0 })}
                    id="job-status-label">
                    JOB STATUS
                </InputLabel>
                <Select
                    value={filters.selectedJobStatus}
                    onChange={handleJobStatusChange}
                    renderValue={selected => selected}
                    MenuProps={MenuProps}>
                    {jobStatus.map(status => (
                        <MenuItem key={status.key} value={status.key}>
                            <ListItemText primary={status.key} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </MuiThemeProvider>
    );
};

const SelectAdmin = props => {
    const classes = useStyles();
    const { users, selectedUserEmail, setSelectedUserEmail, setClearClicked, setHideClearButton } =
        props;

    const [open, setOpen] = useState(false);

    /**
     * Toggle MultiUser Select Dialog
     * @param {*} value dialog state
     */

    const toggleSeclectAdminDialog = value => {
        setOpen(value);
    };

    const handleUserEmailChange = selectedUserEmails => {
        setSelectedUserEmail(selectedUserEmails);
        setClearClicked(false);
        if (selectedUserEmails.length != 0) {
            setHideClearButton(false);
        }
    };

    return (
        <>
            <FormControl className={classes.formControl}>
                <Button
                    variant="outlined"
                    className={classes.selectUserButton}
                    onClick={e => toggleSeclectAdminDialog(true)}>
                    <div className={classes.selectUserDiv}>
                        {selectedUserEmail.length == 0
                            ? `SELECT ADMIN`
                            : `SELECTED ADMIN(s) ${selectedUserEmail.length}`}
                        <ArrowDropDown />
                    </div>
                </Button>
            </FormControl>
            <SelectAdminDialog
                classes={{
                    paper: classes.paper,
                }}
                id="userselect-menu"
                keepMounted
                open={open}
                options={users}
                onClose={toggleSeclectAdminDialog}
                selectedUserEmail={selectedUserEmail}
                setSelectedUserEmail={setSelectedUserEmail}
                handleUserEmailChange={handleUserEmailChange}
            />
        </>
    );
};

export default BulkActionStatusFilter;
