import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { UserContext } from 'components/providers/UserProvider';
/**
 * A custom hook which
 * Fetches User permissions assigned by the admin
 * @returns {*} allowedUpdate, readOnly
 */

const usePermissionsHook = props => {
    const [allowedUpdate, setAllowedUpdate] = useState(false);
    const [allowed, setAllowed] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const [{ permissions, user }] = useContext(UserContext);

    useEffect(() => {
        setAllowedUpdate(isAllowedUpdate(props));
        setReadOnly(isReadOnly(props));
        setAllowed(isAllowed(props));
    });

    /**
     * @returns permission from permissions
     * @param {*} slug: permission slug
     */
    function getPermission(slug) {
        return permissions.find(p => p.slug == slug);
    }

    /**
     * @param {*} permissionSlug - name of permission
     * @returns weather the permission is allowed or not
     */
    function isAllowedUpdate(permissionSlug) {
        if (user.is_owner) {
            return true;
        }
        let perm = getPermission(permissionSlug);
        if (perm) {
            return perm.allowed && !perm.read_only;
        }

        return false;
    }
    function isAllowed(permissionSlug) {
        if (user.is_owner) {
            return true;
        }
        let perm = getPermission(permissionSlug);
        if (perm) {
            return perm.allowed;
        }

        return false;
    }
    function isReadOnly(permissionSlug) {
        if (user.is_owner) {
            return false;
        }
        let perm = getPermission(permissionSlug);
        if (perm) return perm.read_only;
    }

    return { allowed, allowedUpdate, readOnly };
};

export default usePermissionsHook;
