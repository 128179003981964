import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { mergeClasses } from '@material-ui/styles';

const theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                marginLeft: '-15px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    dateRangeLabelBox: {},
}));

const DateRange = props => {
    const {
        fromDate,
        toDate,
        handleFromDateChange,
        handleToDateChange,
        disableFilter,
        datePickerLabel,
    } = props;
    const classes = useStyles();

    return (
        <Box border="1px solid #ced4da" borderRadius="4px" marginBottom="auto">
            <Box
                className={classes.dateRangeLabelBox}
                display="flex"
                justifyContent="center"
                marginLeft="auto"
                marginRight="auto"
                width="max-content"
                paddingLeft="10px"
                paddingRight="10px"
                style={{ background: 'white', marginTop: '-10px' }}>
                {' '}
                {datePickerLabel}{' '}
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box textAlign="center" marginBottom="5px">
                    <MuiThemeProvider theme={theme}>
                        <KeyboardDatePicker
                            style={{ width: '122px', marginTop: '0px' }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="inline"
                            autoOk={true}
                            format="dd/MM/yyyy"
                            disabled={disableFilter}
                            id="date-picker-inline"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            disableFuture={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            InputAdornmentProps={{ position: 'start' }}
                        />

                        <KeyboardDatePicker
                            style={{ width: '122px', marginTop: '0px' }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            variant="inline"
                            autoOk={true}
                            minDate={fromDate}
                            format="dd/MM/yyyy"
                            disabled={disableFilter}
                            id="date-picker-inline"
                            value={toDate}
                            onChange={handleToDateChange}
                            disableFuture={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            InputAdornmentProps={{ position: 'start' }}
                        />
                    </MuiThemeProvider>
                </Box>
            </MuiPickersUtilsProvider>
        </Box>
    );
};

export default DateRange;
