/**
 * Custom theme for Scalefusion
 */

import { createMuiTheme } from '@material-ui/core/styles';

/**
 * Customize theme options
 */
const newTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#6772e5', //'#5533FF'
        },

        secondary: {
            main: '#f44336',
        },

        text: {
            white: '#fff',
        },

        background: {
            default: '#e3e8ee',
        },
    },

    typography: {
        // fontSize: 14,
        // htmlFontSize: 16,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },

    // Override styles using CSS API
    overrides: {
        MuiButton: {
            root: {
                // textTransform: 'none',
                borderRadius: 2,
                fontSize: 13,
            },
        },

        MuiOutlinedInput: {
            root: {
                borderRadius: 2,
            },
        },

        // MuiTab: {
        //     wrapper: {
        //         flexDirection:'row',
        //     },
        // },
    },
});

export default newTheme;
