import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core/';
import Pagination from '@material-ui/lab/Pagination';
import CopyToClipboard from './CopyToClipboard';
import CustomTooltip from './CustomTooltip';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },

    noUserFound: {
        marginTop: '5%',
        textAlign: 'center',
    },

    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },

    flexRoot: {
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        background: '#9baaba',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },

    headerItem: {
        fontWeight: 700,
        alignItems: 'center',
        padding: '18px 0',
    },

    expansionPannelRoot: {
        borderRadius: '4px',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        '&:before': {
            backgroundColor: 'transparent',
        },
    },
}));

const UserInventoryList = props => {
    const classes = useStyles();
    const { users, page, setPage, totalPages, fetchUsers } = props;

    const [expanded, setExpanded] = useState(false);

    // For Expand
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // fetchUsers(false);
    };

    return (
        <>
            {users.length > 0 ? (
                <>
                    <Box className={classes.flexRoot}>
                        <Box className={classes.headerItem} ml="15px">
                            User ID
                        </Box>
                        {/* Uncomment the below line & change the "ml" after that for mumdm-1045 changes which were accidentally pushed to Prod before requirement */}
                        <Box className={classes.headerItem} ml="290px">
                            Region
                        </Box>
                        {/* Change to ml="120px" */}
                        <Box className={classes.headerItem} ml="130px">
                            Registered On{' '}
                        </Box>
                        {/* Change to ml="160px" */}
                        <Box className={classes.headerItem} ml="130px">
                            Master Profile
                        </Box>
                        {/* Change to ml="90px" */}
                        <Box className={classes.headerItem} ml="115px">
                            Primary Device
                        </Box>
                        {/* Change to ml="30px" */}
                        <Box className={classes.headerItem} ml="30px">
                            Secondary Device
                        </Box>
                        <Box className={classes.headerItem}></Box>
                    </Box>
                    {users.map(user => (
                        <Accordion
                            key={user.id}
                            classes={{ root: classes.expansionPannelRoot }}
                            expanded={expanded === user.id}
                            onChange={handleChange(user.id)}>
                            <AccordionSummary
                                expandIcon={<CustomTooltip variable={expanded} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header">
                                <Box display="flex" alignItems="center" width="100%">
                                    <Box flex="5">
                                        <Link
                                            underline="always"
                                            target="_blank"
                                            // rel="noopener noreferrer"
                                            className={classes.link}
                                            href={process.env.PUBLIC_URL + `/users?q=${user.id}`}>
                                            {user.id}
                                        </Link>
                                        <CopyToClipboard text={user.id} />
                                    </Box>
                                    {/* Uncomment the below line for mumdm-1045 changes which were accidentally pushed to Prod before requirement */}
                                    <Box flex="2">{user.region}</Box>
                                    <Box flex="3.5">{user.enrolled_on || user.registered_on}</Box>
                                    <Box flex="3.5">{user.master_profile.name}</Box>
                                    <Box flex="2">{user.primary_user_device_count || 0}</Box>
                                    <Box flex="1">{user.secondry_user_device_count || 0}</Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                {user.devices.length > 0 ? (
                                    <Box width="100%">
                                        <TableContainer>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <strong>Serial No.</strong>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <strong>LOS MDM Version</strong>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <strong>OS Version</strong>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {user.devices.map(device => (
                                                        <TableRow key={device.id}>
                                                            <TableCell>
                                                                {device.serial_no}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {device.los_client_version_name}(
                                                                {device.los_client_version_code})
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {device.os_version}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                ) : (
                                    <Typography>User Not Enrolled!!</Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    {users.length > 0 && (
                        <Box className={classes.paginationSection}>
                            <Pagination
                                color="primary"
                                count={totalPages}
                                page={page}
                                variant="outlined"
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                onChange={handleChangePage}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <Typography className={classes.noUserFound}>
                    <strong> No Users Found!! </strong>
                </Typography>
            )}
        </>
    );
};

export default UserInventoryList;
