import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { defaults } from 'react-chartjs-2';
import { Bar, Line, Pie } from 'react-chartjs-2';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    filters: {
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
    },
    select: {
        marginTop: '16px',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        width: '30%',
        marginRight: 15,
    },

    dateControl: {
        width: '60%',
        marginRight: 15,
    },

    loading: {
        position: 'absolute',
        marginTop: '5%',
    },
    searchField: {
        margin: 0,
    },
    tags_filter: {
        width: '250px',
        marginTop: '16px',
    },

    actionButton: {
        marginLeft: 'auto',
        marginRight: 15,
    },

    topContainer: {
        width: 'calc(100% - 300px)',
        marginLeft: 20,
    },

    disabled: {
        pointerEvents: 'none',
        opacity: 0.6,
    },

    noDataFound: {
        textAlign: 'center',
        marginTop: '5%',
    },

    centeredProgress: {
        height: 600,
        position: 'relative',
        top: '40%',
    },
}));

const TotalAppUsage = props => {
    const classes = useStyles();
    const { loading, noDataMessage, totalAppUsageData } = props;

    const [data, setData] = useState({});
    const [options, setOptions] = useState({});

    const chartColors = [
        '#E57373',
        '#F06292',
        '#BA68C8',
        '#9575CD',
        '#7986CB',
        '#64B5F6',
        '#4FC3F7',
        '#4DD0E1',
        '#4DB6AC',
        '#81C784',
        '#AED581',
        '#FFF176',
        '#FFD54F',
        '#FFB74D',
        '#FF8A65',
        '#A1887F',
        '#90A4AE',
    ];

    const dynamicColors = () => {
        // let r = Math.floor(Math.random() * 255);
        // let g = Math.floor(Math.random() * 255);
        // let b = Math.floor(Math.random() * 255);
        // return 'rgb(' + r + ',' + g + ',' + b + ')';
        return _.sample(chartColors);
    };

    useEffect(() => {
        if (totalAppUsageData.length > 0) {
            setData({
                datasets: [
                    {
                        data: totalAppUsageData.map(data => data.foreground_usage),
                        backgroundColor: totalAppUsageData.map(data => dynamicColors()),
                    },
                ],
                labels: totalAppUsageData.map(data => data.name),
            });

            setOptions({
                tooltips: {
                    callbacks: {
                        title: function (t, d) {
                            return d.labels[t[0].index];
                        },
                        label: function (t, d) {
                            return totalAppUsageData[t.index].formatted_foreground_usage;
                        },
                    },
                },
                legend: {
                    position: 'left',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 8,
                    },
                },
                responsive: true,
            });
        }
    }, [totalAppUsageData]);

    return (
        <Box style={{ marginTop: '20px' }}>
            {totalAppUsageData.length > 0 ? (
                <Box>
                    <Pie data={data} options={options} />
                </Box>
            ) : (
                <Box className={classes.noDataFound}>
                    {loading && (
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.centeredProgress}
                        />
                    )}
                    <Typography>
                        <strong> {noDataMessage} </strong>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default TotalAppUsage;
