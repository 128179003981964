/**
 * Heading component
 */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

/**
 * Heading view
 * @param {Object} props
 */
const Heading = props => {
    const { title, subtitle, titleVariant = 'h6', bodyVariant = 'body2', ...others } = props;
    return (
        <React.Fragment>
            <Typography variant={titleVariant} {...others}>
                {title}
            </Typography>
            <Typography variant={bodyVariant} color="textSecondary" gutterBottom>
                {subtitle}
            </Typography>
        </React.Fragment>
    );
};

Heading.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subtitle: PropTypes.string,
    titleVariant: PropTypes.string,
    bodyVariant: PropTypes.string,
};

export default Heading;
