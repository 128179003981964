import http from 'core/http';
import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Heading from 'components/header/Heading';
import { ArrowForward } from '@material-ui/icons';
import DownloadCsvButton from './DownloadCsvButton';
import UserInventoryList from './UserInventoryList';
import UserInventoryAdvanceSearch from './UserInventoryAdvanceSearch';
import { cloneDeep } from 'lodash';
import { UserContext } from 'components/providers/UserProvider';
import { SHOW_TOAST } from 'components/providers/actions';
import UserInventoryReportUploadCSVButton from './UserInventoryReportUploadCSVButton';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    filters: {
        background: 'white',
        display: 'flex',
        padding: '10',
        borderRadius: '4px',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    totalUsers: {
        margin: '14px 0 !important',
        borderRadius: '4px',
        boxShadow:
            '0px 0px 1px rgb(0 0 0 / 4%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 10px 20px rgb(0 0 0 / 4%)',
        padding: '20px !important',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
}));

const UserInventoryReportNew = props => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAdvancedSearchAPI();
    }, []);

    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    const [searchData, setSearchData] = useState([]);

    const getAdvancedSearchAPI = () => {
        async function fetchData() {
            const response = await http.get('/api/v3/users/search_options.json');
            setSearchData(response.data.options);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            });
    };

    // Advance Search

    const [{ user }, userDispatch] = useContext(UserContext);
    const [searchBy, setSearchBy] = useState([
        {
            index: 0,
            title: '',
            operators: [],
            operator: '',
            options: [],
            value: [],
            dateRange: { from: null, to: null },
            type: '',
            placeholder: '',
            queryType: 'AND',
        },
    ]);
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [totalUsersCountAdv, setTotalUsersCountAdv] = useState(0);
    const [totalPagesAdv, setTotalPagesAdv] = useState(0);
    const [filteredUsersCountAdv, setFilteredUsersCountAdv] = useState(0);
    const [totalDevicesCount, setTotalDevicesCount] = useState(0);
    const perPageAdv = 10;
    const [pageAdv, setPageAdv] = useState(1);
    const [clearClicked, setClearClicked] = useState(false);
    const [showTitle, setShowTitle] = useState(false);

    useEffect(() => {
        advSearchAPI();
    }, [pageAdv]);

    useEffect(() => {
        if (clearClicked) advSearchAPI();
    }, [clearClicked]);

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    const advancedSearchParams = () => {
        const exceptKey = function (i, filters) {
            return Object.keys(i).reduce((accumulator, key) => {
                // Copy all except ones mentioned in Keys[]
                if (!filters.includes(key)) {
                    accumulator[key] = i[key];
                }
                return accumulator;
            }, {});
        };

        let searchByClone = cloneDeep(searchBy);

        let array = [];
        searchByClone.map((i, index) => {
            let reqParams = exceptKey(i, [
                'index',
                'operators',
                'dateRange',
                'options',
                'type',
                'placeholder',
                'queryType',
            ]);
            if (index == 0) delete i.queryType;
            if (i.title != '' && i.operator != '' && i.value != '') {
                array.push({ query: reqParams, keyword: i.queryType });
            }
        });

        const params = {
            params: {
                search_by: JSON.stringify(array),
                page: pageAdv,
                per_page: perPageAdv,
            },
        };

        return params;
    };

    const advSearchAPI = () => {
        setIsLoading(true);

        http.get('/api/v3/users/search_filters.json', advancedSearchParams())
            .then(response => {
                setSearchedUsers(response.data.device_users);
                setTotalUsersCountAdv(response.data.total_users_count);
                setTotalPagesAdv(response.data.total_pages);
                setFilteredUsersCountAdv(response.data.total_filtered_users);
                setTotalDevicesCount(response.data.total_devices_count);
            })
            .catch(error => {
                let errMsg =
                    typeof error.response.data.errors == 'string'
                        ? error.response.data.errors
                        : Object.values(error.response.data.errors).join(', ');
                showErrorToast(errMsg);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <Box>
                                <Button color="primary" onClick={goBack}>
                                    Reports&nbsp; <ArrowForward fontSize="small" />
                                </Button>
                                <span className={classes.reportName}>User Inventory</span>
                            </Box>
                            <Box fontSize="13px" marginLeft="8px" color="gray">
                                View and download user inventory reports from here.
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <UserInventoryReportUploadCSVButton />
                            <DownloadCsvButton
                                csvPath="/api/v3/users/search_filters.csv"
                                parameters={advancedSearchParams()}
                                fileName="user"
                                disabled={isLoading}
                            />
                        </Box>
                    </Box>
                }
            />
            <Container
                style={{ marginTop: '20px', padding: '10px', justifyContent: 'space-between' }}
                className={classes.filters}>
                <UserInventoryAdvanceSearch
                    searchBy={searchBy}
                    setSearchBy={setSearchBy}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    advSearchAPI={advSearchAPI}
                    clearClicked={clearClicked}
                    setClearClicked={setClearClicked}
                    setPage={setPageAdv}
                />
            </Container>
            <Box className={classes.totalUsers}>
                <b>{`Total Users: ${filteredUsersCountAdv}`}</b>
                <b>{`Total Device Count: ${totalDevicesCount}`}</b>
            </Box>
            {isLoading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={24} />
                </div>
            ) : (
                <UserInventoryList
                    users={searchedUsers}
                    page={pageAdv}
                    setPage={setPageAdv}
                    isLoading={isLoading}
                    totalPages={totalPagesAdv}
                    fetchUsers={advSearchAPI}
                    perPageAdv={perPageAdv}
                    showTitle={showTitle}
                    setShowTitle={setShowTitle}
                />
            )}
        </Container>
    );
};

export default UserInventoryReportNew;
