import React from 'react';
import { makeStyles, Button, Typography } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/BarChart';

import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
}));

// see https://material-ui.com/guides/composition/#button
export default (theme, ...props) => {
    const classes = useStyles(theme);

    return (
        <Button
            to={process.env.PUBLIC_URL + '/reports'}
            color="inherit"
            id="reports-link"
            component={NavLink}>
            <ReportIcon /> Reports
        </Button>
    );
};
