import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import UserIcon from '@material-ui/icons/SupervisedUserCircle';

import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
}));

// see https://material-ui.com/guides/composition/#button

export default (theme, ...props) => {
    const classes = useStyles(theme);

    return (
        <Button
            to={process.env.PUBLIC_URL + '/users'}
            color="inherit"
            activeClassName="active"
            id="users-link"
            component={RouterLink}>
            <UserIcon /> Users
        </Button>
    );
};
