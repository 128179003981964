/**
 * Provides loggedin user object
 */

import React, { useReducer, useCallback } from 'react';
import * as Actions from 'components/providers/actions';
import UserApi from './UserApi';
import Toast from 'components/utils/Toast';

const DefaultToast = {
    isVisible: false,
    message: '',
    variant: 'success',
};

const initialState = {
    user: {}, // to be fetched from server
    theme: {},
    profiles: [], // to be fetched from server
    apps: [], // store apps to be fetched from server
    users: [], // parents or children
    admins: [], // custom admins created (to be fetched from server)
    roles: [], // custom roles created (to be fetched from server)
    userLoggedIn: null, // weather user is successfully logged in or not
    toast: DefaultToast,
    permissions: [], // permissions of logged-in user
    environment: 'development',
};

const userStateReducer = (state, action) => {
    switch (action.type) {
        case Actions.FETCH_USER:
            return {
                ...state,
                user: { ...action.payload },
            };
        case Actions.UPDATE_USER:
            return {
                ...state,
                user: { ...action.payload },
            };
        case Actions.SET_LOGIN_STATE:
            return {
                ...state,
                userLoggedIn: action.payload,
            };
        case Actions.SHOW_TOAST:
            return {
                ...state,
                toast: { ...state.toast, ...action.payload },
            };
        case Actions.FETCH_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload ? [...action.payload] : [],
            };
        case Actions.ENVIRONMENT:
            return {
                ...state,
                environment: action.payload,
            };
        default:
            return state;
    }
};

export const UserContext = React.createContext({});

export const UserProvider = ({ children, ...props }) => {
    const [userState, userDispatch] = useReducer(userStateReducer, initialState);
    const { userLoggedIn, toast } = userState;

    const onUserReloadNeeded = useCallback(async () => {
        // const user = await UserApi.loadUserDetails()
        UserApi.loadUserDetails()
            .then(response => {
                userDispatch({
                    type: Actions.FETCH_USER,
                    payload: response.data.user,
                });
                // Set user is loggedin without fail
                userDispatch({
                    type: Actions.SET_LOGIN_STATE,
                    payload: true,
                });
                // Set user's permissions
                userDispatch({
                    type: Actions.FETCH_PERMISSIONS,
                    payload: response.data.user.permissions,
                });
                userDispatch({
                    type: Actions.ENVIRONMENT,
                    payload: response.data.user.environment,
                });
            })
            .catch(error => {
                // Set user failed to login
                userDispatch({
                    type: Actions.SET_LOGIN_STATE,
                    payload: false,
                });
            });
        // update this callback if userLoggedIn value changes
    }, [userLoggedIn]);

    React.useEffect(() => {
        onUserReloadNeeded();
        // We need to fetch users after user is logged in successfully
    }, [userLoggedIn]);

    function toggleToast() {
        userDispatch({
            type: Actions.SHOW_TOAST,
            payload: {
                isVisible: false,
            },
        });
    }

    return (
        <UserContext.Provider value={[userState, userDispatch]}>
            {children}
            <Toast
                message={toast.message}
                variant={toast.variant}
                isOpen={toast.isVisible}
                toggleVisibility={toggleToast}
            />
        </UserContext.Provider>
    );
};
