import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box } from '@material-ui/core/';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    flexRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 24px 0 24px',
        background: '#9baaba',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
    },
    itemOne: {
        textAlign: 'left',
        flex: 2.7,
    },

    itemTwo: {
        textAlign: 'left',
        flex: 2,
    },

    itemThree: {
        textAlign: 'center',
        flex: 1,
    },

    headerItem: {
        fontWeight: 500,
        padding: '18px 0',
        color: '#fff',
    },
}));

const AppDataUsageListHeader = props => {
    const classes = useStyles();
    return (
        <Paper className={classes.flexRoot}>
            <Box className={clsx(classes.headerItem, classes.itemOne)}>APP</Box>
            <Box className={clsx(classes.headerItem, classes.itemThree)}>FOREGROUND DURATION</Box>
        </Paper>
    );
};

export default AppDataUsageListHeader;
