import React, { useState } from 'react';
import { Tooltip, IconButton, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(theme => ({
    copyButton: {
        zIndex: 0,
        cursor: 'pointer',
        minWidth: '0px',
    },
}));
const CopyToClipboard = ({ text, tooltipPlacement }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const copyToClipboard = (e, text) => {
        setOpen(true);
        e.stopPropagation();
        var textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        // setTimeout(() => setOpen(false), 500)
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title="Copied"
                open={open}
                onClose={handleTooltipClose}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                    disablePortal: true,
                }}
                placement={tooltipPlacement}>
                <IconButton
                    onClick={e => copyToClipboard(e, text)}
                    edge="end"
                    className={classes.copyButton}>
                    <CopyIcon />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default CopyToClipboard;
