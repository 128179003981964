import React, { useState } from 'react';
import {
    Box,
    FormControl,
    Button,
    Checkbox,
    InputLabel,
    ListItemText,
    Popover,
    List,
    ListItem,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import useSearchHook from 'components/profiles/Hooks/useSearchHook';
import SearchBox from 'components/utils/SearchBox';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(-4px, -7px) scale(1)',
            },
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'none !important',
                },
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    FormControl: {
        border: '1px solid #ced4da',
        borderRadius: 4,
        fontFamily: 'Camphor,sans-serif',
        width: '-webkit-fill-available', // Mozilla-based browsers will ignore this.
        width: '-moz-available', // WebKit-based browsers will ignore this.
        width: 'fill-available',
        minWidth: '-webkit-fill-available',
        minWidth: '-moz-available',
        minWidth: 'fill-available',
    },
    selectButton: {
        // width: '162px',
        height: 40,
        alignItems: 'Left',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        fontSize: 13,
        textTransform: 'none',
        paddingRight: '5px 0px 5px 10px',
    },
    selectLabelDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        textTransform: 'none',
    },
    listText: {
        fontSize: '12px',
    },
    optionsList: {
        maxHeight: '260px', // Earlier maxHeight: '300px'
        overflow: 'auto',
    },
    dropdownSearchBar: {
        backgroundColor: 'white',
        margin: '10px',
    },
    dropdownActionButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
}));

const CustomMultiSelectDropdown = props => {
    const classes = useStyles();

    const {
        showLabel,
        inputLabel,
        label,
        labelAfterSelecting,
        searchBoxLabel,
        options,
        selectedOptions,
        setSelectedOptions,
        labelKey,
        valueKey,
    } = props;

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchString, setSearchString] = useState('');

    const id = open ? 'simple-popover' : undefined;

    const { _list } = useSearchHook({
        searchString: searchString,
        keys: [labelKey],
        list: options,
    });

    const handleDropdownClick = event => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setSearchString('');
    };

    const handleCloseClick = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleSearchOnChange = event => {
        setSearchString(event.target.value);
    };

    const handleOptionSelect = (e, value) => {
        const currentIndex = selectedOptions.indexOf(value);
        const newChecked = [...selectedOptions];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedOptions(newChecked);
    };

    const handleCancelClick = () => {
        setSelectedOptions([]);
        handleCloseClick();
    };

    const handleClearClick = event => {
        setSelectedOptions([]);
        setOpen(true);
    };

    return (
        <Box>
            <MuiThemeProvider theme={theme}>
                <form autoComplete="off">
                    <FormControl className={classes.FormControl} variant="outlined">
                        {showLabel && (
                            <InputLabel
                                style={{
                                    backgroundColor: 'white',
                                    paddingLeft: '10px',
                                    justifyContent: 'center !important',
                                    display: 'flex !important',
                                    paddingRight: '10px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    fontSize: 'inherit',
                                    marginLeft: '35px',
                                }}>
                                {inputLabel}
                            </InputLabel>
                        )}
                        <Button
                            className={classes.selectButton}
                            disableRipple={true}
                            disableFocusRipple={true}
                            id="selected-options-button"
                            onClick={handleDropdownClick}>
                            <div className={classes.selectLabelDiv}>
                                <>
                                    {selectedOptions.length <= 0 ? (
                                        label
                                    ) : (
                                        <>
                                            {labelAfterSelecting} {selectedOptions.length}
                                        </>
                                    )}
                                </>
                                <ArrowDropDown />
                            </div>
                        </Button>
                    </FormControl>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseClick}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <Box>
                            <Box className={classes.dropdownSearchBar}>
                                <SearchBox
                                    id="search-input"
                                    inputProps={{ placeholder: `${searchBoxLabel}` }}
                                    onChange={handleSearchOnChange}
                                />
                            </Box>
                            <Box>
                                <List className={classes.optionsList}>
                                    {_list &&
                                        _list.map(option => (
                                            <ListItem key={option[valueKey]}>
                                                <Checkbox
                                                    onClick={e =>
                                                        handleOptionSelect(e, option[valueKey])
                                                    }
                                                    edge="start"
                                                    color="primary"
                                                    value={option[valueKey]}
                                                    checked={
                                                        selectedOptions.indexOf(option[valueKey]) >
                                                        -1
                                                    }
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby': `checkbox-list-label-${option[valueKey]}`,
                                                        'data-select-mp-checkbox': `${option[valueKey]}`,
                                                    }}
                                                />
                                                <ListItemText
                                                    className={classes.listText}
                                                    id={`checkbox-list-label`}
                                                    primary={option[labelKey]}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                            <Box className={classes.dropdownActionButton}>
                                <Box>
                                    <Button onClick={handleCancelClick} color="primary">
                                        CANCEL
                                    </Button>
                                    <Button onClick={handleCloseClick} color="primary">
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Popover>
                </form>
            </MuiThemeProvider>
        </Box>
    );
};

CustomMultiSelectDropdown.defaultProps = {
    showLabel: false,
    inputLabel: 'Select',
    label: 'Select',
    labelAfterSelecting: `Selected`,
    searchBoxLabel: 'Search options here',
    labelKey: 'label',
    valueKey: 'value',
};

export default CustomMultiSelectDropdown;
