/**
 * User login flow
 * We take user to login form if not logged in already
 */
import React, { useContext } from "react";
import { UserContext } from "components/providers/UserProvider";
import Login from "pages/Login";
import LoadingProgress from "components/progress/LoadingProgress";

const Authenticator = props => {
  const [state] = useContext(UserContext);
  const { userLoggedIn } = state;

  return (
    <>
      {userLoggedIn === null ? (
        <LoadingProgress />
      ) : userLoggedIn ? (
        props.children
      ) : (
        <Login />
      )}
    </>
  );
};

export default Authenticator;
