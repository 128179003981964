import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ThreeDotsDark from '../../images/three-dots-dark.svg';

const useStyles = makeStyles(theme => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 15,
        color: '#00C851',
    },
    loadingImg: {
        height: 14,
        padding: 5,
        marginTop: 8,
    },
    loadingText: {
        fontSize: 16,
    },
}));

function PasswordDialog(props) {
    const classes = useStyles();
    const {
        password,
        setPassword,
        openPasswordDialog,
        setOpenPasswordDialog,
        isDestructive,
        handleAccept,
        acceptBtnText,
        dialogText,
        loading,
        title,
    } = props;

    const handleClose = () => {
        setPassword('');
        setOpenPasswordDialog(false);
    };

    const handleChange = event => {
        setPassword(event.target.value);
    };

    return (
        <div>
            <Dialog
                open={openPasswordDialog}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title ? title : 'Enter Pasword'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                    {loading ? (
                        <Box className={classes.loading}>
                            <span className={classes.loadingText}>
                                <i>Action Successful. Please wait for the confirmation</i>
                            </span>
                            <img src={ThreeDotsDark} alt="loading" className={classes.loadingImg} />
                        </Box>
                    ) : (
                        <form autoComplete="off">
                            <TextField
                                autoFocus
                                inputProps={{
                                    autoComplete: 'nope',
                                }}
                                required
                                value={password}
                                onChange={e => handleChange(e)}
                                type="password"
                                margin="dense"
                                id="field1"
                                label="Password"
                                fullWidth
                            />
                        </form>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        size="small"
                        variant={isDestructive ? 'outlined' : 'text'}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading || password.length == 0}
                        onClick={() => handleAccept()}
                        size="small"
                        color={isDestructive ? 'secondary' : 'primary'}
                        variant={isDestructive ? 'contained' : 'text'}>
                        {acceptBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
PasswordDialog.defaultProps = {
    acceptBtnText: 'OK',
    loading: false,
};

PasswordDialog.propTypes = {
    acceptBtnText: PropTypes.any,
    loading: PropTypes.bool,
    isDestructive: PropTypes.bool,
};

export default PasswordDialog;
