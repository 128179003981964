import React from "react";
import { useEffect, useContext } from "react";
import Routes from "../Routes";
import { UserContext } from "components/providers/UserProvider";
import { Route } from "react-router-dom";

/**
 * This hook provides accessible routes defined by admin
 * @returns {*} routes allowed by admin for user to access
 */
const useRoutesHook = permissions => {
  const [{ user }] = useContext(UserContext);
  useEffect(() => {
    if (permissions.length) {
      Routes.forEach(route => {
        const perm = permissions.find(
          p =>
            route.permissionName == "common" || p.slug == route.permissionName
        );
        if (user.is_owner || (perm && perm.allowed == true)) {
          // Tell router, that this route needs to be redirected
          route.redirect = false;
        } else {
          route.redirect = true;
        }
      });
    }
  }, [permissions]);

  return { Routes };
};

export default useRoutesHook;
