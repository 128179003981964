import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
    },
});

const LoadMoreButton = props => {
    const classes = useStyles();
    const { errorMessage, totalRecords, presentRecords, setRecordsPerPage, perPage, increment } =
        props;

    const handleLoadMore = () => {
        setRecordsPerPage(perPage + increment);
    };
    return (
        <div className={classes.root}>
            {totalRecords == presentRecords ? (
                <strong className={classes.loadButton}>{errorMessage}</strong>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLoadMore}
                    style={{ fontSize: '13px' }}>
                    Load More
                </Button>
            )}
        </div>
    );
};

export default LoadMoreButton;
