import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '67px 57px',
    },
    centeredText: {
        textAlign: 'center',
        paddingTop: 50,
        paddingBottom: 20,
    },
    fileUploader: {
        background: '#F7F7F7',
        marginBottom: '20px',
        border: '2px dotted #9E9E9E',
        borderRadius: 3.5,
        fontSize: 16,
        lineHeight: '24px',
        padding: '35px',
        textAlign: 'center',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    browseFileText: {
        color: 'blue',
    },
    browseFileTextDisabled: {
        color: 'gray',
        cursor: 'not-allowed',
    },
}));

const UploadCSV = props => {
    const classes = useStyles();
    const { csv, setCsv, disabledAccept } = props;

    const onDrop = useCallback(
        acceptedFiles => {
            setCsv(acceptedFiles[0]);
        },
        [csv],
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.csv',
        maxSize: 2048 * 2048,
        onDrop: onDrop,
    });

    return (
        <div
            {...getRootProps()}
            className={clsx(classes.fileUploader, {
                [classes.browseFileTextDisabled]: disabledAccept,
            })}>
            <input {...getInputProps()} disabled={disabledAccept} />
            <div>
                Drag & Drop your CSV File here or{' '}
                <span
                    className={clsx(classes.browseFileText, {
                        [classes.browseFileTextDisabled]: disabledAccept,
                    })}>
                    Browse the File
                </span>
            </div>
            <Box component="span">{csv ? csv.name : ''}</Box>
        </div>
    );
};

export default UploadCSV;
