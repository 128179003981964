import React, { useState } from 'react';
import { useEffect } from 'react';

/**
 * @param {[]} list list to be searched
 * @param {[]} searchString string to be searched
 * @param {[]} key object's key to be searched
 * @param {Object} props
 * @returns {[]} List matched with search string
 */
const useSearchHook = props => {
    useEffect(() => {}, [props.searchString]);
    let _list = props.list;
    let search = props.searchString.trim().toLowerCase();

    if (search.length > 0 && props.list.length) {
        _list = _list.filter(function (app) {
            if (props.key) {
                return app[props.key].toLowerCase().indexOf(search) !== -1;
            } else if (props.keys) {
                return props.keys.some(
                    eachKey => app[eachKey].toLowerCase().indexOf(search) !== -1,
                );
            }
        });
    }

    return { _list };
};

export default useSearchHook;
