import React, { useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { cloneDeep, indexOf as lodashIndexOf, remove } from 'lodash';
// Material UI
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import {
    IconButton,
    FormControlLabel,
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableContainer,
    TableCell,
    Link,
    Tooltip,
    Box,
} from '@material-ui/core';
import { UserContext } from 'components/providers/UserProvider';
import { SHOW_TOAST } from 'components/providers/actions';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import CopyComponent from 'components/users/CopyComponent';
const useStyles = makeStyles(theme => ({
    p: {
        color: '#5E5E5E',
    },

    row: {
        padding: '0px',
    },
    cell: {
        padding: '0px 10px',
    },
    cell2: {
        padding: '10px 10px',
    },
    checkboxCell: {
        paddingLeft: '20px',
    },

    lineHeight: {
        lineHeight: '12px',
        fontWeight: 600,
    },

    iconColor: {
        color: '#777',
    },

    tableBorder: {
        borderCollapse: 'inherit',
        border: '1px solid rgb(239, 239, 239)',
        borderRadius: 4,
        borderBottom: 'transparent',
    },

    tableHead: {
        backgroundColor: 'rgba(189, 185, 255, 0.24)',
    },

    tableContainer: {
        height: 300,
    },

    hiddenInput: {
        opacity: 0,
        position: 'absolute',
    },

    copyIcon: {},
}));

const List = props => {
    const classes = useStyles();
    const {
        listHeaders,
        rows,
        checkboxedList,
        deleteIconRequired,
        allowedUpdate,
        disableCheckboxFor,
        firstColAsUsersLink,
    } = props;

    const isSelected = id => props.selected.indexOf(id) !== -1;
    const [{ user }, userDispatch] = useContext(UserContext);

    function handleSelect(event, id) {
        let newSelected = cloneDeep(props.selected);
        lodashIndexOf(newSelected, id) != -1
            ? remove(newSelected, n => n == id)
            : newSelected.push(id);

        props.setSelected(newSelected);
    }

    // User id hidden field input ref we need to copy the content from
    const inputRef = useRef(null);

    function copyText() {
        inputRef.current.select();
        document.execCommand('copy');
        showSuccessToast('Copied');
    }

    function showSuccessToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    }
    // function onSelectAllClick(event) {
    //     if (event.target.checked) {
    //         const newSelecteds = rows.map(n => n.id);
    //         props.setSelected(newSelecteds);
    //         return;
    //     }
    //     props.setSelected([]);
    // }

    return (
        <div>
            {/* {checkboxedList && rows.length > 0 && (
                <FormControlLabel
                    label="Select All"
                    control={
                        <Checkbox
                            disabled={!allowedUpdate}
                            indeterminate={
                                props.selected.length > 0 && props.selected.length < rows.length
                            }
                            checked={props.selected.length === rows.length}
                            onChange={onSelectAllClick}
                            color="primary"
                        />
                    }
                />
            )} */}
            <TableContainer className={classes.tableContainer}>
                {rows.length > 0 ? (
                    <Table classes={{ root: classes.tableBorder }} stickyHeader>
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.row}>
                                {listHeaders.map((header, index) => (
                                    <TableCell
                                        key={index}
                                        className={clsx(classes.p, classes.lineHeight)}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <TableRow className={classes.row} key={rowIndex}>
                                    {checkboxedList && (
                                        <TableCell
                                            align="left"
                                            key={row.id}
                                            className={classes.cell}>
                                            <FormControlLabel
                                                className={classes.formLabel}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        className={classes.checkboxCell}
                                                        inputProps={{
                                                            'pd-master-profile-checkbox-name': `${row.name}`,
                                                        }}
                                                        disabled={
                                                            !allowedUpdate ||
                                                            disableCheckboxFor.includes(row.id)
                                                        }
                                                        checked={isSelected(row.id)}
                                                        onChange={event =>
                                                            handleSelect(event, row.id)
                                                        }
                                                    />
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    {Object.keys(row)
                                        .filter(function (e) {
                                            return e !== 'id';
                                        })
                                        .map((k, index) => (
                                            <TableCell
                                                align="left"
                                                key={k}
                                                className={
                                                    checkboxedList ? classes.cell : classes.cell2
                                                }>
                                                {index == 0 && firstColAsUsersLink ? (
                                                    <Box>
                                                        <Link
                                                            underline="always"
                                                            target="_blank"
                                                            className={classes.link}
                                                            href={
                                                                process.env.PUBLIC_URL +
                                                                `/users?q=${row[k]}`
                                                            }>
                                                            {row[k]}
                                                        </Link>
                                                        {/* <input
                                                            ref={inputRef}
                                                            className={classes.hiddenInput}
                                                            type="text"
                                                            readOnly
                                                            value={row[k] || ''}
                                                        />
                                                        <Tooltip title="Copy">
                                                            <IconButton
                                                                className={classes.copyIcon}
                                                                id="copy-button"
                                                                onClick={copyText}>
                                                                <CopyIcon />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                        <CopyComponent
                                                            inputRef={inputRef}
                                                            inputValue={row[k]}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Typography
                                                        key={index}
                                                        component="p"
                                                        color="inherit"
                                                        className={classes.p}>
                                                        {/* {row[k] || 'NA'} */}
                                                        {typeof row[k] == 'object'
                                                            ? row[k].join(',')
                                                            : row[k] || 'NA'}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        ))}
                                    {deleteIconRequired && allowedUpdate && (
                                        <TableCell>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="Open drawer"
                                                onClick={() => props.handleDeleteAction(row.id)}
                                                className={clsx(classes.menuButton, {
                                                    [classes.hide]: props.open,
                                                })}>
                                                <DeleteIcon className={classes.iconColor} />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Box
                        height="inherit"
                        width="inherit"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Typography align="center" variant="h6">
                            No data found
                        </Typography>
                    </Box>
                )}
            </TableContainer>
        </div>
    );
};

List.defaultProps = {
    rows: [],
    checkboxedList: false,
    deleteIconRequired: false,
    selected: [],
    allowedUpdate: false,
    disableCheckboxFor: [],
    checkboxId: '',
    firstColAsUsersLink: false,
};

List.propTypes = {
    checkboxedList: PropTypes.bool,
    deleteIconRequired: PropTypes.bool,
    allowedUpdate: PropTypes.bool,
    disableCheckboxFor: PropTypes.array,
    checkboxId: PropTypes.any,
};
export default List;
