import React, { useContext, useState } from 'react';
import fileDownload from 'js-file-download';
import { Box, Button, Link } from '@material-ui/core';
import http from 'core/http';
import usePermissionsHook from 'Hooks/usePermissionHook';
import ThreeDotsDark from '../../images/three-dots-dark.svg';
import PropTypes from 'prop-types';
import { UserContext } from 'components/providers/UserProvider';
import { SHOW_TOAST } from 'components/providers/actions';
import { makeStyles } from '@material-ui/styles';
const PERMISSION_SLUG = 'Byjus/Reports';

const useStyles = makeStyles(theme => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 15,
        color: '#00C851',
    },
    linkLoading: {
        display: 'flex',
        justifyContent: 'center',
        color: '#00C851',
    },
    loadingText: {
        fontSize: 16,
    },
    loadingLinkText: {
        fontSize: 13,
    },
    loadingImg: {
        height: 16,
        padding: 5,
        marginTop: 8,
        marginRight: 32,
    },
    linkLoadingImg: {
        height: 16,
        padding: 5,
        marginTop: 3,
        marginRight: 32,
    },
}));

const DownloadCsvButton = props => {
    const classes = useStyles();
    const { csvPath, parameters, fileName, disabled, id, text, toastMessage } = props;
    const { allowedUpdate } = usePermissionsHook(PERMISSION_SLUG);
    const [loading, setLoading] = useState(false);
    const [{ user }, userDispatch] = useContext(UserContext);

    function showSuccessToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'success',
            },
        });
    }

    const downloadCsv = () => {
        // Dowload CSV File
        setLoading(true);
        http.get(csvPath, parameters)
            .then(response => {
                showSuccessToast(
                    'Action for downloading the CSV is successful, for status please visit reports > bulk action status.',
                );
                fileDownload(
                    response.data,
                    response.headers['content-disposition']
                        .split('filename=')[1]
                        .replace(/['"]/g, ''),
                );
            })
            .catch(error => {
                console.log('ERROR IN RESPONSE: ', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            {loading ? (
                <>
                    {!text ? (
                        <Box className={classes.loading}>
                            <span className={classes.loadingText}>
                                <i>Downloading CSV, Please Wait</i>
                            </span>
                            <img src={ThreeDotsDark} alt="loading" className={classes.loadingImg} />
                        </Box>
                    ) : (
                        <Box className={classes.linkLoading}>
                            <span className={classes.loadingLinkText}>
                                <i>Downloading CSV, Please Wait</i>
                            </span>
                            <img
                                src={ThreeDotsDark}
                                alt="loading"
                                className={classes.linkLoadingImg}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <>
                    {!text && allowedUpdate && (
                        <Button
                            id={id}
                            variant="contained"
                            color="primary"
                            onClick={downloadCsv}
                            disabled={disabled}>
                            DOWNLOAD AS CSV
                        </Button>
                    )}

                    {text && allowedUpdate && (
                        <Link component="button" onClick={downloadCsv}>
                            Download as CSV
                        </Link>
                    )}
                </>
            )}
        </div>
    );
};

DownloadCsvButton.defaultProps = {
    id: 'download-as-csv-button',
};

DownloadCsvButton.propTypes = {
    id: PropTypes.any,
};

export default DownloadCsvButton;
