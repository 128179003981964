/**
 * User login form
 */
import React, { useContext, useState } from 'react';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircleRounded, LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';
import http from 'core/http';
import { UserContext } from 'components/providers/UserProvider';
import { SET_LOGIN_STATE } from 'components/providers/actions';
import Toast from 'components/utils/Toast';
import ProgressButton from 'components/utils/ProgressButton';
import { inRange } from 'lodash';
import { useLocation } from 'react-router-dom';
import ForgetPassword from 'components/Login/ForgetPassword';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        padding: 24,
        width: '25%',
        margin: 'auto',
    },

    margin: {
        margin: theme.spacing(1),
    },

    formControlRoot: {
        margin: '12px 0',
    },

    button: {
        width: '-webkit-fill-available',
        marginTop: 20,
    },

    greyIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
    },

    forgetPassword: {
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'end',
    },

    forgetPasswordText: {
        fontWeight: 500,
        color: '#3f51b5',
        cursor: 'pointer',
    },
}));

const Login = props => {
    const [state, dispatch] = useContext(UserContext);

    const [isTOastVisible, setToastVisible] = useState(false);
    const [message, setMessage] = useState('');

    // api call loader
    const [isLoading, setIsLoading] = useState(false);
    const [forgetPasswordClicked, setForgetPasswordClicked] = useState(false);

    function toggleToast() {
        setToastVisible(v => !v);
    }

    const classes = useStyles();
    // Form fields
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const updateValues = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    /**
     * Post user data to server for login request
     */
    function login() {
        setIsLoading(true);

        let data = new FormData();
        data.append('utf8', '✓');
        data.append('user[email]', values.email);
        data.append('user[password]', values.password);
        // data.append('user[remember_me]', true);

        http.post('/users/sign_in.json', data)
            .then(res => {
                redirectToHome(res.data);
                setUserLoggedIn();
            })
            .catch(error => {
                // Do not show snack in case of 5xx server errors
                if (inRange(error.response.status, 500, 599)) return;

                setMessage(error.response.data.error);
                toggleToast();
            })
            .finally(() => {
                // stop loader
                setIsLoading(false);
            });
    }

    function setUserLoggedIn() {
        dispatch({
            type: SET_LOGIN_STATE,
            payload: true,
        });
    }

    const toggleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const history = useHistory();

    function redirectToHome(signInResponseData) {
        let user = signInResponseData.user;
        if (user && user.afw && user.afw.setup_done) {
            if (!query.get('q')) {
                history.push('/moderator-dash/profiles');
            }
        } else {
            history.push('/moderator-dash/config');
        }
    }

    // Logi user when hot Enter key
    function onEnterPress(event) {
        if (event.key === 'Enter') {
            login();
        }
    }

    const { showPassword, email, password } = values;

    // For Forget Password

    const openFogetPassword = () => {
        setForgetPasswordClicked(true);
    };

    const closeFogetPassword = () => {
        setForgetPasswordClicked(false);
    };

    return (
        <div className={classes.root}>
            {!forgetPasswordClicked && (
                <>
                    <FormControl
                        fullWidth
                        className={classes.margin}
                        classes={{ root: classes.formControlRoot }}
                        variant="outlined">
                        <InputLabel htmlFor="user-email-input">User Name</InputLabel>
                        <OutlinedInput
                            onKeyPress={onEnterPress}
                            id="user-email-input"
                            value={email}
                            onChange={updateValues('email')}
                            startAdornment={
                                <InputAdornment position="start">
                                    <AccountCircleRounded className={classes.greyIcon} />
                                </InputAdornment>
                            }
                            labelWidth={72}
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={classes.margin}
                        classes={{ root: classes.formControlRoot }}
                        variant="outlined">
                        <InputLabel htmlFor="user-password-input">Password</InputLabel>
                        <OutlinedInput
                            onKeyPress={onEnterPress}
                            id="user-password-input"
                            value={password}
                            onChange={updateValues('password')}
                            type={showPassword ? 'text' : 'password'}
                            startAdornment={
                                <InputAdornment position="start">
                                    <LockRounded className={classes.greyIcon} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        aria-label="Toggle password visibility"
                                        onClick={toggleShowPassword}>
                                        {showPassword ? (
                                            <VisibilityOff className={classes.greyIcon} />
                                        ) : (
                                            <Visibility className={classes.greyIcon} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={62}
                        />
                    </FormControl>
                    <Box className={classes.forgetPassword}>
                        <span onClick={openFogetPassword} className={classes.forgetPasswordText}>
                            Forget Password?
                        </span>
                    </Box>
                    <ProgressButton
                        id="user-login-button"
                        color="primary"
                        variant="contained"
                        isWaiting={isLoading}
                        styles={classes.button}
                        disabled={isLoading}
                        onClick={login}>
                        Log In
                    </ProgressButton>
                    <Toast
                        message={message}
                        variant="error"
                        isOpen={isTOastVisible}
                        toggleVisibility={toggleToast}
                    />
                </>
            )}
            {forgetPasswordClicked && (
                <ForgetPassword
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    closeFogetPassword={closeFogetPassword}
                />
            )}
        </div>
    );
};

export default Login;
