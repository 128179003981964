import React, { useState } from 'react';
import {
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import SearchBox from 'components/utils/SearchBox';
import useSearchHook from 'components/profiles/Hooks/useSearchHook';

const theme = createMuiTheme({
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: '0px',
            },
        },
        MuiDialogContent: {
            dividers: {
                padding: '16px 0px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    list: {
        paddingTop: '0px',
        paddingBottom: '0px',
    },

    listText: {
        fontSize: '12px',
    },
}));

const MultiSelectDialog = props => {
    const {
        onClose,
        open,
        options,
        handleUserEmailChange,
        searchString,
        setSearchString,
        selectedUserEmail,
        setSelectedUserEmail,
        ...other
    } = props;
    const classes = useStyles();

    const handleCancel = () => {
        handleUserEmailChange([]);
        setSelectedUserEmail([]);
        onClose(false);
    };

    const handleOk = () => {
        handleUserEmailChange(selectedUserEmail);
        onClose(false);
    };

    const handleChange = (e, value) => {
        const currentIndex = selectedUserEmail.indexOf(value);
        const newChecked = [...selectedUserEmail];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectedUserEmail(newChecked);
    };

    /**
     * Store search string to render searched app list
     * @param {*} event
     */
    const onChange = event => {
        setSearchString(event.target.value);
    };

    const { _list } = useSearchHook({
        searchString: searchString,
        keys: ['email'],
        list: options,
    });

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}>
            <DialogTitle id="confirmation-dialog-title">Admin Emails</DialogTitle>
            <DialogContent dividers>
                {open && (
                    <SearchBox
                        inputProps={{ placeholder: 'Search By Email ID' }}
                        id="filter-admins-by-email-search-input"
                        onChange={onChange}
                    />
                )}
                <MuiThemeProvider theme={theme}>
                    <List>
                        {_list.map(option => (
                            <ListItem
                                key={option.email}
                                button
                                onClick={e => handleChange(e, option.email)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedUserEmail.indexOf(option.email) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': `checkbox-list-label-${option.email}`,
                                        }}
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    className={classes.listText}
                                    id={`checkbox-list-label-${option.email}`}
                                    primary={option.email}
                                />
                            </ListItem>
                        ))}
                    </List>
                </MuiThemeProvider>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    CANCEL
                </Button>
                <Button onClick={handleOk} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MultiSelectDialog;
