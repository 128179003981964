import http from 'core/http';
import React, { useState, useEffect } from 'react';
import { Container, Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Heading from 'components/header/Heading';
import { ArrowForward } from '@material-ui/icons';
import UserLevelAppUsage from './UserLevelAppUsage';
import { StyledTabs, StyledTab, Tabpanel } from 'components/tabs/StyledTabs';
import TotalAppUsage from './TotalAppUsage';
import _ from 'lodash';
import LoadingProgress from 'components/progress/LoadingProgress';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    filters: {
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
    },
    select: {
        marginTop: '16px',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        width: '30%',
        marginRight: 15,
    },

    dateControl: {
        width: '79%',
        marginRight: 15,
    },

    loading: {
        position: 'absolute',
        marginTop: '5%',
    },

    centeredProgress: {
        height: 600,
        position: 'relative',
        left: '45%',
        top: '40%',
    },

    searchField: {
        margin: 0,
    },
    tags_filter: {
        width: '250px',
        marginTop: '16px',
    },

    actionButton: {
        marginLeft: 'auto',
        marginRight: 15,
    },

    topContainer: {
        width: 'calc(100% - 300px)',
        marginLeft: 20,
    },

    disabled: {
        pointerEvents: 'none',
        opacity: 0.6,
    },
    tabContainer: {
        backgroundColor: '#9baaba !important', //theme.palette.background.paper,
        marginTop: 25,
        marginBottom: 5,
        padding: '6px 0 0',
        borderRadius: 3,
        boxShadow: '0 2px 4px 0 rgba(50,50,93,.1)',
        '& > .tabpanel': {
            minHeight: 615,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
    },
}));

const AppUsageReport = props => {
    const classes = useStyles();
    const [selectedDeviceUserId, setSelectedDeviceUserId] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [currentTab, setCurrentTab] = useState(0);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [totalAppUsageData, setTotalAppUsageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [{ user }, userDispatch] = React.useContext(UserContext);

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }
    useEffect(() => {
        setInitialDataUsageDate();
        fetchInitialData();
    }, []);

    const setInitialDataUsageDate = () => {
        const currentDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(currentDate.getDate() - 6);
        fromDate = new Date(fromDate.toDateString());

        setFromDate(
            new Date(
                fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate(),
            ),
        );
        setToDate(
            new Date(
                currentDate.getFullYear() +
                    '-' +
                    (currentDate.getMonth() + 1) +
                    '-' +
                    currentDate.getDate(),
            ),
        );
    };

    const fetchInitialData = () => {
        setLoading(true);
        http.get(`/api/v3/app_data_usage_report/initial_data.json`)
            .then(response => {
                setProfiles(response.data.master_profiles);
            })
            .catch(error => {
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    const handleFromDateChange = date => {
        setFromDate(date);
    };

    const handleToDateChange = date => {
        setToDate(date);
    };

    const handleTabChange = (event, newVal) => {
        setInitialDataUsageDate();
        setSelectedDeviceUserId([]);
        setSelectedDevice([]);
        setCurrentTab(newVal);
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <>
                        <Box>
                            <Button color="primary" onClick={goBack}>
                                Reports&nbsp; <ArrowForward fontSize="small" />
                            </Button>
                            <span className={classes.reportName}>App Usage</span>
                        </Box>
                        {/* <Box fontSize="13px" marginLeft="8px" color="gray">
                            View and download app usage reports from here.
                        </Box> */}
                    </>
                }
            />
            {loading ? (
                <LoadingProgress />
            ) : (
                <UserLevelAppUsage
                    selectedDeviceUserId={selectedDeviceUserId}
                    setDeviceList={setDeviceList}
                    setSelectedDeviceUserId={setSelectedDeviceUserId}
                    setSelectedDevice={setSelectedDevice}
                    selectedDevice={selectedDevice}
                    fromDate={fromDate}
                    toDate={toDate}
                    handleFromDateChange={handleFromDateChange}
                    handleToDateChange={handleToDateChange}
                    deviceList={deviceList}
                />
            )}
        </Container>
    );
};

export default AppUsageReport;
