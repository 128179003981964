// Fetch user action
export const FETCH_USER = 'FETCH_USER';

export const UPDATE_USER = 'UPDATE_USER';

export const SET_LOGIN_STATE = 'SET_LOGIN_STATE';

export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';

export const ENVIRONMENT = 'ENVIRONMENT';

export const SHOW_TOAST = 'SHOW_TOAST';
