import React, { useContext, useState, useEffect } from 'react';
import 'date-fns';
import {
    Container,
    Typography,
    Box,
    Button,
    Grid,
    Select,
    MenuItem,
    FormControl,
    Input,
    Radio,
    ListItemText,
    InputLabel,
    TextField,
    CircularProgress,
} from '@material-ui/core';
import LoadingProgress from 'components/progress/LoadingProgress';
import clsx from 'clsx';

import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { ArrowForward, ArrowDropDown, FilterList, Translate } from '@material-ui/icons';
import Heading from 'components/header/Heading';
import http from 'core/http';
import AccountActivityList from './AccountActivityList';
import DownloadCsvButton from './DownloadCsvButton';
import ActiVityType from './ActivityTypes';
import usePermissionsHook from 'Hooks/usePermissionHook';
import DateRange from './DateRange';
import MultiSelectDialog from './MultiSelectDialog';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const PERMISSION_SLUG = 'Byjus/ActivityLog';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(21px, 14px) scale(1)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '13px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    filters: {
        background: 'white',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        padding: 10,
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        '& #activity-type-label': {
            fontWeight: 500,
        },
    },

    label: {
        visibility: 'hidden',
    },
    selectUserButton: {
        // borderBottom: '1px solid grey',
        // paddingBottom: '0px',
        // paddingLeft: '0px',
        color: 'grey',
        width: '100%',
        borderRadius: 4,
        paddingTop: 5,
        marginTop: 10,
    },
    selectUserDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '4%',
        paddingBottom: 2,
    },
    paper: {
        // width: '25%',
        width: 'max-content',
        maxHeight: 435,
    },
    searchField: {
        width: '32%',
        // marginLeft: 15,
        border: '1px solid #ced4da',
        borderRadius: 4,
        // height: 1.1875em;
        margin: 0,
        display: 'block',
        padding: '12px 8px',
        fontSize: 14,
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        // '&:focus': {
        //     outline: 'none',
        //     borderBottom: `1px solid ${theme.palette.primary.main}`,
        // },
        marginTop: 16,
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        marginTop: '15px',
        marginBottom: '8px',
        justifySelf: 'center',
        alignSelf: 'center',
        fontSize: 11,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AccountActivityReport = props => {
    const classes = useStyles();
    const { allowed } = usePermissionsHook(PERMISSION_SLUG);
    const { allowedUpdate } = usePermissionsHook(PERMISSION_SLUG);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [loading, setLoading] = useState(true);
    // const [perPage, setPerPage] = useState(50);
    const [users, setUsers] = useState([]);
    const [activities, setActivities] = useState([]);
    // const [totalActivities, setTotalActivities] = useState();
    const [activityTypes, setActivityTypes] = useState([]);

    const [selectedActivityType, setSelectedActivityType] = useState([]);
    const [selectedUserEmail, setSelectedUserEmail] = useState([]);
    const [hideClearButton, setHideClearButton] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [userId, setUserId] = useState('');
    // Search string
    const [searchString, setSearchString] = useState('');
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [clearClicked, setClearClicked] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [{ user }, userDispatch] = React.useContext(UserContext);

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }
    useEffect(() => {
        setDateRange();
        setActivityTypes(ActiVityType);
    }, []);

    useEffect(() => {
        if (!isFilterApplied) fetchActivities(true);
    }, [fromDate, toDate]);
    // selectedUserEmail, selectedActivityType, fromDate, toDate, perPage, userId]);

    useEffect(() => {
        fetchActivities(false);
    }, [page]);

    useEffect(() => {
        if (clearClicked) fetchActivities();
    }, [clearClicked]);

    /**
     * This function set fromDate and toDate
     */
    const setDateRange = () => {
        const currentDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(currentDate.getDate() - 6);
        fromDate = new Date(fromDate.toDateString());

        setFromDate(
            new Date(
                fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate(),
            ),
        );
        setToDate(
            new Date(
                currentDate.getFullYear() +
                    '-' +
                    (currentDate.getMonth() + 1) +
                    '-' +
                    currentDate.getDate(),
            ),
        );
    };

    /**
     * Fetch Account Activities On Change UserEmail, ActivityType, DateRange
     */
    const fetchActivities = (refreshList = false) => {
        const updateList = refreshList;
        if (updateList) {
            setPage(1);
        }
        setLoading(true);
        const data = formatData();
        if (data.params.from_date !== undefined && data.params.to_date !== undefined) {
            http.get('/cloud/audit/activities.json', data)
                .then(response => {
                    // var updatedActivitiesList = updateList
                    //     ? response.data.activities
                    //     : activities.concat(response.data.activities);
                    setActivities(response.data.activities);
                    // setTotalActivities(response.data.total_records);
                    setUsers(response.data.filters_data.account_users);
                    setTotalPages(response.data.total_pages);
                })
                .catch(error => {
                    console.log('ERROR IN RESPONSE: ', error);
                    showErrorToast(error.response.data.errors || 'Something went Wrong!');
                })
                .finally(() => {
                    setLoading(false);
                    setIsFilterApplied(true);
                });
        }
    };

    /**
     * Format Parameter Data
     */
    const formatData = () => {
        const params = {
            params: {
                from_date:
                    fromDate &&
                    fromDate.getFullYear() +
                        '_' +
                        (fromDate.getMonth() + 1) +
                        '_' +
                        fromDate.getDate(),
                to_date:
                    toDate &&
                    toDate.getFullYear() + '_' + (toDate.getMonth() + 1) + '_' + toDate.getDate(),
                load_filter_options: true,
                page: page,
                // per_page: perPage,
                per_page: 10,
                user_emails: selectedUserEmail,
                activity_types: selectedActivityType,
                device_user_id: userId,
            },
        };
        return params;
    };

    const handleActivityChange = event => {
        setSelectedActivityType([event.target.value]);
        setHideClearButton(false);
        setClearClicked(false);
    };

    const handleFromDateChange = date => {
        setFromDate(date);
        setHideClearButton(false);
        setClearClicked(false);
    };

    const handleToDateChange = date => {
        setToDate(date);
        setHideClearButton(false);
        setClearClicked(false);
    };

    const handleUserEmailChange = selectedUserEmails => {
        setClearClicked(false);
        setSelectedUserEmail(selectedUserEmails);
        if (selectedUserEmails.length != 0) {
            setHideClearButton(false);
        }
    };

    /**
     * Clear All Selected Filters
     */
    const handleClearButtonClick = () => {
        setClearClicked(true);
        setSelectedActivityType([]);
        setSelectedUserEmail([]);
        setPage(1);
        setDateRange();
        setHideClearButton(true);
        setUserId('');
    };

    /**
     * Toggle MultiUser Select Dialog
     * @param {*} value dialog state
     */
    const toggleMultiSeclectDialog = value => {
        setOpen(value);
        setSearchString('');
    };

    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    const handleUserIdSearchTextChange = event => {
        setUserId(event.target.value);
        setHideClearButton(false);
        setClearClicked(false);
    };

    const handleSubmitClick = () => {
        fetchActivities(true);
    };
    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" flexDirection="column">
                            <Box>
                                <Button color="primary" onClick={goBack}>
                                    Reports&nbsp; <ArrowForward fontSize="small" />
                                </Button>
                                <span className={classes.reportName}>Account Activity</span>
                            </Box>
                            <Box fontSize="13px" marginLeft="8px" color="gray">
                                View and download account activity reports from here.
                            </Box>
                        </Box>
                        <Box>
                            {allowedUpdate && (
                                <DownloadCsvButton
                                    csvPath="/cloud/audit/activities.csv"
                                    parameters={formatData()}
                                    fileName="Account_Activity"
                                    disabled={activities.length == 0}
                                />
                            )}
                        </Box>
                    </Box>
                }
            />

            {allowed && (
                <div>
                    <Box>
                        <Container style={{ marginTop: '20px' }} className={classes.filters}>
                            <Grid className={classes.spaceBetween}>
                                <Box display="flex" style={{ marginTop: '18px' }}>
                                    <FilterList className={classes.spaceAround} />
                                    <Typography> Filter Activities </Typography>
                                </Box>
                                <Box style={{ marginTop: '10px' }}>
                                    <DateRange
                                        fromDate={fromDate}
                                        toDate={toDate}
                                        handleFromDateChange={handleFromDateChange}
                                        handleToDateChange={handleToDateChange}
                                        datePickerLabel="Activities Between"
                                    />
                                </Box>

                                <MuiThemeProvider theme={theme}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        style={{ marginTop: '11px' }}>
                                        <InputLabel
                                            shrink={false}
                                            // className={
                                            //     selectedActivityType.length !== 0 && classes.label
                                            // }
                                            className={clsx({
                                                [classes.label]: selectedActivityType.length !== 0,
                                            })}
                                            id="activity-type-label">
                                            ACTIVITY
                                        </InputLabel>
                                        <Select
                                            value={
                                                selectedActivityType.length != 0
                                                    ? activityTypes.filter(
                                                          a => a.key == selectedActivityType[0],
                                                      )[0].value
                                                    : selectedActivityType
                                            }
                                            onChange={handleActivityChange}
                                            // input={<Input />}
                                            renderValue={selected => selected}
                                            MenuProps={MenuProps}>
                                            {activityTypes.map(activityType => (
                                                <MenuItem
                                                    key={activityType.key}
                                                    value={activityType.key}>
                                                    <Radio
                                                        checked={
                                                            selectedActivityType[0] ==
                                                            activityType.key
                                                        }
                                                        color="primary"
                                                    />
                                                    <ListItemText primary={activityType.value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </MuiThemeProvider>

                                <FormControl className={classes.formControl}>
                                    <Button
                                        variant="outlined"
                                        className={classes.selectUserButton}
                                        onClick={e => toggleMultiSeclectDialog(true)}>
                                        <div className={classes.selectUserDiv}>
                                            {selectedUserEmail.length == 0
                                                ? `SELECT ADMIN`
                                                : `SELECTED ADMIN(s) ${selectedUserEmail.length}`}
                                            <ArrowDropDown />
                                        </div>
                                    </Button>
                                </FormControl>
                                <MultiSelectDialog
                                    classes={{
                                        paper: classes.paper,
                                    }}
                                    id="userselect-menu"
                                    keepMounted
                                    open={open}
                                    onClose={toggleMultiSeclectDialog}
                                    options={users}
                                    searchString={searchString}
                                    setSearchString={setSearchString}
                                    selectedUserEmail={selectedUserEmail}
                                    setSelectedUserEmail={setSelectedUserEmail}
                                    handleUserEmailChange={handleUserEmailChange}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    disabled={
                                        fromDate == 'Invalid Date' ||
                                        fromDate == null ||
                                        toDate == 'Invalid Date' ||
                                        toDate == null
                                    }
                                    style={{ marginTop: '15px', marginBottom: '8px' }}
                                    onClick={handleSubmitClick}>
                                    <strong>FILTER</strong>
                                </Button>
                            </Grid>
                            <Grid className={classes.spaceBetween}>
                                <input
                                    placeholder="Search using User ID / Serial #"
                                    id="search"
                                    type="search"
                                    className={classes.searchField}
                                    value={userId}
                                    onChange={e => handleUserIdSearchTextChange(e)}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    className={clsx(classes.button, {
                                        [classes.clearButton]: hideClearButton,
                                    })}
                                    onClick={handleClearButtonClick}>
                                    <strong>Clear</strong>
                                </Button>
                            </Grid>
                        </Container>
                    </Box>
                    {loading ? (
                        <div className={classes.loadingContainer}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (
                        <AccountActivityList
                            accountActivities={activities}
                            totalPages={totalPages}
                            page={page}
                            setPage={setPage}
                        />
                    )}
                </div>
            )}
        </Container>
    );
};

export default AccountActivityReport;
