import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import EnterpriseAppsIcon from '@material-ui/icons/PlayForWork';
import AppsIcon from '@material-ui/icons/Apps';

import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
}));

// see https://material-ui.com/guides/composition/#button
export default (theme, ...props) => {
    const classes = useStyles(theme);

    return (
        <Button
            to={process.env.PUBLIC_URL + '/apps'}
            color="inherit"
            component={RouterLink}
            id="enterprise-apps-link">
            {/* <EnterpriseAppsIcon /> */}
            <AppsIcon />
            Applications
        </Button>
    );
};
