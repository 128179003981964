import http from 'core/http';
import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    CircularProgress,
    Chip,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Heading from 'components/header/Heading';
import { ArrowForward, FilterList } from '@material-ui/icons';
import DownloadCsvButton from './DownloadCsvButton';
import DateRange from './DateRange';
import ScreenTimeList from './ScreenTimeList';
import { Autocomplete } from '@material-ui/lab';
import FilterByMasterProfiles from 'components/users/FilterByMasterProfiles';
import UserApi from 'components/providers/UserApi';
import { UserContext } from 'components/providers/UserProvider';
import { cloneDeep, indexOf as lodashIndexOf, remove, shuffle } from 'lodash';
import FilterByCategories from './FilterByCategories';
import clsx from 'clsx';
import { SHOW_TOAST } from 'components/providers/actions';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 10px) scale(0.75)',
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '13px',
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    filters: {
        background: 'white',
        padding: '10',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
    searchField: {
        width: '96%',
        border: '1px solid #ced4da',
        borderRadius: 4,
        margin: 0,
        display: 'block',
        padding: '13px 8px',
        fontSize: 14,
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        marginTop: 14,
    },
    tagsBorder: {
        border: '1px solid #ced4da',
        borderRadius: '4px',
        padding: '0 10px',
        backgroundColor: '#fff',
        marginTop: '-20px',
    },
    tags_filter: {
        width: '250px',
        marginTop: '16px',
    },
    totalUsers: {
        textAlign: 'center',
        fontSize: 14,
        marginTop: 15,
    },
}));

const ScreenTimeReport = props => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [appCategory, setAppCategory] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [{ user }, userDispatch] = useContext(UserContext);
    const [profiles, setProfiles] = useState([]);
    const [selectedMasterProfileIds, setSelectedMasterProfileIds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCurrentCategoryIds, setSelectedCurrentCategoryIds] = useState([]);
    const [selectedReportedCategoryIds, setSelectedReportedCategoryIds] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [excludeTags, setExcludeTags] = useState(false);
    const [userId, setUserId] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [hideClearButton, setHideClearButton] = useState(true);
    const [clearClicked, setClearClicked] = useState(false);

    useEffect(() => {
        setDateRange();
        fetchAllTags();
        fetchCategories();
        fetchMasterProfiles();
    }, []);

    useEffect(() => {
        if (fromDate && toDate) if (!isFilterApplied) fetchReportedAppCategoryList(true);
    }, [fromDate, toDate]);

    useEffect(() => {
        if (fromDate && toDate) fetchReportedAppCategoryList(false);
    }, [page]);

    useEffect(() => {
        if (clearClicked) fetchReportedAppCategoryList();
    }, [clearClicked]);

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }

    // API call to fetch all tags
    function fetchAllTags() {
        async function fetchData() {
            const response = await http.get('/api/v3/tags.json');
            setAllTags(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    }

    // API call to fetch Master Profiles
    const fetchMasterProfiles = () => {
        UserApi.loadMasterProfileList(user.id)
            .then(response => {
                if (response.isAxiosError) {
                    showErrorToast(response.response.data.errors);
                } else {
                    setProfiles(response);
                }
            })
            .catch(error => {})
            .finally(() => setIsLoading(false));
    };

    // API call to fetch Categories
    const fetchCategories = () => {
        async function fetchData() {
            const response = await http.get('/api/v3/app_categories.json');
            setCategories(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    };

    // API call to fetch App Category List
    const fetchReportedAppCategoryList = (refreshList = false) => {
        setIsLoading(true);
        const updateList = refreshList;
        if (updateList) {
            setPage(1);
        }
        async function fetchFilteredData() {
            const response = await http.get(`/api/v3/reported_app_categories.json`, {
                params: formatData(),
            });
            setAppCategory(response.data.reported_app_categories);
            setTotalPages(response.data.total_pages);
            setTotalRecords(response.data.total_records);
        }
        fetchFilteredData()
            .then(response => {})
            .catch(error => {
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            })
            .finally(() => {
                setIsLoading(false);
                setIsFilterApplied(true);
            });
    };

    const formatData = () => {
        const params = { page: page, per_page: 10, filters: {} };
        let filterParams = {};
        filterParams.from_date =
            fromDate &&
            fromDate.getFullYear() + '_' + (fromDate.getMonth() + 1) + '_' + fromDate.getDate();
        filterParams.to_date =
            toDate && toDate.getFullYear() + '_' + (toDate.getMonth() + 1) + '_' + toDate.getDate();
        filterParams.profile_ids = selectedMasterProfileIds;
        filterParams.current_category = selectedCurrentCategoryIds;
        filterParams.reported_category = selectedReportedCategoryIds;
        filterParams.tags = selectedTags;
        filterParams.exclude_tags = excludeTags;
        filterParams.user_id = userId;
        filterParams.screen_time_report = true;
        params.filters = filterParams;
        return params;
    };

    /**
     * This function set fromDate and toDate
     */
    const setDateRange = () => {
        const currentDate = new Date();
        let _fromDate = new Date();
        _fromDate.setDate(currentDate.getDate() - 6);
        _fromDate = new Date(_fromDate.toDateString());

        setFromDate(
            new Date(
                _fromDate.getFullYear() +
                    '-' +
                    (_fromDate.getMonth() + 1) +
                    '-' +
                    _fromDate.getDate(),
            ),
        );

        setToDate(
            new Date(
                currentDate.getFullYear() +
                    '-' +
                    (currentDate.getMonth() + 1) +
                    '-' +
                    currentDate.getDate(),
            ),
        );
    };

    const handleFromDateChange = date => {
        setFromDate(date);
        setHideClearButton(false);
        setClearClicked(false);
    };

    const handleToDateChange = date => {
        setToDate(date);
        setHideClearButton(false);
        setClearClicked(false);
    };

    const handleUserIdSearchTextChange = event => {
        setUserId(event.target.value);
        setHideClearButton(false);
        setClearClicked(false);
    };

    // Master Profile related Functions
    const handleMasterProfileFilterChange = event => {
        setHideClearButton(false);
        setClearClicked(false);
        let profileId = Number(event.target.value);
        let newSelected = cloneDeep(selectedMasterProfileIds);
        lodashIndexOf(newSelected, profileId) != -1
            ? remove(newSelected, n => n == profileId)
            : newSelected.push(profileId);
        setSelectedMasterProfileIds(newSelected);
    };

    function goBack(e) {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    }

    const handleSubmitBtnClick = () => {
        fetchReportedAppCategoryList(true);
    };

    const handleClearButtonClick = () => {
        setClearClicked(true);
        setPage(1);
        setUserId('');
        setDateRange();
        setSelectedTags([]);
        setExcludeTags(false);
        setSelectedMasterProfileIds([]);
        setSelectedCurrentCategoryIds([]);
        setSelectedReportedCategoryIds([]);
        setHideClearButton(true);
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <Box>
                        <Button color="primary" onClick={goBack}>
                            Reports&nbsp; <ArrowForward fontSize="small" />
                        </Button>
                        <span className={classes.reportName}>App Category Mismatch</span>
                    </Box>
                }
            />

            <Box display="flex" justifyContent="space-between">
                <Typography component="p">
                    View and download the report for the app category mismatch reported by parents
                    from app control section.
                </Typography>
                <DownloadCsvButton
                    csvPath={'/api/v3/reported_app_categories.csv'}
                    parameters={formatData()}
                    fileName="ReportedAppCategories"
                    disabled={isLoading || appCategory.length == 0}
                />
            </Box>

            <Container style={{ marginTop: '20px' }} className={classes.filters}>
                <Box paddingBottom="10px">
                    <Box style={{ padding: '10px' }} display="flex" flexDirection="column">
                        <Box display="flex">
                            <FilterList className={classes.spaceAround} />
                            <Typography> Filter Users</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box style={{ marginTop: '25px' }}>
                                <DateRange
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    handleFromDateChange={handleFromDateChange}
                                    handleToDateChange={handleToDateChange}
                                    datePickerLabel="Reported Between"
                                />
                            </Box>

                            <Box style={{ marginTop: '25px' }}>
                                <FilterByMasterProfiles
                                    profiles={profiles}
                                    selectedMasterProfileIds={selectedMasterProfileIds}
                                    setSelectedMasterProfileIds={setSelectedMasterProfileIds}
                                    handleMasterProfileFilterChange={
                                        handleMasterProfileFilterChange
                                    }
                                />
                            </Box>

                            <Box style={{ marginTop: '25px' }}>
                                <FilterByCategories
                                    currentCategory={true}
                                    categories={categories}
                                    setClearClicked={setClearClicked}
                                    setHideClearButton={setHideClearButton}
                                    selectedCategoryIds={selectedCurrentCategoryIds}
                                    setSelectedCategoryIds={setSelectedCurrentCategoryIds}
                                />
                            </Box>

                            <Box style={{ marginTop: '25px' }}>
                                <FilterByCategories
                                    categories={categories}
                                    setClearClicked={setClearClicked}
                                    setHideClearButton={setHideClearButton}
                                    selectedCategoryIds={selectedReportedCategoryIds}
                                    setSelectedCategoryIds={setSelectedReportedCategoryIds}
                                />
                            </Box>

                            <Box>
                                <SearchByTags
                                    allTags={allTags}
                                    excludeTags={excludeTags}
                                    setExcludeTags={setExcludeTags}
                                    selectedTags={selectedTags}
                                    setSelectedTags={setSelectedTags}
                                    setClearClicked={setClearClicked}
                                    setHideClearButton={setHideClearButton}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent="space-between" padding="10px">
                        <Box width="50%">
                            <input
                                id="search"
                                type="search"
                                value={userId}
                                className={classes.searchField}
                                placeholder="Search by User ID"
                                onChange={e => handleUserIdSearchTextChange(e)}
                            />
                        </Box>

                        <Box>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                style={{ marginTop: '20px' }}
                                className={clsx({ [classes.clearButton]: hideClearButton })}
                                onClick={handleClearButtonClick}>
                                <strong>CLEAR</strong>
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                disabled={
                                    isLoading ||
                                    fromDate == 'Invalid Date' ||
                                    fromDate == null ||
                                    toDate == 'Invalid Date' ||
                                    toDate == null
                                }
                                style={{ marginTop: '20px', marginLeft: '10px' }}
                                onClick={handleSubmitBtnClick}>
                                <strong>FILTER</strong>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Box className={classes.totalUsers}>
                <b>{`Total Users: ${totalRecords}`}</b>
            </Box>
            {isLoading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress size={24} />
                </div>
            ) : (
                <ScreenTimeList
                    appCategory={appCategory}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
        </Container>
    );
};

const SearchByTags = props => {
    const classes = useStyles();
    const {
        allTags,
        excludeTags,
        setExcludeTags,
        selectedTags,
        setSelectedTags,
        setHideClearButton,
        setClearClicked,
    } = props;

    const incomingTags = allTags.map(tag => tag.name);

    const handleTagChange = newTags => {
        var nameArray = newTags.map(function (tag) {
            return tag;
        });
        setSelectedTags(nameArray);
        setHideClearButton(false);
        setClearClicked(false);
        if (nameArray.length == 0) {
            setExcludeTags(false);
            setHideClearButton(true);
        }
    };

    const handleExcludeTags = event => {
        setExcludeTags(event.target.checked);
    };

    return (
        <Box className={classes.tagsBorder}>
            <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                options={incomingTags}
                value={selectedTags}
                className={classes.tags_filter}
                getOptionLabel={option => option}
                renderTags={(value, getTagProps) =>
                    selectedTags.map((option, index) => (
                        <Chip
                            key={index}
                            variant="outlined"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                onChange={(event, newInputValue) => {
                    handleTagChange(newInputValue);
                }}
                renderInput={params => (
                    <TextField {...params} variant="standard" placeholder="Search by Tags" />
                )}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={excludeTags}
                        onChange={handleExcludeTags}
                        disabled={selectedTags.length == 0}
                        color="primary"
                    />
                }
                label="Exclude Tags"
            />
        </Box>
    );
};

export default ScreenTimeReport;
