import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';

// customize
const useStyles = makeStyles(theme => ({}));

/**
 * The customized tabs component
 * @param {Object} props
 */
const StyledTabs = withStyles({
    root: {
        paddingLeft: 5,
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
        '& > div': {
            maxWidth: 60,
            width: '100%',
            backgroundColor: '#635ee7',
        },
    },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

/**
 * Individual Tab styling
 */
const StyledTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,

        '&:focus': {
            opacity: 1,
        },

        '&.Mui-selected': {
            background: '#fff',
            color: theme.palette.common.black,
        },

        '&:not(.Mui-selected):hover': {
            background: '#7d91a5',
        },
    },
}))(props => <Tab disableRipple {...props} />);

/**
 * Tab panel
 */
const Tabpanel = props => {
    const { children, value, index, ...others } = props;
    const allowed = ['dispatch'];

    const filtered = Object.keys(others)
        .filter(key => !allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = others[key];
            return obj;
        }, {});

    return (
        <Typography
            component="div"
            className="tabpanel"
            role="tabpanel"
            hidden={value !== index}
            id={`styled-tabpanel-${index}`}
            aria-labelledby={`styled-tab-${index}`}
            style={{ background: '#fff' }}
            {...filtered}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
};

export { StyledTab, StyledTabs, Tabpanel };
