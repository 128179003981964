import http from 'core/http';
import React, { useState, useEffect } from 'react';
import { Container, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Heading from 'components/header/Heading';
import { ArrowForward } from '@material-ui/icons';
import DataIngestionReportList from './DataIngestionReportList';
import Filters from './Filters';
import { Pagination } from '@material-ui/lab';
import { SHOW_TOAST } from 'components/providers/actions';
import { UserContext } from 'components/providers/UserProvider';

const PER_PAGE = 10;

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 16,
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    filters: {
        background: 'white',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        padding: 10,
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        width: '25%',
        marginRight: 15,
    },
    label: {
        visibility: 'hidden',
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
    paginationSection: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
    },
}));

const DataIngestionReport = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [dataIngestionData, setDataIngestionData] = useState([]);
    const [totalRecords, setTotalRecords] = useState();
    const [taskTypes, setTaskTypes] = useState({});
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [{ user }, userDispatch] = React.useContext(UserContext);

    function showErrorToast(message) {
        userDispatch({
            type: SHOW_TOAST,
            payload: {
                isVisible: true,
                message: message,
                variant: 'error',
            },
        });
    }
    const getFromDate = () => {
        const currentDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(currentDate.getDate() - 6);
        fromDate = new Date(fromDate.toDateString());
        fromDate = new Date(
            fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate(),
        );
        return fromDate;
    };

    const getToDate = () => {
        const currentDate = new Date();
        let toDate = new Date(
            currentDate.getFullYear() +
                '-' +
                (currentDate.getMonth() + 1) +
                '-' +
                currentDate.getDate(),
        );
        return toDate;
    };

    const [filters, setFilters] = useState({
        // date: new Date(),
        from_date: getFromDate(),
        to_date: getToDate(),
        task_type: 'all',
    });

    const goBack = e => {
        let { history } = props;
        e.preventDefault();
        history && history.goBack();
    };

    // API call to fetch Categories
    const fetchTaskTypes = () => {
        async function fetchData() {
            const response = await http.get('/api/v3/data_ingestion_task_types.json');
            setTaskTypes(response.data);
        }
        fetchData()
            .then(response => {})
            .catch(function (error) {
                console.log('ERROR IN RESPONSE: ', error);
            });
    };

    const formatDate = date => {
        return date && date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    };

    const fetchData = () => {
        // const { from, to } = setDateRange();
        setDataIngestionData([]);
        setLoading(true);
        const data = {
            params: {
                // date: formatDate(filters.date),
                from_date: formatDate(filters.from_date),
                to_date: formatDate(filters.to_date),
                task_type: filters.task_type,
                per_page: PER_PAGE,
                page: page,
            },
        };
        http.get(`/api/v3/data_ingestion_task_report.json`, data)
            .then(response => {
                console.log('response->', response);
                setDataIngestionData(response.data.data_ingestion_task_report);
                setTotalRecords(response.data.total_records);
                setLoading(false);
                setTotalPages(Math.ceil(response.data.total_records / 20));
            })
            .catch(error => {
                showErrorToast(error.response.data.errors || 'Something went Wrong!');
            });
    };

    console.log('page', page);

    useEffect(() => {
        fetchTaskTypes();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Heading
                title={
                    <>
                        <Box>
                            <Button color="primary" onClick={goBack}>
                                Reports&nbsp; <ArrowForward fontSize="small" />
                            </Button>
                            <span className={classes.reportName}>Data Ingestion</span>
                        </Box>
                        <Box fontSize="13px" marginLeft="8px" color="gray">
                            View report of app usage data ingestion performed at configured external
                            storage.
                        </Box>
                    </>
                }
            />
            <Filters
                taskTypes={taskTypes}
                filters={filters}
                setFilters={setFilters}
                fetchData={fetchData}
                getFromDate={getFromDate}
                getToDate={getToDate}
                setPage={setPage}
            />
            {totalRecords ? (
                <Box>
                    <DataIngestionReportList
                        dataIngestionData={dataIngestionData}
                        totalRecords={totalRecords}
                        loading={loading}
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        fetchData={fetchData}
                    />
                    <Box className={classes.paginationSection}>
                        <Pagination
                            color="primary"
                            count={totalPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handleChangePage}
                        />
                    </Box>
                </Box>
            ) : (
                <Box className={classes.noDataContainer}>
                    <strong>No Data Found</strong>
                </Box>
            )}
        </Container>
    );
};

export default DataIngestionReport;
