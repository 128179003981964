import {
    Box,
    CircularProgress,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    MuiThemeProvider,
    createMuiTheme,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';

const theme = createMuiTheme({
    overrides: {
        MuiTableHead: {
            root: {
                marginBottom: '20px !important',
                backgroundColor: 'black',
            },
        },
        MuiTableRow: {
            marginBottom: '20px !important',
            backgroundColor: 'black',
        },
    },
});

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '30px',
    },
    tableHead: {
        backgroundColor: '#9baaba !important',
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
    },
    expansionPannelRoot: {
        boxShadow:
            '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        margin: '14px 0 !important',
        backgroundColor: 'white',
        paddingBottom: '10px !important',

        // '&:before': {
        //     backgroundColor: 'transparent',
        // },
    },
    tableBodyRow: {
        // marginBottom: "10px !important"
    },
    MuiTableHead: {
        marginBottom: '20px',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 26,
    },
}));

const DataIngestionReportList = props => {
    const { dataIngestionData, totalRecords, loading } = props;
    const classes = useStyles();

    return (
        <Box width="100%" className={classes.paper}>
            {loading ? (
                <Box className={classes.loadingContainer}>
                    <CircularProgress variant="indeterminate" disableShrink />
                </Box>
            ) : (
                <>
                    <Box className={classes.expansionPannelRoot} padding="15px" borderRadius="8px">
                        <Typography>
                            <strong> Total Records: {totalRecords} </strong>
                        </Typography>
                    </Box>
                    <MuiThemeProvider theme={theme}>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="">
                                            <strong>Performed at</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>From Date</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>To Date</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>Total File Records</strong>
                                        </TableCell>
                                        <TableCell align="center">
                                            <strong>Type</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.expansionPannelRoot}>
                                    {dataIngestionData.map((data, index) => (
                                        <TableRow key={index} className={classes.tableBodyRow}>
                                            <TableCell align="left">{data.created_at}</TableCell>
                                            <TableCell align="center">{data.from_date}</TableCell>
                                            <TableCell align="center">{data.to_date}</TableCell>
                                            <TableCell align="center">
                                                {data.file_data_count}
                                            </TableCell>
                                            <TableCell align="center">{data.task_type}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </MuiThemeProvider>
                </>
            )}
        </Box>
    );
};

export default DataIngestionReportList;
