/**
 * Scalefusion Apps page
 */
import React from 'react';
import { Checkbox, ListItemText, ListItem, List, Popover, InputLabel } from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { cloneDeep, indexOf as lodashIndexOf, remove, shuffle } from 'lodash';

import { Box, FormControl, Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const theme = createMuiTheme({
    overrides: {
        MuiInputLabel: {
            outlined: {
                transform: 'translate(-4px, -7px) scale(1)',
            },
        },
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'none !important',
                },
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    selectButton: {
        width: '180px',
        height: 40,
        alignItems: 'Left',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        fontSize: 13,
        paddingRight: '5px 0px 5px 10px',
        fontFamily: 'Camphor,sans-serif',
    },
    FormControl: {
        border: '1px solid #ced4da',
        borderRadius: 4,
    },
    selectCategoryDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        textTransform: 'none',
    },
    listText: {
        fontSize: '12px',
    },

    sticky: {
        zIndex: 99999,
        borderTop: '1px solid',
        position: 'sticky',
        width: '100%',
        background: '#fff',
        bottom: 0,
        '&:hover': {
            backgroundColor: '#fff',
        },
    },

    categoryList: {
        maxHeight: '260px', // Earlier maxHeight: '300px'
        overflow: 'auto',
    },
}));

const FilterByCategories = props => {
    const classes = useStyles();
    const {
        categories,
        currentCategory,
        selectedCategoryIds,
        setSelectedCategoryIds,
        setHideClearButton,
        setClearClicked,
    } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-popover' : undefined;

    const handleCategoryFilterChange = event => {
        setHideClearButton(false);
        setClearClicked(false);
        let categoryId = Number(event.target.value);
        let newSelected = cloneDeep(selectedCategoryIds);
        lodashIndexOf(newSelected, categoryId) != -1
            ? remove(newSelected, n => n == categoryId)
            : newSelected.push(categoryId);
        setSelectedCategoryIds(newSelected);
    };

    return (
        <Box>
            <MuiThemeProvider theme={theme}>
                <form autoComplete="off">
                    <FormControl className={classes.FormControl} variant="outlined">
                        <InputLabel
                            style={{
                                backgroundColor: 'white',
                                paddingLeft: '10px',
                                justifyContent: 'center !important',
                                display: 'flex !important',
                                paddingRight: '10px',
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontSize: 'inherit',
                                marginLeft: '32px',
                            }}>
                            {currentCategory ? 'Current Category' : 'Reported Category'}
                        </InputLabel>
                        <Button
                            className={classes.selectButton}
                            disableRipple={true}
                            disableFocusRipple={true}
                            // disabled={disableFilter}
                            id="users-filter-select-category"
                            onClick={handleClick}>
                            <div className={classes.selectCategoryDiv}>
                                {selectedCategoryIds.length == 0
                                    ? 'Select Category'
                                    : `Selected ${selectedCategoryIds.length} Categories`}
                                <ArrowDropDown />
                            </div>
                        </Button>
                    </FormControl>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <Box>
                            <List className={classes.categoryList}>
                                {categories &&
                                    categories.map(category => (
                                        <ListItem key={category.id}>
                                            <Checkbox
                                                onClick={handleCategoryFilterChange}
                                                edge="start"
                                                color="primary"
                                                value={category.id}
                                                checked={
                                                    selectedCategoryIds.indexOf(category.id) > -1
                                                }
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': `checkbox-list-label-${category.name}`,
                                                    'data-select-mp-checkbox': `${category.name}`,
                                                }}
                                            />
                                            <ListItemText
                                                className={classes.listText}
                                                id={`checkbox-list-label-${category.id}`}
                                                primary={category.name}
                                            />
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    </Popover>
                </form>
            </MuiThemeProvider>
        </Box>
    );
};

export default FilterByCategories;
