import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Box, Grid } from '@material-ui/core';
import Logo from '../../images/logo.png';

import {
    ConfigNav,
    EnterpriseAppsNav,
    ReportsNav,
    UsersNav,
    AdminsNav,
    RemoteCastAndControlNav,
} from 'components/navbar/navs';
import { UserContext } from 'components/providers/UserProvider';
import ProfilesNav from './navs/ProfilesNav';
import LoggedInUserNav from './navs/LoggedInUserNav';
import usePermissionsHook from 'Hooks/usePermissionHook';

const useStyles = makeStyles(theme => ({
    root: {
        '> a': {
            color: '#fff',
        },
    },

    appbar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    navs: {
        marginLeft: 0,
        '& > a': {
            // marginRight: 12,
            textTransform: 'none',
            fontSize: 14,
            // height: 50,
            borderRadius: 0,

            '& svg': {
                marginRight: 8,
            },
        },

        '& > .MuiListItemIcon': {
            minWidth: 80,
        },

        '& .MuiButton-text': {
            padding: '15px 12px',
        },

        '& > a.active': {
            backgroundColor: theme.palette.primary.dark,
        },
    },

    iconRoot: {
        minWidth: 30,
    },

    accounts: {
        textTransform: 'none !important',

        '&.MuiGrid-item': {
            textAlign: 'right',
        },
    },
}));

/**
 * App global navbar component
 *
 * @param {Object} theme
 * @param  {...any} props
 */

const AESETUP_SLUG = 'Byjus/AESetup';
const DEVELOPERAPIKEY_SLUG = 'Byjus/DeveloperAPIKey';
const MASTERPROFILES_SLUG = 'Byjus/MasterProfiles';
const USERS_SLUG = 'Byjus/Users';
const APPLICATIONS_SLUG = 'Byjus/Applications';
const ADMINSANDROLES_SLUG = 'Byjus/AdminsAndRoles';
const REMOTECAST = 'Byjus/RemoteCast';

const AppNavBar = (theme, ...props) => {
    const classes = useStyles(theme);
    const [{ user }, dispatch] = useContext(UserContext);
    return (
        <AppBar position="fixed" className={classes.appbar}>
            <Toolbar variant="dense">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.root}>
                    <Grid item xs={2}>
                        <IconButton edge="start">
                            <img src={Logo} height="30" width="30" alt="Scalefusion" />
                        </IconButton>
                        <Typography variant="h6" component="span" color="inherit">
                            {process.env.REACT_APP_SITE_TITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Box className={classes.navs}>
                            <ConfigNav />
                            {usePermissionsHook(MASTERPROFILES_SLUG)['allowed'] && <ProfilesNav />}
                            {usePermissionsHook(USERS_SLUG)['allowed'] && <UsersNav />}
                            {usePermissionsHook(APPLICATIONS_SLUG)['allowed'] && (
                                <EnterpriseAppsNav />
                            )}
                            <ReportsNav />
                            {usePermissionsHook(ADMINSANDROLES_SLUG)['allowed'] && <AdminsNav />}
                            {usePermissionsHook(REMOTECAST)['allowed'] && (
                                <RemoteCastAndControlNav />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={1} className={classes.accounts}>
                        <Box className={classes.navs}>
                            {user && <LoggedInUserNav user={user} />}
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default AppNavBar;
