import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProgressButton from 'components/utils/ProgressButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dialog: {
        minWidth: 400,
    },
}));

/**
 * A reusable wrapper dialog
 * it renders the children inside and
 * can understand position of destructive buttons
 * like delete
 * @param {*} props dialog contents
 */
const MuiDialog = props => {
    const classes = useStyles();
    const {
        title,
        onAccept,
        onReject,
        open = false,
        onCloseText,
        isDestructive,
        onAcceptText,
        isLoading,
        isProgressButton,
        acceptButtonId,
        cancelButtonId,
        disableAccept,
        ...others
    } = props;

    return (
        <Dialog open={open} onClose={onReject} {...others} classes={{ paper: classes.dialog }}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{props.children}</DialogContent>
            <DialogActions>
                {onReject && !isDestructive && (
                    <Button color="primary" onClick={onReject} id={cancelButtonId}>
                        {onCloseText}
                    </Button>
                )}
                {onAccept && (
                    <>
                        {isProgressButton ? (
                            <ProgressButton
                                isWaiting={isLoading}
                                autoFocus
                                color={isDestructive ? 'secondary' : 'primary'}
                                size="small"
                                variant="contained"
                                disabled={disableAccept}
                                id={acceptButtonId}
                                onClick={onAccept}>
                                {onAcceptText}
                            </ProgressButton>
                        ) : (
                            <Button
                                onClick={onAccept}
                                id={acceptButtonId}
                                disabled={disableAccept}
                                color={isDestructive ? 'secondary' : 'primary'}>
                                {onAcceptText}
                            </Button>
                        )}
                    </>
                )}
                {isDestructive && (
                    <Button
                        color="primary"
                        onClick={onReject}
                        id={cancelButtonId}
                        size="small"
                        variant="outlined">
                        {onCloseText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

MuiDialog.defaultProps = {
    onCloseText: 'CANCEL',
    onAcceptText: 'SAVE',
    isDestructive: false,
    isLoading: false,
    isProgressButton: false,
    acceptButtonId: 'accept-button',
    cancelButtonId: 'cancel-button',
    disableAccept: false,
};

MuiDialog.propTypes = {
    open: PropTypes.bool,
    onAcceptText: PropTypes.any,
    onCloseText: PropTypes.any,
    onReject: PropTypes.func,
    onAccept: PropTypes.func,
    title: PropTypes.any.isRequired,
    isDestructive: PropTypes.bool,
    isLoading: PropTypes.bool,
    isProgressButton: PropTypes.bool,
    acceptButtonId: PropTypes.any,
    cancelButtonId: PropTypes.any,
    disableAccept: PropTypes.any,
};

export default MuiDialog;
