import http from 'core/http';
import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, FormControl, TextField, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';
import DownloadCsvButton from './DownloadCsvButton';
import AppDataUsageList from './AppDataUsageList';
import MultiSelect from 'react-multi-select-component';
import _ from 'lodash';
import clsx from 'clsx';
import DateRange from './DateRange';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    filters: {
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
    },
    select: {
        marginTop: '16px',
    },
    spaceAround: {
        marginRight: '4px',
    },
    clearButton: {
        visibility: 'hidden',
    },
    reportName: {
        fontSize: '15px',
        color: 'gray',
    },
    formControl: {
        // minWidth: 120,
        // maxWidth: 300,
        width: '32%',
        marginRight: 20,
    },

    dateControl: {
        // width: '79%',
        width: '40%',
        marginRight: 15,
    },

    loading: {
        position: 'absolute',
        marginTop: '5%',
    },
    searchField: {
        margin: 0,
    },
    tags_filter: {
        width: '250px',
        marginTop: '16px',
    },

    actionButton: {
        marginLeft: 'auto',
        marginRight: 15,
    },

    topContainer: {
        width: 'calc(100% - 100px)',
        marginLeft: 0,
    },

    disabled: {
        pointerEvents: 'none',
        opacity: 0.6,
    },
    searchField: {
        width: '100%',
        // marginLeft: 15,
        border: '1px solid #ced4da',
        borderRadius: 4,
        // height: 1.1875em;
        margin: 0,
        marginBottom: 8,
        display: 'block',
        padding: '14px 4px',
        fontSize: 14,
        minWidth: 0,
        background: 'none',
        boxSizing: 'content-box',
        // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        // '&:focus': {
        //     outline: 'none',
        //     borderBottom: `1px solid ${theme.palette.primary.main}`,
        // },
        marginTop: 8,
    },

    autoComplete: {
        padding: '5px !important',
        borderRadius: '4px !important',
    },
}));

const UserLevelAppUsage = props => {
    const classes = useStyles();
    const {
        selectedDeviceUserId,
        setDeviceList,
        setSelectedDeviceUserId,
        setSelectedDevice,
        selectedDevice,
        fromDate,
        toDate,
        handleFromDateChange,
        handleToDateChange,
        deviceList,
    } = props;
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [totalAppDataCount, setTotalAppDataCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [noDataMessage, setNoDataMessage] = useState('Select Device to fetch report');
    const [dataUsageData, setDataUsageData] = useState([]);

    useEffect(() => {
        if (selectedDeviceUserId.length > 0) handleSelectedDeviceUserIdChange();
    }, [selectedDeviceUserId]);

    const handleSelectedDeviceUserIdChange = () => {
        setIsLoading(true);
        http.get(`/api/v3/users/${selectedDeviceUserId}/user_devices.json`, formatData())
            .then(response => {
                setDeviceList(response.data.devices);
                setNoDataMessage('Select Device to fetch report');
            })
            .catch(error => {
                if (error.response.status == 422) {
                    setNoDataMessage('User Not found. Please check your User ID.');
                }
            })
            .finally(response => {
                setIsLoading(false);
            });
    };

    const handleDeviceUserChange = event => {
        setSelectedDeviceUserId(event.target.value);
    };

    useEffect(() => {
        fetchData(false);
    }, [page]);

    const fetchData = (refreshList = false) => {
        const updateList = refreshList;

        if (updateList) {
            setPage(1);
        }
        if (selectedDevice && selectedDevice.id) {
            setIsLoading(true);
            const data = formatData();
            http.get(`/api/v3/app_data_usage_report/data_usage.json`, formatData())
                .then(response => {
                    setDataUsageData(response.data.data);
                    if (response.data.data.length === 0) setNoDataMessage('No Data Found!!!');
                    setTotalAppDataCount(response.data.total_data_count);
                    setTotalPages(response.data.total_pages);
                })
                .catch(error => {})
                .finally(response => {
                    setIsLoading(false);
                });
        }
    };

    /**
     * Format Parameter Data
     */
    const formatData = () => {
        if (fromDate && toDate && selectedDevice) {
            let deviceId = selectedDevice.id;
            const params = {
                params: {
                    from_date:
                        fromDate &&
                        fromDate.getFullYear() +
                            '_' +
                            (fromDate.getMonth() + 1) +
                            '_' +
                            fromDate.getDate(),
                    to_date:
                        toDate &&
                        toDate.getFullYear() +
                            '_' +
                            (toDate.getMonth() + 1) +
                            '_' +
                            toDate.getDate(),
                    device_id: deviceId,
                    per_page: perPage,
                    page: page,
                },
            };
            return params;
        }
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                {/* <Typography component="p">View and download reports from here.</Typography> */}
                <Box fontSize="13px" marginLeft="8px" color="gray">
                    View and download app usage reports from here.
                </Box>
                <Box marginTop="-20px">
                    <DownloadCsvButton
                        csvPath="/api/v3/app_data_usage_report/data_usage.csv"
                        parameters={formatData()}
                        fileName="appDataUsage"
                        disabled={
                            isLoading ||
                            dataUsageData.length === 0 ||
                            (selectedDevice && selectedDevice.length === 0) ||
                            selectedDeviceUserId.length === 0
                        }
                    />
                </Box>
            </Box>
            <Box style={{ marginTop: '20px', padding: '10px' }} className={classes.filters}>
                <Box display="flex" ml="15px" alignItems="center">
                    <FilterList className={classes.spaceAround} />
                    <Box> Fetch Records </Box>
                </Box>
                <Box className={classes.topContainer}>
                    <Box display="flex" alignItems="center" m="10px">
                        <FormControl className={classes.dateControl}>
                            <DateRange
                                fromDate={fromDate}
                                toDate={toDate}
                                handleFromDateChange={handleFromDateChange}
                                handleToDateChange={handleToDateChange}
                                datePickerLabel="App Usage Between"
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            {/* <MultiSelect
                                options={deviceUserIds}
                                value={selectedDeviceUserId}
                                ItemRenderer={deviceUserDropdownRenderer}
                                onChange={handleDeviceUserSelect}
                                hasSelectAll={false}
                                labelledBy={'Select'}
                                overrideStrings={{
                                    selectSomeItems: 'Select Device User...',
                                    search: 'Search Device User',
                                }}
                            /> */}
                            <input
                                placeholder="Search by User ID"
                                id="search"
                                type="search"
                                className={classes.searchField}
                                value={selectedDeviceUserId}
                                onChange={handleDeviceUserChange}
                            />
                        </FormControl>
                        <FormControl
                            style={{ marginTop: '3px' }}
                            className={clsx(classes.formControl, {
                                [classes.disabled]: selectedDeviceUserId.length === 0,
                            })}>
                            <Autocomplete
                                options={deviceList}
                                getOptionLabel={option => option.serial_no || ''}
                                classes={{ inputRoot: classes.autoComplete }}
                                id="device-serial-no"
                                value={selectedDevice}
                                onChange={(event, newValue) => {
                                    setSelectedDevice(newValue);
                                }}
                                renderInput={params => (
                                    <TextField
                                        variant="outlined"
                                        placeholder="Serial No."
                                        {...params}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.actionButton}
                    onClick={() => fetchData(true)}
                    disabled={
                        isLoading ||
                        !selectedDevice ||
                        selectedDevice.length === 0 ||
                        selectedDeviceUserId.length === 0 ||
                        fromDate == 'Invalid Date' ||
                        fromDate == null ||
                        toDate == 'Invalid Date' ||
                        toDate == null
                    }>
                    <strong>FILTER</strong>
                </Button>
            </Box>

            <AppDataUsageList
                dataUsageData={dataUsageData}
                page={page}
                setPage={setPage}
                totalRecords={totalAppDataCount}
                totalPages={totalPages}
                isLoading={isLoading}
                noDataMessage={noDataMessage}
            />
        </>
    );
};

export default UserLevelAppUsage;
