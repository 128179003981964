import React from 'react';

const ActiVityType = [
    { key: 'account_activity', value: 'Account Activity' },
    { key: 'device_management', value: 'Device Management' },
    { key: 'device_actions', value: 'Device Actions' },
    { key: 'application_management', value: 'Application Management' },
    { key: 'setup_and_enrollment', value: 'Setup And Enrollment' },
    { key: 'remote_cast', value: 'Remote Support' },
];

export default ActiVityType;
